<script>
import { useAuthStore } from '@/stores/authStore';
import { ref } from 'vue';
import axios from 'axios';
import router from '@/router/index.js';
import OrderComponent from '@/components/OrderComponent.vue';

export default {
  components: {
    OrderComponent
  },
  data() {
    const authStore = useAuthStore();

    return {
      authStore,
      profile: null
    };
  },
  computed: {},
  methods: {
    async save() {
      let data = { profile: this.profile };
      await axios
        .post(this.apiUrl + 'profile/save', data)
        .then((response) => {
          this.authStore.profile = response.data;
          router.push({ path: '/profile/orders' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex no-print">
    <div class="flex-grow-1"><h3>Order View</h3></div>
    <div class="">
      <button class="btn btn-sm btn-secondary" onclick="window.print()">Print</button>
    </div>
  </div>
  <OrderComponent
    :order-id="$route.params.id"
    :email="$route.params.email"
    view-type="customer"
  ></OrderComponent>
</template>
