<script>
import specials_carousel from '@/components/SpecialsCarousel.vue';

export default {
  components: {
    specials_carousel
  },
  data() {}
};
</script>

<template>
  <div class="item-container">
    <div v-if="clientName === 'midcity'">
      <img
        src="https://ext-chef.s3.us-west-2.amazonaws.com/Website-Banner-Van.png"
        class="img-fluid"
      />
    </div>

    <div class="text-center my-5">
      <a href="/login"
        ><button type="submit" class="btn btn-primary mx-3">
          <i class="fas fa-lock"></i> Customer Login
        </button></a
      >

      <a href="/profile/edit"
        ><button type="submit" class="btn btn-primary mx-3">
          <i class="fas fa-user"></i> Create Account
        </button></a
      >
    </div>
    <specials_carousel />
  </div>
</template>
