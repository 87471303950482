<script>
export default {
  name: 'BreadcrumbTrail',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<template>
  <div>
    <ul class="list-group list-group-horizontal mb-2">
      <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.url" class="breadcrumb">
        <a :href="breadcrumb.url">{{ breadcrumb.name }}</a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.breadcrumb:after {
  content: '>';
  color: #cccccc;
  left: 5px;
  margin: 0;
  position: relative;
}
.breadcrumb:last-child:after {
  content: '';
}
.breadcrumb {
  padding: 0px 5px;
  margin-bottom: 0px;
  list-style: none;
  border-radius: 1px;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
}
.breadcrumb a {
  color: #333;
  text-decoration: none;
}
</style>
