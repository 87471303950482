<script>
import axios from 'axios';

export default {
  data() {
    let shipping_options = ['delivery'];
    if (this.clientName == 'atlas') {
      shipping_options = [
        'delivery',
        'will call (Indianapolis Store)',
        'will call (Ft Wayne Store)',
        'will call (Grand Rapids Store)',
        'will call (South Bend Store)'
      ];
    }
    if (this.clientName == 'midcity') {
      shipping_options = [
        'delivery',
        'deliver with next Mid City delivery',
        'pickup at Young Brothers (Kauai)',
        'deliver with next YHata delivery'
      ];
    }

    return {
      order: {},
      country_codes: ['US', 'CA'],
      state_codes: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',

        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT'
      ],
      cc_types: ['Visa', 'MasterCard', 'Amex', 'Discover'],
      checkout_types: ['quote', 'cc', 'check', 'COD', 'invoice', 'lease', 'PO', 'wire'],
      employeeList: [],
      manufacturers: [],
      shipping_options: shipping_options,
      payment_sources: ['stripe', 'authorizenet', 'check', 'cash', 'paid at desk'],
      status_types: [
        'quote',
        'incomplete',
        'being processed',
        'pending order',
        'pending shipping',
        'pending customer pickup',
        'pending route delivery',
        'in transit',
        'all items delivered',
        'canceled'
      ]
    };
  },
  mounted() {
    // API get employee
    axios
      .get(this.apiUrl + 'admin/employee/dropdowns')
      .then((response) => {
        this.employeeList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
    // API get manufacturers
    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    // API get order
    axios
      .get(this.apiUrl + 'admin/orders/' + this.$route.params.id)
      .then((response) => {
        this.order = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    changeManufacturer(event, order_item) {
      //  v-model="order_item.manufacturer_code" is set, need to set order_item.manufacturer too
      let selectedManufacturer =
        event.target.options[event.target.options.selectedIndex].text;
      order_item.manufacturer = selectedManufacturer;
    },
    cancelOrder() {
      this.$router.push({ path: '/admin/orders/view/' + this.$route.params.id });
    },
    saveOrder() {
      axios
        .post(this.apiUrl + 'admin/orders/save', this.order)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    deleteItem(index) {
      if (confirm('Do you really want to delete?')) {
        this.order.order_items.splice(index, 1);
        this.saveOrder();
      }
    },

    addPaymentHistory() {
      this.order.payment_histories.push({ order_id: this.order.orderid });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center mb-2">
    <h2>Order / Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelOrder">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveOrder">Save</button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex">Order {{ order.orderid }}</div>
        <div class="card-body">
          <table class="">
            <tr>
              <td class="form-label">Status</td>
              <td>
                <select v-model="order.status" class="form-select no-width">
                  <option v-for="status in status_types" :key="status" :value="status">
                    {{ status }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="form-label">Date Quoted</td>
              <td>{{ $getDateTime(order.date_quoted) }}</td>
            </tr>
            <tr>
              <td class="form-label">Date Ordered</td>
              <td>{{ $getDateTime(order.date_ordered) }}</td>
            </tr>
            <tr>
              <td class="form-label">Payment Type</td>
              <td>
                <select v-model="order.checkout_type" class="form-select no-width">
                  <option
                    v-for="checkout_type in checkout_types"
                    :key="checkout_type"
                    :value="checkout_type"
                  >
                    {{ checkout_type }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="form-label">Shipping Option</td>
              <td>
                <select v-model="order.shipping_option" class="form-select no-width">
                  <option
                    v-for="shipping_option in shipping_options"
                    :key="shipping_option"
                    :value="shipping_option"
                  >
                    {{ shipping_option }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="form-label">Employee</td>
              <td>
                <select v-model="order.employee_id" class="form-select form-select-sm">
                  <option value="0">-</option>
                  <option
                    v-for="employee in employeeList"
                    :key="employee.id"
                    :value="employee.id"
                  >
                    {{ employee.name }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="form-label">Profile</td>
              <td v-if="order.profile">
                {{ order.profile.firstname }}
                {{ order.profile.lastname }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-header">Memos</div>
        <div class="card-body">
          Internal Memo <br />
          <div>
            <textarea v-model="order.memo" class="form-control" rows="3"></textarea>
          </div>
          <br />

          Customer Memo <br />
          <div>
            <textarea
              v-model="order.customer_memo"
              class="form-control"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--- Bill To - Ship To -->
  <div class="row py-3">
    <div class="col">
      <div class="card">
        <div class="card-header">Bill To</div>
        <div class="card-body">
          <table class="">
            <tr>
              <td class="form-label">Company</td>
              <td>
                <input v-model="order.billto_company" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Firstname</td>
              <td>
                <input
                  v-model="order.billto_firstname"
                  class="form-control"
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td class="form-label">Lastname</td>
              <td>
                <input v-model="order.billto_lastname" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Email</td>
              <td>
                <input v-model="order.billto_email" class="form-control" type="email" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Phone</td>
              <td>
                <input v-model="order.billto_phone" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Address</td>
              <td>
                <input v-model="order.billto_address1" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Address2</td>
              <td>
                <input v-model="order.billto_address2" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">City State Zip</td>
              <td>
                <input
                  v-model="order.billto_city"
                  class="form-control no-width inline"
                  type="text"
                  placeholder="city"
                />

                <select v-model="order.billto_state" class="form-select inline mx-1">
                  <option v-for="state in state_codes" :key="state" :value="state">
                    {{ state }}
                  </option>
                </select>

                <input
                  v-model="order.billto_zip"
                  class="form-control no-width inline"
                  type="text"
                  placeholder="zip"
                />
              </td>
            </tr>
            <tr>
              <td class="form-label">Country</td>
              <td>
                <select v-model="order.billto_country" class="form-select">
                  <option
                    v-for="country in country_codes"
                    :key="country"
                    :value="country"
                  >
                    {{ country }}
                  </option>
                </select>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card">
        <div class="card-header">Ship To</div>
        <div class="card-body">
          <table class="table">
            <tr>
              <td class="form-label">Company</td>
              <td>
                <input v-model="order.company" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Firstname</td>
              <td>
                <input v-model="order.firstname" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Lastname</td>
              <td>
                <input v-model="order.lastname" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Email</td>
              <td>
                <input v-model="order.email" class="form-control" type="email" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Phone</td>
              <td>
                <input v-model="order.phone" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Address</td>
              <td>
                <input v-model="order.address1" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">Address2</td>
              <td>
                <input v-model="order.address2" class="form-control" type="text" />
              </td>
            </tr>
            <tr>
              <td class="form-label">City State Zip</td>
              <td>
                <input
                  v-model="order.city"
                  class="form-control no-width inline"
                  type="text"
                  placeholder="city"
                />

                <select v-model="order.state" class="form-select inline mx-1">
                  <option v-for="state in state_codes" :key="state" :value="state">
                    {{ state }}
                  </option>
                </select>

                <input
                  v-model="order.zip"
                  class="form-control no-width inline"
                  type="text"
                  placeholder="zip"
                />
              </td>
            </tr>
            <tr>
              <td class="form-label">Country</td>
              <td>
                <select v-model="order.country" class="form-select">
                  <option
                    v-for="country in country_codes"
                    :key="country"
                    :value="country"
                  >
                    {{ country }}
                  </option>
                </select>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />

  <!--- payment history -->
  <div class="card">
    <div class="card-header">
      Payment History
      <font-awesome-icon class="hand mx-1" icon="fa-plus" @click="addPaymentHistory()" />
    </div>
    <div class="card-body">
      <table class="table table-hover table-sm" style="width: 100%">
        <thead class="table-light">
          <tr>
            <td>DateEntered</td>
            <td>Source</td>
            <td>TransId</td>
            <td>AuthCode</td>
            <td>Memo</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody
          v-for="payment_history in order.payment_histories"
          :key="payment_history.id"
        >
          <tr>
            <td>{{ $getDateTime(payment_history.date_entered) }}</td>
            <td>
              <select v-model="payment_history.source" class="form-select">
                <option v-for="source in payment_sources" :key="source" :value="source">
                  {{ source }}
                </option>
              </select>
            </td>
            <td>
              <input v-model="payment_history.trans_id" class="form-control no-width" />
            </td>
            <td>
              <input v-model="payment_history.auth_code" class="form-control no-width" />
            </td>
            <td>
              <input v-model="payment_history.memo" class="form-control no-width" />
            </td>
            <td>
              <input v-model="payment_history.amount" class="form-control no-width" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br />
  <br />

  <table class="table table-hover table-sm" style="width: 100%">
    <thead class="table-light">
      <tr>
        <td>Orderid</td>
        <td>Company</td>
        <td>SKU</td>
        <td>Item</td>
        <td class="">Cog</td>
        <td class="">CogTax</td>
        <td class="">Price</td>
        <td class="">Tax</td>
        <td class="">Qty</td>
        <td class="right">Total</td>
        <td class="right">Profit</td>
      </tr>
    </thead>
    <tbody
      v-for="(order_item, item_index) in order.order_items"
      :key="order_item.orderid"
    >
      <tr>
        <td>
          {{ order_item.order_itemid }}
          <font-awesome-icon
            class="hand mx-1"
            icon="fa-trash-alt"
            @click="deleteItem(item_index)"
          />
        </td>
        <td>
          <select
            v-model="order_item.manufacturer_code"
            class="form-select"
            @change="changeManufacturer($event, order_item)"
          >
            <option
              v-for="manufacturer in manufacturers"
              :key="manufacturer.manufacturer_code"
              :value="manufacturer.manufacturer_code"
            >
              {{ manufacturer.manufacturer }}
            </option>
          </select>
        </td>
        <td>
          <input v-model="order_item.model" class="form-control" type="text" />
        </td>
        <td>
          <input v-model="order_item.title" class="form-control" type="text" />
        </td>
        <td class="">
          <input
            v-model="order_item.cog"
            class="form-control"
            type="number"
            step="0.01"
          />
          <input
            v-model="order_item.is_rebateable"
            class="form-check-input"
            type="checkbox"
            true-value="1"
            false-value="0"
          />
          rebateable
          <br />
          <input
            v-model="order_item.in_stock"
            class="form-check-input"
            type="checkbox"
            true-value="1"
            false-value="0"
          />
          instock
        </td>
        <td class="">
          <input
            v-model="order_item.cog_tax_total"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">
          <input
            v-model="order_item.sell_price"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">
          <input
            v-model="order_item.tax_total"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">
          <input v-model="order_item.qty" class="form-control" type="number" />
        </td>
        <td class="right">
          {{ $money(order_item.qty * (order_item.sell_price + order_item.tax_total)) }}
        </td>
        <td class="right">
          {{
            $money(
              order_item.qty *
                (order_item.sell_price +
                  order_item.tax_total -
                  order_item.cog -
                  order_item.cog_tax_total)
            )
          }}
        </td>
      </tr>

      <!--- serial_number -->
      <tr v-if="order_item.serial_number > ''">
        <td></td>
        <td></td>
        <td></td>
        <td>
          serial:
          <input v-model="order_item.serial_number" class="form-control" type="text" />
        </td>
        <td class="right"></td>
        <td class="right"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <!--- options -->
      <tr v-if="order_item.options > ''">
        <td></td>
        <td></td>
        <td></td>
        <td><input v-model="order_item.options" class="form-control" type="text" /></td>
        <td class="right"></td>
        <td class="right"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>

      <!--- order_item_accessories -->
      <tr
        v-for="order_item_accessory in order_item.order_item_accessories"
        :key="order_item_accessory.orderid"
      >
        <td></td>
        <td>
          <input
            v-model="order_item_accessory.manufacturer"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="order_item_accessory.model" class="form-control" type="text" />
        </td>
        <td>
          <input
            v-model="order_item_accessory.description"
            class="form-control"
            type="text"
          />
        </td>

        <td class="right">
          <input
            v-model="order_item_accessory.cog"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item_accessory.cog_tax_total"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item_accessory.sell_price"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item_accessory.tax_total"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">
          <input v-model="order_item_accessory.qty" class="form-control" type="number" />
        </td>

        <td class="right">
          {{
            $money(
              order_item_accessory.qty *
                (order_item_accessory.sell_price + order_item_accessory.tax_total)
            )
          }}
        </td>
        <td></td>
      </tr>

      <!--- freight 1 -->
      <tr>
        <td></td>
        <td>
          <input
            v-model="order_item.freight_company_1"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="order_item.freight_memo_1" class="form-control" type="text" />
        </td>
        <td>freight</td>
        <td class="right">
          <input
            v-model="order_item.freight_cog_1"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_cog_tax_total_1"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_rate_1"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_tax_total_1"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">1</td>
        <td class="right">
          {{ $money(order_item.freight_rate_1 + order_item.freight_tax_total_1) }}
        </td>
        <td class="right">
          {{
            $money(
              order_item.freight_rate_1 +
                order_item.freight_tax_total_1 -
                order_item.freight_cog_1 -
                order_item.freight_cog_tax_total_1
            )
          }}
        </td>
      </tr>

      <!--- freight2 -->
      <tr>
        <td></td>
        <td>
          <input
            v-model="order_item.freight_company_2"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="order_item.freight_memo_2" class="form-control" type="text" />
        </td>
        <td>freight</td>
        <td class="right">
          <input
            v-model="order_item.freight_cog_2"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_cog_tax_total_2"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_rate_2"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_tax_total_2"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">1</td>
        <td class="right">
          {{ $money(order_item.freight_rate_2 + order_item.freight_tax_total_2) }}
        </td>
        <td class="right">
          {{
            $money(
              order_item.freight_rate_2 +
                order_item.freight_tax_total_2 -
                order_item.freight_cog_2 -
                order_item.freight_cog_tax_total_2
            )
          }}
        </td>
      </tr>

      <!--- freight3 -->
      <tr>
        <td></td>
        <td>
          <input
            v-model="order_item.freight_company_3"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="order_item.freight_memo_3" class="form-control" type="text" />
        </td>
        <td>freight</td>
        <td class="right">
          <input
            v-model="order_item.freight_cog_3"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_cog_tax_total_3"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_rate_3"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="right">
          <input
            v-model="order_item.freight_tax_total_3"
            class="form-control"
            type="number"
            step="0.01"
          />
        </td>
        <td class="">1</td>
        <td class="right">
          {{ $money(order_item.freight_rate_3 + order_item.freight_tax_total_3) }}
        </td>
        <td class="right">
          {{
            $money(
              order_item.freight_rate_3 +
                order_item.freight_cog_tax_total_3 -
                order_item.freight_cog_3 -
                order_item.freight_cog_tax_total_3
            )
          }}
        </td>
      </tr>
    </tbody>

    <!--- fee -->
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td>
        <input v-model="order.fee_memo" class="form-control" type="text" />
      </td>
      <td class="right"></td>
      <td class="right"></td>
      <td></td>
      <td></td>
      <td class="form-label">Fee</td>
      <td class="right">
        <input v-model="order.fee" class="form-control" type="number" step="0.01" />
      </td>
      <td></td>
    </tr>

    <!--- total -->
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="right"></td>
      <td class="right"></td>
      <td></td>
      <td></td>
      <td class="form-label">Total</td>
      <td class="right">{{ $money(order.total) }}</td>
      <td class="right">
        {{ $money(order.total + order.fee - order.freight_total_cog - order.cog_total) }}
      </td>
    </tr>
  </table>

  <!--- policies --->
  <br />
  <br />
  <br />
  <hr />
  <br />
  <br />
  <br />

  <div v-if="clientName === 'atlas'">
    <PolicyAtlas></PolicyAtlas>
  </div>
  <div v-if="clientName === 'midcity'">
    <PolicyMidcity></PolicyMidcity>
  </div>
  <div v-if="clientName === 'mychefstore'">
    <PolicyMychefstore></PolicyMychefstore>
  </div>
</template>
