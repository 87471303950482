<script>
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import dayjs from 'dayjs';

export default {
  components: { flatPickr },
  data() {
    let begin_date = new Date();
    let end_date = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);

    return {
      items_misc_info: {
        id: 0,
        manufacturer_code: '1016',
        model: '',
        misc_info_begin_date: dayjs(begin_date).format('YYYY-MM-DD'),
        misc_info_end_date: dayjs(end_date).format('YYYY-MM-DD'),
        misc_info: ''
      },

      manufacturers: []
    };
  },
  mounted() {
    // API get manufacturers
    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    // API get Data
    axios
      .get(this.apiUrl + 'admin/items_misc_info/' + this.$route.params.id)
      .then((response) => {
        this.items_misc_info = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    cancelItemsMiscInfo() {
      this.$router.push({ path: '/admin/items_misc_info' });
    },
    SaveItemsMiscInfo() {
      axios
        .post(this.apiUrl + 'admin/items_misc_info', this.items_misc_info)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/items_misc_info' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Items Misc Info / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelItemsMiscInfo">
        Cancel
      </button>
      <button class="btn btn-primary btn-sm" @click="SaveItemsMiscInfo">Save</button>
    </div>
  </div>

  <table class="">
    <tr>
      <td class="form-label">Manufacturer</td>
      <td>
        <select v-model="items_misc_info.manufacturer_code">
          <option
            v-for="manufacturer in manufacturers"
            :key="manufacturer.manufacturer_code"
            :value="manufacturer.manufacturer_code"
          >
            {{ manufacturer.manufacturer }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">Model</td>
      <td><input v-model="items_misc_info.model" class="form-control" /></td>
    </tr>
    <tr>
      <td class="form-label">Start Date</td>
      <td>
        <flatPickr
          v-model="items_misc_info.misc_info_begin_date"
          class="form-control form-control-date flatpickr-input"
          data-bs-focus="false"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">End Date</td>
      <td>
        <flatPickr
          v-model="items_misc_info.misc_info_end_date"
          class="form-control form-control-date flatpickr-input"
          data-bs-focus="false"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">Misc Info</td>
      <td class="w-100">
        <textarea v-model="items_misc_info.misc_info" class="form-control"></textarea>
      </td>
    </tr>
  </table>
</template>
