<script>
import axios from 'axios';

export default {
  data() {
    return {
      employee: {
        id: 0,
        su: 0,
        inactive: 0,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        phone2: '',
        password: '',
        account_type: 'sales'
      }
    };
  },
  mounted() {
    // API get employee
    if (this.$route.params.id > 0) {
      axios
        .get(this.apiUrl + 'admin/employee/' + this.$route.params.id)
        .then((response) => {
          this.employee = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  },
  methods: {
    cancelEmployee() {
      this.$router.push({ path: '/admin/employee' });
    },
    saveEmployee() {
      axios
        .post(this.apiUrl + 'admin/employee', this.employee)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/employee' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Employees / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelEmployee">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveEmployee">Save</button>
    </div>
  </div>

  <!--- {{ $route.params.id }} --->
  <table>
    <tr>
      <td class="form-label">Inactive</td>
      <td>
        <input
          v-model="employee.inactive"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">Administrator</td>
      <td>
        <input
          v-model="employee.su"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">First Name</td>
      <td><input v-model="employee.firstname" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Last Name</td>
      <td><input v-model="employee.lastname" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Email</td>
      <td><input v-model="employee.email" class="form-control" type="email" /></td>
    </tr>
    <tr>
      <td class="form-label">Phone</td>
      <td><input v-model="employee.phone" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Phone2</td>
      <td><input v-model="employee.phone2" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">password</td>
      <td><input v-model="employee.password" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Account Type</td>
      <td>
        <select v-model="employee.account_type" class="form-select form-select-sm">
          <option value="admin">admin</option>
          <option value="sales">sales</option>
          <option value="supervisor">supervisor</option>
        </select>
      </td>
    </tr>
  </table>
</template>
