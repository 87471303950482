<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  mounted() {
    axios
      .get(this.apiUrl + 'admin/run-markups')
      .then((response) => {
        this.showToast('Data saved', response.data.message, 'success');
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Run Markups</h2>
  </div>
</template>
