<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      memoList: []
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/memo')
      .then((response) => {
        this.memoList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addMemo() {
      router.push({ path: '/admin/memo/0' });
    },
    modifyMemo(memo_id) {
      router.push({ path: '/admin/memo/' + memo_id });
    },
    deleteMemo(memo_id, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/memo/' + memo_id)
          .then((resp) => {
            this.memoList.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Memos</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="addMemo">Add</button>
    </div>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Name</td>
        <td>Type</td>
        <td>Memo</td>

        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(memo, index) in memoList" :key="memo.id">
        <td>{{ memo.name }}</td>
        <td>{{ memo.memo_type }}</td>
        <td>{{ memo.memo }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifyMemo(memo.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
        <td class="text-center">
          <a class="hand" :href="javascript" @click="deleteMemo(memo.id, index)">
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
