<script>
import CartButton from '../components/CartButton.vue';
import FreeShipping from '../components/FreeShipping.vue';
import { toRaw } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';

export default {
  name: 'ItemsListComponent',
  components: {
    CartButton,
    FreeShipping
  },

  props: {
    items: {
      type: Object,
      required: false,
      default: () => {}
    },
    filters: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    const authStore = useAuthStore();
    return {
      authStore,
      checkedFilters: [],
      mobileFilterDiv: null
    };
  },
  watch: {
    checkedFilters(val) {
      this.hideMobileFilters();
    }
  },
  mounted() {
    let mobileFilter = document.getElementById('mobileFilterDiv');
    if (mobileFilter) {
      this.mobileFilterDiv = new window.bootstrap.Offcanvas(
        document.getElementById('mobileFilterDiv')
      );
    }
  },
  methods: {
    getShippingInfo(item) {
      let qty =
        item.stock_qty_warehouse_1 +
        item.stock_qty_warehouse_2 +
        item.stock_qty_warehouse_3 +
        item.stock_qty_warehouse_4;

      if (this.clientName == 'atlas' || this.clientName == 'midcity') {
        if (item.dont_use_facts_for_availability == 1) {
          return (
            '<img src="https://ext-chef.s3.us-west-2.amazonaws.com/in_stock.gif" title="in stock (' +
            item.stock_qty_warehouse_2 +
            ')">'
          );
        }
        if (qty <= 0) {
          return ' <span class="not-in-stock">Not in Stock, back-ordered</span>';
        } else {
          if (this.clientName == 'atlas') {
            let avail =
              '<img src="https://ext-chef.s3.us-west-2.amazonaws.com/in_stock.gif" ';
            avail +=
              'title="in stock SB: ' +
              item.stock_qty_warehouse_1 +
              ' INDY: ' +
              item.stock_qty_warehouse_2 +
              ' GR: ' +
              item.stock_qty_warehouse_3 +
              ' FW: ' +
              item.stock_qty_warehouse_4 +
              '">';
            return avail;
          } else {
            return (
              '<img src="https://ext-chef.s3.us-west-2.amazonaws.com/in_stock.gif" title="in stock (' +
              item.stock_qty_warehouse_2 +
              ')">'
            );
          }
        }
      } else {
        return 'Ships in ' + item.availability;
      }
    },
    showMobileFilters() {
      this.mobileFilterDiv.show();
    },
    hideMobileFilters() {
      this.mobileFilterDiv.hide();
    },
    hasMultipleOptions: function (item) {
      return (
        item.color_options.length > 1 ||
        item.gas_options.length > 1 ||
        item.electrical_options.length > 1 ||
        item.misc_options.length > 1
      );
    },
    is_visible: function (item) {
      // BUG (but hard to fix) - if 2 filters have same value, it will show for either
      let checkedFilterValues = toRaw(this.checkedFilters); // need toRaw to get values since its a Proxy object

      // show all items if no filter selected
      if (checkedFilterValues.length == 0) {
        return true;
      }

      // loop all filters and see if we should show it
      let filterNames = this.filters.map((filter) => filter.name);
      for (let idx in filterNames) {
        if (checkedFilterValues.includes(item[filterNames[idx]])) {
          return true;
        }
      }
      return false;
    },
    goToItem: function (url) {
      window.location.href = url;
    },

    addToOrderGuide(item_id) {
      let data = {
        profile_company_id: this.authStore.getProfileCompanyId,
        item_id: item_id
      };
      axios
        .post(this.apiUrl + 'profile/order-guide/add', data)
        .then(() => {
          // add to store
          this.authStore.profile.order_quide_item_ids = [
            ...this.authStore.profile.order_quide_item_ids,
            String(item_id)
          ];
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    deleteFromOrderGuide(item_id) {
      let data = {
        profile_company_id: this.authStore.getProfileCompanyId,
        item_id: item_id
      };
      axios
        .post(this.apiUrl + 'profile/order-guide/delete', data)
        .then(() => {
          // delete from store
          this.authStore.profile.order_quide_item_ids =
            this.authStore.profile.order_quide_item_ids.filter((id) => id != item_id);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    isItemInOrderGuide(itemId) {
      if (this.authStore.isProfileLoggedIn) {
        return (
          this.authStore.profile.order_quide_item_ids &&
          this.authStore.profile.order_quide_item_ids.includes(String(itemId))
        );
      }
      return false;
    }
  }
};
</script>

<template>
  <div class="item-container">
    <div class="row">
      <!-- Filters ----------------------------------------------------------------------------------->
      <!--- Filters mobile screen-->
      <div v-if="filters && filters.length" class="d-lg-none">
        <button class="btn btn-sm btn-light btn-filter" @click="showMobileFilters">
          <font-awesome-icon icon="filter" class="me-1" />Filters
        </button>
        <!-- offcanvas - mobile filters -->
        <div
          id="mobileFilterDiv"
          class="offcanvas offcanvas-start"
          tabindex="-1"
          aria-labelledby="mobileFilterDivLabel"
          data-bs-keyboard="false"
        >
          <div class="offcanvas-header">
            <h5 id="mobileFilterDivLabel" class="offcanvas-title">Filters</h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div class="offcanvas-body">
            <div class="card">
              <div class="card-body">
                <div
                  v-for="filter_section in filters"
                  :key="filter_section.name"
                  class="mb-3"
                >
                  <div class="filter-section-header">
                    {{ filter_section.name.replace(/_/g, ' ').replace('filter', '') }}
                  </div>
                  <div
                    v-for="filter_value in filter_section.values"
                    :key="filter_value.value"
                  >
                    <input
                      :id="filter_value"
                      v-model="checkedFilters"
                      type="checkbox"
                      :value="filter_value"
                      class="mx-2"
                    />
                    <label class="filter-value">{{ filter_value }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--- Filters large screen-->
      <div v-if="filters && filters.length" class="col-lg-3 d-none d-lg-block">
        <div class="filter-container">
          <div class="filter-header"><div class="filter-header-text">Filters</div></div>

          <div v-for="filter_section in filters" :key="filter_section.name" class="mb-3">
            <div class="filter-section-header">
              {{ filter_section.name.replace('filter', '').replace(/_/g, ' ') }}
            </div>
            <div v-for="filter_value in filter_section.values" :key="filter_value.value">
              <input
                :id="filter_value"
                v-model="checkedFilters"
                type="checkbox"
                :value="filter_value"
                class="mx-2"
              />
              <label class="filter-value">{{ filter_value }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Items ----------------------------------------------------------------------------------->
      <div class="col-lg-9">
        <!--- mobile screen-->
        <div class="d-md-none">
          <table class="table">
            <tbody>
              <tr v-for="item in items" v-show="is_visible(item)" :key="item.id">
                <td class="item-image hand" @click="goToItem(item.url_link)">
                  <img :src="item.thumb_image_url" class="thumb-image" />
                </td>
                <td class="hand" @click="goToItem(item.url_link)">
                  <div class="link">{{ item.title }}</div>
                  <div class="row">
                    <div class="col-6">
                      <!---<div class="model">{{ item.model }}</div> -->
                      <div class="model">{{ item.facts_number }}</div>
                      <!--- shipping info -->
                      <span class="mt-2" v-html="getShippingInfo(item)"></span>
                      <FreeShipping
                        v-if="item.free_freight || item.comp_freight"
                      ></FreeShipping>

                      <!--- add Order Guide-->
                      <div v-if="authStore.isProfileLoggedIn" class="add-order-guide">
                        <button
                          v-if="isItemInOrderGuide(item.id)"
                          class="btn btn-secondary btn-sm"
                          @click="deleteFromOrderGuide(item.id)"
                        >
                          remove from order guide
                        </button>
                        <button
                          v-if="!isItemInOrderGuide(item.id)"
                          class="btn btn-secondary btn-sm"
                          @click="addToOrderGuide(item.id)"
                        >
                          add to order guide
                        </button>
                      </div>
                    </div>
                    <div class="col-6 text-end no-wrap">
                      <div
                        v-if="item.sell_price > 0 && item.show_pricing == 1"
                        class="price"
                      >
                        {{ $money(item.sell_price) }}
                      </div>
                      <span
                        v-if="item.sell_price == 0 || item.show_pricing == 0"
                        class="price"
                      >
                        request quote
                      </span>
                      <div class="item-add">
                        <CartButton :item="item"></CartButton>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--- large screen-->
        <div class="d-none d-md-block">
          <table class="table">
            <tbody>
              <tr v-for="item in items" v-show="is_visible(item)" :key="item.id">
                <td class="item-image hand" @click="goToItem(item.url_link)">
                  <img :src="item.thumb_image_url" class="thumb-image" />
                </td>
                <td>
                  <div class="ms-2">
                    <div class="link hand" @click="goToItem(item.url_link)">
                      {{ item.title }}
                    </div>
                    <!---<div class="model">{{ item.model }}</div> -->
                    <div class="model">{{ item.facts_number }}</div>
                    <div class="row">
                      <div class="col-6 mt-2">
                        <!--- shipping info -->
                        <div class="" v-html="getShippingInfo(item)"></div>
                        <FreeShipping
                          v-if="item.free_freight || item.comp_freight"
                        ></FreeShipping>
                      </div>

                      <!--- add Order Guide-->
                      <div class="col-6">
                        <div v-if="authStore.isProfileLoggedIn" class="add-order-guide">
                          <button
                            v-if="isItemInOrderGuide(item.id)"
                            class="btn btn-secondary btn-sm"
                            @click="deleteFromOrderGuide(item.id)"
                          >
                            remove from order guide
                          </button>
                          <button
                            v-if="!isItemInOrderGuide(item.id)"
                            class="btn btn-secondary btn-sm"
                            @click="addToOrderGuide(item.id)"
                          >
                            add to order guide
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-end no-wrap">
                  <div v-if="item.sell_price > 0 && item.show_pricing == 1" class="price">
                    {{ $money(item.sell_price) }}
                  </div>
                  <div
                    v-if="item.sell_price == 0 || item.show_pricing == 0"
                    class="price"
                  >
                    request quote
                  </div>
                  <div class="item-add">
                    <CartButton :item="item"></CartButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-secondary {
  color: #3b4238;
  background-color: #f8f8f7;
  border-color: #bebfc0;
  padding: 0.15rem 0.25rem;
  font-size: 0.65rem;
  margin-top: 10px;
}
.on {
  color: #cf3700;
}
.item-add {
  float: right;
}
.add-order-guide {
  position: relative;
  top: 0;
  left: 0;
}
.table > :not(caption) > * > * {
  padding: 1rem 0;
}
.btn-filter {
  color: #000;
  border-color: var(--bs-btn-hover-border-color);
}
.input-group {
  display: inline;
}
.item-image {
  display: table-cell;
  width: 65px;
  padding-right: 10px;
}
.item-add {
  xxxdisplay: block ruby;
  white-space: nowrap;
}

.col-auto {
  border-left: solid 1px #ccc;
}
.filter-container {
  border: solid 1px #ccc;
  xxxmax-width: 200px;
}
.filter-header {
  background-color: #a80000;
}
.filter-header-text {
  font-weight: bold;
  font-size: 120%;
  color: #ffffff;
  padding-left: 10px;
}
.filter-section-header {
  padding-left: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #3b4238;
}
.filter-value {
  font-weight: 350;
  font-size: 14px;
}
.bottom-border {
  border-bottom: 1px solid #dee2e6;
  margin-top: 15px;
  padding-bottom: 15px;
}
.model {
  font-size: 80%;
}
.item-row:hover {
  background-color: #f8f9fa;
}
</style>
