<script>
import axios from 'axios';
import router from '@/router/index.js';
import OrderComponent from '@/components/OrderComponent.vue';

export default {
  components: {
    OrderComponent
  },
  data() {
    return {
      mergeFromOrderId: '',
      order: {},
      memoList: [],
      newMemo: ''
    };
  },
  mounted() {
    this.getOrder();

    // memos
    axios
      .get(this.apiUrl + 'admin/memo')
      .then((response) => {
        this.memoList = response.data;
        // strip out blank memos
        this.memoList = this.memoList.filter((memo) => memo.name);
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    getOrder() {
      axios
        .get(this.apiUrl + 'admin/orders/' + this.$route.params.id)
        .then((response) => {
          this.order = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    cancel() {
      this.$router.push({ path: '/admin/orders' });
    },

    merge() {
      if (confirm('Do you really want to merge?')) {
        axios
          .get(
            this.apiUrl +
              'admin/orders/merge/' +
              this.mergeFromOrderId +
              '/' +
              this.order.orderid
          )
          .then((response) => {
            this.showToast('Data saved', response.data.message, 'success');
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.detail) {
              this.showToast('Error', error.response.data.detail, 'error');
            } else {
              this.showToast('Error', error.message, 'error');
            }
          });
      }
    },
    sendEmail() {
      axios
        .post(
          this.apiUrl +
            'admin/orders/send-email/' +
            this.order.orderid +
            '/' +
            this.order.email,
          { memo: this.newMemo }
        )
        .then((response) => {
          this.showToast('Email sent', '', 'success');
          // reload it
          router.push({ path: '/admin/orders/view/' + this.order.orderid });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    editOrder() {
      router.push({ path: '/admin/orders/' + this.order.orderid });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center mb-2">
    <h2>Order View</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="editOrder">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="editOrder">Edit</button>
    </div>
  </div>

  <!--- filter -->
  <div class="filter">
    <table class="memo-table">
      <tr>
        <td>
          <input
            v-model="order.email"
            class="form-control inline mr-10"
            placeholder="Email"
          />
        </td>
        <td>
          <select v-model="newMemo" class="form-select form-select-sm w100">
            <option v-for="memo in memoList" :key="memo.id" :value="memo.memo">
              {{ memo.name }}
            </option>
          </select>
        </td>
        <td class="w50">
          <textarea v-model="newMemo" class="form-control w100" rows="3"></textarea>
        </td>
        <td>
          <button class="btn btn-primary btn-sm" @click="sendEmail">Send Email</button
          ><br />
        </td>
      </tr>
    </table>

    <input
      v-model="mergeFromOrderId"
      class="form-control inline mr-10"
      placeholder="From OrderId"
    />
    <button class="btn btn-primary btn-sm" @click="merge">Merge</button><br />
  </div>

  <OrderComponent :order-id="$route.params.id" view-type="admin"></OrderComponent>
</template>

<style scoped>
.w100 {
  width: 100% !important;
}
.memo-table td {
  vertical-align: top;
}
.w50 {
  width: 50% !important;
}
</style>
