<script>
import axios from 'axios';
import router from '../../router/index.js';
import dayjs from 'dayjs';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: { flatPickr },
  data() {
    let begin_date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let end_date = new Date();

    return {
      resultList: [],
      employeeList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,
      filters: {
        date_ordered_begin: dayjs(begin_date).format('YYYY-MM-DD'),
        date_ordered_end: dayjs(end_date).format('YYYY-MM-DD'),
        employee_id: 0,
        order_id: '',
        status: '',
        order_misc: ''
      }
    };
  },
  computed: {
    total() {
      return (
        Math.round(this.resultList.reduce((total, item) => total + item.total, 0) * 100) /
        100
      );
    }
  },
  mounted() {
    // get orders
    this.getAllResultList(this.paginationCurrentPage);
    // get employee dropdown
    axios
      .get(this.apiUrl + 'admin/employee/dropdowns')
      .then((response) => {
        this.employeeList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    getAllResultList(page) {
      axios
        .post(this.apiUrl + 'admin/orders?page_number=' + page, this.filters)
        .then((response) => {
          this.resultList = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
        });
    },
    viewOrder(orderid) {
      router.push({ path: '/admin/orders/view/' + orderid });
    },
    processPagination(page) {
      this.getAllResultList(page);
    },
    fitlerOrders() {
      this.getAllResultList(1);
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Orders</h2>
  </div>

  <!--- filter -->
  <div class="filter">
    <flatPickr
      v-model="filters.date_ordered_begin"
      class="form-control form-control-date flatpickr-input mr-10 inline"
      data-bs-focus="false"
    />
    to
    <flatPickr
      v-model="filters.date_ordered_end"
      class="form-control form-control-date flatpickr-input ml-10 inline"
      data-bs-focus="false"
    /><input
      v-model="filters.order_misc"
      type="text"
      class="form-control form-control-sm"
      placeholder="Search"
    />

    <input
      v-model="filters.order_id"
      type="text"
      class="form-control form-control-sm"
      placeholder="Order Id"
    />
    <select v-model="filters.employee_id" class="form-select form-select-sm">
      <option value="0">all employees</option>
      <option v-for="employee in employeeList" :key="employee.id" :value="employee.id">
        {{ employee.name }}
      </option>
    </select>

    <select v-model="filters.status" class="form-select form-select-sm">
      <option value="">all status</option>
      <option value="quote">quote</option>
      <option value="incomplete">incomplete</option>
      <option value="being processed">being processed</option>
      <option value="pending order">pending order</option>
      <option value="pending shipping">pending shipping</option>
      <option value="pending customer pickup">pending customer pickup</option>
      <option value="pending route delivery">pending route delivery</option>
      <option value="in transit">in transit</option>
      <option value="all items delivered">all items delivered</option>
      <option value="canceled">canceled</option>
    </select>
    <button class="btn btn-primary btn-sm" @click="fitlerOrders">filter</button>
  </div>

  <div class="pagination-container">
    <vue-awesome-paginate
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
      :on-click="processPagination"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <th>Order ID</th>
        <th>Cobrand</th>
        <th>Date Quoted</th>
        <th>Name</th>
        <th>Status</th>
        <th>Payment Type</th>
        <th>Internal Memo</th>
        <th>Customer Memo</th>
        <th>Total</th>
        <th>Due</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in resultList" :key="order.orderid">
        <td>
          <a href="#" @click="viewOrder(order.orderid)">
            {{ order.orderid }}
          </a>
        </td>
        <td>{{ order.cobrand }}</td>
        <td>{{ $getDateTime(order.date_quoted) }}</td>
        <td>{{ order.billto_firstname }} {{ order.billto_lastname }}</td>
        <td>{{ order.status }}</td>

        <td>{{ order.checkout_type }}</td>
        <td>{{ order.memo }}</td>
        <td>{{ order.customer_memo }}</td>
        <td class="right">{{ order.total }}</td>
        <td class="right">{{ order.total_due }}</td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td class="right">Total</td>
        <td>
          <b class="right">{{ total }}</b>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>
