<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      item_list: [],
      section_list: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,
      filters: {
        manufacturer_code: '',
        item_misc: '',
        section_id: 0
      },
      manufacturers: []
    };
  },
  mounted() {
    this.getAllresultList(this.paginationCurrentPage);

    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    axios
      .get(this.apiUrl + 'admin/section/dropdown')
      .then((response) => {
        this.section_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    processPagination(page) {
      this.getAllresultList(page);
    },
    getAllresultList(page) {
      axios
        .post(this.apiUrl + 'admin/item?page_number=' + page, this.filters)
        .then((response) => {
          this.item_list = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    additem() {
      router.push({ path: '/admin/item/0' });
    },
    modifyitem(item_id) {
      router.push({ path: '/admin/item/' + item_id });
    },
    deleteitem(item_id, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/item/' + item_id)
          .then((resp) => {
            this.item_list.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Items</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="additem">Add</button>
    </div>
  </div>

  <!-- Filters -->
  <div class="filter">
    <font-awesome-icon icon="fa-filter" class="mr-10" />Filters<br />
    <input
      v-model="filters.item_misc"
      type="text"
      class="form-control form-control-sm"
      placeholder="model/title/sku"
    />
    <select v-model="filters.manufacturer_code" class="form-select form-select-sm">
      <option value="">all manufacturers</option>
      <option
        v-for="manufacturer in manufacturers"
        :key="manufacturer.manufacturer_code"
        :value="manufacturer.manufacturer_code"
      >
        {{ manufacturer.manufacturer }}
      </option>
    </select>
    <select v-model="filters.section_id" class="form-select form-select-sm">
      <option value="0">all sections</option>
      <option v-for="section in section_list" :key="section.name" :value="section.id">
        {{ section.name }}
      </option>
    </select>
    <button class="btn btn-primary btn-sm" @click="processPagination(1)">search</button>
  </div>

  <!-- Pagination -->
  <div class="pagination-container">
    <vue-awesome-paginate
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
      :on-click="processPagination"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Manufacturer</td>
        <td>Model</td>
        <td>SKU</td>

        <td>Title/Section/Filter</td>
        <td>Availability</td>
        <td>Options</td>

        <td class="text-center">OverrideCOG</td>
        <td class="text-center">OverrideMarkup</td>
        <td class="text-center">Discontinued</td>
        <td class="text-center">DoNotDisplay</td>

        <td class="text-center">Image</td>
        <td class="text-center">Warranty</td>
        <td class="text-center">Pdf</td>
        <td class="text-center">Specs</td>

        <td>Modified</td>
        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in item_list" :key="item.id">
        <td>{{ item.manufacturer }}</td>
        <td>{{ item.model }}</td>
        <td>{{ item.facts_number }}</td>

        <td>
          {{ item.title }}<br />
          {{ item.filters }}<br />
          <span v-for="section in item.sections" :key="section"
            >{{ section.section }}<br
          /></span>
        </td>
        <td>{{ item.availability }}</td>
        <td>{{ item.options }}</td>

        <td class="right">
          <span v-for="override_cog in item.override_cogs" :key="override_cog"
            >{{ $money(override_cog.cog) }}<br
          /></span>
        </td>
        <td class="right">
          <span v-for="override_markup in item.override_markups" :key="override_markup"
            >{{ $money(override_markup.markup) }}<br
          /></span>
        </td>
        <td class="text-center">
          <span v-if="item.discontinued"><font-awesome-icon icon="fa-check" /></span>
        </td>
        <td class="text-center">
          <span v-if="item.do_not_display"><font-awesome-icon icon="fa-check" /></span>
        </td>

        <td class="text-center">
          <span v-if="item.image_url"><font-awesome-icon icon="fa-check" /></span>
        </td>
        <td class="text-center">
          <span v-if="item.warranty"><font-awesome-icon icon="fa-check" /></span>
        </td>
        <td class="text-center">
          <span v-if="item.pdf_url"><font-awesome-icon icon="fa-check" /></span>
        </td>
        <td class="text-center">
          <span v-if="item.specs"><font-awesome-icon icon="fa-check" /></span>
        </td>

        <td>{{ $getDateTime(item.date_modified) }}</td>
        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifyitem(item.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
        <td class="text-center">
          <a class="hand" :href="javascript" @click="deleteitem(item.id, index)">
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
