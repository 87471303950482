const filter_names = [
    'filter_doors',
    'filter_cooling_type',
    'filter_ice_type',
    'filter_ice_head_width',
    'filter_power',
    'filter_pan_size',
    'filter_pans',
    'filter_quarts',
    'filter_standing',
    'filter_wells',
    'filter_width',
    'filter_misc'
];

let filter_doors = [
    '1 door',
    '2 doors',
    '3 doors',
    '4 doors',
    '6 doors',
    '8 doors',
    '12 doors',
    'Left Door Hinge',
    'Bottom Mount Compressor',
    'Top Mount Compressor',
    'Soild Door',
    'Glass Door',
    'Merchandiser'
  ];
  let filter_cooling_type = [
    'refrigerated',
    'non refrigerated',
    'dual',
    'Cold Plate',
    'Freezer',
    'Refrigerator',
    'Undercounter',
    'Sandwich Prep Station',
    'Back Bar',
    'Draft Beer Cooler'
  ];
  let filter_ice_type = [
    'cube',
    'flake',
    'nugget',
    'Bar Ice Bin',
    'Cocktail Station',
    'Ice Caddy'
  ];
  let filter_ice_head_width = [
    '22 inches',
    '30 inches',
    '48 inches',
    'Air Cooled',
    'Water Cooled',
    '22" Storage Bin',
    '30" Storage Bin',
    '48"Storage Bin',
    'Bin Adapter'
  ];
  let filter_power = [
    'gas',
    'electric',
    'Covers',
    'Gas Hose',
    'Regulator',
    '120 V',
    '208 V',
    'Induction',
    'Warmer Bulb',
    'Warmer Heating Rod',
    'Manual',
    'Automatic'
  ];
  let filter_pan_size = [
    'full',
    '1/2 pan',
    '1/3 pan',
    '1/4 pan',
    '1/6 pan',
    '1/9 pan',
    'round',
    'covers',
    '18x26x3',
    '18x26x6',
    '18x26x9',
    '18x26x15',
    '12x18x3',
    '12x18x6',
    '12x18x9',
    'Color - Clear',
    'Color - White',
    'Vollrath',
    'Browne Foodservice',
    'Cambro',
    'Roasting'
  ];
  let filter_pans = [
    '1 pan',
    '2 pans',
    '3 pans',
    '4 pans',
    '5 pans',
    '6 pans',
    'Dishwasher Rack',
    'Flat',
    'Peg',
    '9 Compartment',
    '16 compartment',
    '25 Compartment',
    '36 Compartment',
    'Extender'
  ];
  let filter_quarts = [
    '1 quart',
    '2 quarts',
    '3 quarts',
    '4 quarts',
    '5 quarts',
    '6 quarts',
    '7 quarts',
    '8 quarts',
    '9 quarts',
    '10 quarts',
    '11 quarts',
    '20 quarts',
    '30 quarts',
    '40 quarts',
    '50 quarts',
    '60 quarts',
    '70 quarts',
    '80 quarts',
    '90 quarts',
    '100 quarts',
    '110 quarts',
    '120+ quarts',
    'Covers',
    'Square',
    'Color - Clear',
    'Color - White',
    'Color - Black',
    'Bain Marie',
    'Stock Pot',
    'Sauce Pan',
    'Brazier',
    'Wok'
  ];
  let filter_standing = [
    'countertop',
    'drop in',
    'free standing',
    'Caster',
    '1 Compartment Sink',
    '2 Compartment Sink',
    '3 Compartment Sink',
    'Hand Sink',
    'Nozzle',
    'Faucet',
    'Pre Rinse',
    'Single Drain Board',
    'Dbl Drain Board',
    'No Drain Board'
  ];
  let filter_wells = [
    '1 well',
    '2 wells',
    '3 wells',
    '4 wells',
    '5 wells',
    'Plug 5-30P',
    'Plug 5-15P',
    'LP Gas',
    'NG Gas',
    'Pop-Up',
    'Conveyor',
    'Pour Over',
    'Percolator',
    'In-Line',
    'Food Holding',
    'Insulated',
    'Non-Insulated',
    'Slide Top'
  ];
  let filter_width = [
    '24 inches and under',
    '30 inches',
    '36 inches',
    '48 inches',
    '60 inches',
    '72 inches',
    '84 inches',
    '96 inches',
    '108 inches',
    '120 inches',
    'Caster',
    'Post',
    'Wire',
    'Wall Mount',
    'Counter Top',
    'Floor Model'
  ];
  let filter_misc = [
    'Narrow Rim',
    'Wide Rim',
    'Coffee',
    'Soup',
    'Round',
    'Square',
    'Oval',
    'Platter'
  ];

let filters = {
    'filter_names': filter_names,
    'filter_doors': filter_doors,
    'filter_cooling_type': filter_cooling_type,
    'filter_ice_type': filter_ice_type,
    'filter_ice_head_width': filter_ice_head_width,
    'filter_power': filter_power,
    'filter_pan_size': filter_pan_size,
    'filter_pans': filter_pans,
    'filter_quarts': filter_quarts,
    'filter_standing': filter_standing,
    'filter_wells': filter_wells,
    'filter_width': filter_width,
    'filter_misc': filter_misc,
} 
export default filters