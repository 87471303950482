<script>
// this is setup in 
export default {
  methods: {
    closeToast() {
      const toastLiveExample = document.getElementById('toast');
      const toastBootstrap = new bootstrap.Toast(toastLiveExample);
      toastBootstrap.hide();
    }
  }
};
</script>

<template>
  <!--- Toast ---------------------------------------------------------------------------------->
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div id="toast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <div class="me-auto">
          <div id="toast_title"></div>
        </div>

        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="closeToast"
        ></button>
      </div>
      <div id="toast_body" class="toast-body"></div>
    </div>
  </div>
</template>
