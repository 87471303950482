<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      memo: {
        id: 0,
        name: '',
        memo_type: '',
        memo: ''
      }
    };
  },
  mounted() {
    // API get memo
    if (this.$route.params.id > 0) {
      axios
        .get(this.apiUrl + 'admin/memo/' + this.$route.params.id)
        .then((response) => {
          this.memo = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  },
  methods: {
    cancelMemo() {
      this.$router.push({ path: '/admin/memo' });
    },
    saveMemo() {
      axios
        .post(this.apiUrl + 'admin/memo', this.memo)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/memo' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Memo / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelMemo">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveMemo">Save</button>
    </div>
  </div>

  <table class="">
    <tr>
      <td class="form-label">Name</td>
      <td><input v-model="memo.name" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Memo Type</td>
      <td><input v-model="memo.memo_type" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Memo</td>
      <td><textarea v-model="memo.memo" class="form-control"></textarea></td>
    </tr>
  </table>
</template>
