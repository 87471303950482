<script>
import ModalPopupVideo from './ModalPopupVideo';
export default {
  name: 'App',
  components: {
    ModalPopupVideo
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      images: [],
      thumbs: [],
      currentDisplay: ''
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    swap(index) {
      this.currentDisplay = this.images[index];
    },
    getImageHTML(image) {
      return `<img src="${image}" class="img-fluid" />`;
    },
    getImages() {
      if (this.item.image_url) {
        this.currentDisplay = this.getImageHTML(this.item.image_url);
        this.images.push(this.getImageHTML(this.item.image_url));
        this.thumbs.push(this.getImageHTML(this.item.thumb_image_url));
      }
      if (this.item.image2_url) {
        this.images.push(this.getImageHTML(this.item.image2_url));
        this.thumbs.push(this.getImageHTML(this.item.thumb_image2_url));
      }
      if (this.item.image3_url) {
        this.images.push(this.getImageHTML(this.item.image3_url));
        this.thumbs.push(this.getImageHTML(this.item.thumb_image3_url));
      }
      if (this.item.image4_url) {
        this.images.push(this.getImageHTML(this.item.image4_url));
        this.thumbs.push(this.getImageHTML(this.item.thumb_image4_url));
      }
      if (this.item.image5_url) {
        this.images.push(this.getImageHTML(this.item.image5_url));
        this.thumbs.push(this.getImageHTML(this.item.thumb_image5_url));
      }
    },

    showModalVideo() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-video'));
      myModal.show();
    }
  }
};
</script>

<template>
  <div class="image-thumb-div">
    <!--- image -->
    <div class="image-div">
      <span v-html="currentDisplay"></span>
    </div>

    <!--- thumbs -->
    <div class="thumb-div">
      <div class="thumbs">
        <span v-for="(thumb, index) in thumbs" :key="thumb">
          <div class="hand image-boarder" @click="swap(index)" v-html="thumb"></div>
        </span>

        <!--- video -->
        <span v-if="item.you_tube_id">
          <div class="hand image-boarder" @click="showModalVideo">
            <img src="https://ext-chef.s3.us-west-2.amazonaws.com/video-icon.jpg" />
          </div>
          <ModalPopupVideo :you_tube_id="item.you_tube_id"></ModalPopupVideo>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .thumb-div {
    max-width: 65px;
  }
  .image-thumb-div {
    display: block;
    margin-bottom: 10px;
  }
  .thumbs {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    overflow: none;
  }
}
@media (min-width: 768px) {
  .thumb-div {
    max-width: 65px;
  }
  .image-div {
    max-width: 500px;
  }
  .image-thumb-div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .thumbs {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    overflow: none;
  }
}
</style>
