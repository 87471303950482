<script>
import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';
import router from '../../router/index.js';
import dayjs from 'dayjs';

export default {
  data() {
    const authStore = useAuthStore();
    return {
      authStore,
      resultList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,
      fromProfileCompanyId: '',
      toProfileCompanyId: '',
      dayjs: dayjs,
      filters: {
        employee_id: 0,
        profile_misc: '',
        sort_column: '', // Tracks the column being sorted
        sort_order: 'asc' // Tracks the sorting direction ('asc' or 'desc')
      },
      employeeList: []
    };
  },
  mounted() {
    this.getAllresultList(this.paginationCurrentPage);

    // API get employee
    axios
      .get(this.apiUrl + 'admin/employee/dropdowns')
      .then((response) => {
        this.employeeList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    sortBy(column) {
      this.paginationCurrentPage = 1;
      if (this.filters.sort_column === column) {
        this.filters.sort_order = this.filters.sort_order === 'asc' ? 'desc' : 'asc';
      } else {
        this.filters.sort_column = column;
        this.filters.sort_order = 'asc';
      }
      this.resultList = [];
      this.getAllresultList(this.paginationCurrentPage);
    },
    merge() {
      if (confirm('Do you really want to merge?')) {
        if (this.fromProfileCompanyId > 0 && this.toProfileCompanyId > 0) {
          axios
            .post(this.apiUrl + 'admin/profile/merge', {
              from_profile_company_id: this.fromProfileCompanyId,
              to_profile_company_id: this.toProfileCompanyId
            })
            .then(() => {
              this.getAllresultList(this.paginationCurrentPage);
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.data.detail) {
                this.showToast('Error', error.response.data.detail, 'error');
              } else {
                this.showToast('Error', error.message, 'error');
              }
            });
        }
      }
    },
    getAllresultList(page) {
      axios
        .post(this.apiUrl + 'admin/profile?page_number=' + page, {
          ...this.filters
        })
        .then((response) => {
          this.resultList = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    // auto login as profile - on ProfileList.vue and ProfileAddMod.vue
    async loginProfile(profileid) {
      const data = { profileid };
      await axios
        .post(this.apiUrl + 'admin/profile/login-as', data)
        .then((response) => {
          this.authStore.cartItems = [];
          // select 1st company by default
          this.authStore.profile = response.data[0];

          // save list off
          this.authStore.profile_companies = response.data;

          // convert to array of ids
          this.authStore.profile.order_quide_item_ids =
            this.authStore.profile.order_quide_item_ids.split(',');

          this.authStore.profile.profile_assigned_to_item_ids =
            this.authStore.profile.profile_assigned_to_item_ids.split(',');

          if (this.authStore.hasOrderGuide) {
            router.push({ path: '/profile/order-guide' });
          } else {
            router.push({ path: '/' });
          }
        })
        .catch((error) => {
          this.authStore.profile = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    addProfile() {
      router.push({
        path: '/admin/profile/0'
        // query: { profile_company_id: profile_company_id }
      });
    },
    modifyProfile(profile_id) {
      router.push({ path: '/admin/profile/' + profile_id });
    },
    deleteProfile(profile_id, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/profile/' + profile_id)
          .then(() => {
            this.resultList.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addProfileCompany() {
      router.push({ path: '/admin/profile/company/0' });
    },
    modifyProfileCompany(profile_company_id) {
      router.push({ path: '/admin/profile/company/' + profile_company_id });
    },
    deleteProfileCompany(profile_company_id, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/profile/company/' + profile_company_id)
          .then(() => {
            this.resultList.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getPricingTier(tier) {
      return tier === '0' ? 'standard' : tier;
    },
    processPagination(page) {
      this.getAllresultList(page);
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Profiles</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm mx-3" @click="addProfileCompany">
        Add Company
      </button>
      <button class="btn btn-primary btn-sm" @click="addProfile">Add Buyer</button>
    </div>
  </div>

  <!-- Filters -->
  <div class="filter">
    <div class="row">
      <div class="col-6">
        <font-awesome-icon icon="fa-filter" class="mr-10" />Filters<br />
        <input
          v-model="filters.profile_misc"
          type="text"
          class="form-control form-control-sm"
          placeholder="Search"
        />
        <select v-model="filters.employee_id" class="form-select form-select-sm">
          <option value="0">All</option>
          <option
            v-for="employee in employeeList"
            :key="employee.id"
            :value="employee.id"
          >
            {{ employee.name }}
          </option>
        </select>
        <button class="btn btn-primary btn-sm" @click="processPagination(1)">
          search
        </button>
      </div>

      <div class="col-6">
        <div class="float-end">
          <font-awesome-icon icon="fa-code-merge" class="mr-10" />Merge<br />
          <input
            v-model="fromProfileCompanyId"
            type="number"
            min="1"
            maxlength="6"
            class="form-control form-control-sm"
            placeholder="from profile id"
          />

          <input
            v-model="toProfileCompanyId"
            type="number"
            min="1"
            maxlength="6"
            class="form-control form-control-sm"
            placeholder="to profile id"
          />
          <button class="btn btn-primary btn-sm" @click="merge()">merge</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Pagination -->
  <div class="pagination-container">
    <vue-awesome-paginate
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
      :on-click="processPagination"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td class="hand" @click="sortBy('profile_companies.company')">Company</td>
        <td class="hand" @click="sortBy('profile_companies.id')">Id</td>
        <td class="hand" @click="sortBy('profile_companies.created_at')">Created</td>
        <td class="hand" @click="sortBy('profile_companies.facts_number')">FACTS</td>
        <td class="hand" @click="sortBy('employees.lastname')">Salesperson</td>
        <td>Warehouse</td>

        <td class="text-center">PricingTier</td>
        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>

        <td>Buyer</td>
        <td>Email</td>
        <td class="text-center">Login</td>
        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(result, index) in resultList" :key="result.profileid">
        <td>{{ result.company }}</td>
        <td>{{ result.profile_company_id }}</td>
        <td>
          <span v-if="result.created_at">{{
            dayjs(result.created_at).format('YYYY-MM-DD')
          }}</span>
        </td>
        <td>{{ result.facts_number }}</td>
        <td>{{ result.employee_name }}</td>
        <td>{{ result.warehouse }}</td>

        <td class="text-center">{{ getPricingTier(result.pricing_tier) }}</td>
        <td class="text-center">
          <a class="hand" @click="modifyProfileCompany(result.profile_company_id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
        <td class="text-center">
          <a class="hand" @click="deleteProfileCompany(result.profile_company_id, index)">
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </td>

        <td class="buyer">{{ result.buyer_name }}</td>
        <td class="buyer">{{ result.buyer_email }}</td>
        <td class="text-center buyer">
          <span v-if="result.profileid > 0">
            <a class="hand" @click="loginProfile(result.profileid)">
              <font-awesome-icon icon="fa-sign-in" /> </a
          ></span>
        </td>
        <td class="text-center buyer">
          <span v-if="result.profileid > 0">
            <a class="hand" @click="modifyProfile(result.profileid)">
              <font-awesome-icon icon="fa-pen" /> </a
          ></span>
        </td>
        <td class="text-center buyer">
          <span v-if="result.profileid > 0">
            <a class="hand" @click="deleteProfile(result.profileid, index)">
              <font-awesome-icon icon="fa-trash-alt" /> </a
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
.buyer {
  background-color: #d1f1fe;
}
</style>
