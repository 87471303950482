<script>
export default {
  name: 'App',
  props: {
    you_tube_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<template>
  <!-- Modal https://codepen.io/JacobLett/pen/xqpEYE -->
  <div
    id="modal-video"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <!-- 16:9 aspect ratio -->
          <div class="ratio ratio-16x9">
            you_tube_id {{ you_tube_id }}
            <iframe
              id="video"
              class="embed-responsive-item"
              :src="`https://www.youtube.com/embed/${you_tube_id}?autoplay=1showinfo=0`"
              allowscriptaccess="always"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-secondary btn-close2"
          data-bs-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.modal-body {
  position: relative;
  padding: 0px;
}
.btn-close2 {
  border-radius: 0;
}
</style>
