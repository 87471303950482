<script>
import axios from 'axios';
import router from '../router/index.js';
import PolicyAtlas from './layouts/PolicyAtlas.vue';
import PolicyMidcity from './layouts/PolicyMidcity.vue';
import PolicyMychefstore from './layouts/PolicyMychefstore.vue';

export default {
  name: 'OrderComponent',
  components: {
    PolicyAtlas,
    PolicyMychefstore,
    PolicyMidcity
  },
  props: {
    orderId: {
      type: String,
      required: true,
      default: '0'
    },
    email: {
      type: String,
      required: false,
      default: ''
    },
    viewType: {
      type: String,
      required: true,
      default: 'customer'
    }
  },
  data() {
    return {
      order: {},
      pdfs: [],
      paymentTotal: 0
    };
  },
  mounted() {
    let orderPath = 'admin/orders/' + this.orderId;

    if (this.viewType == 'customer') {
      orderPath = 'profile/' + this.orderId + '/' + this.email;
    }

    axios
      .get(this.apiUrl + orderPath)
      .then((response) => {
        this.order = response.data;

        this.paymentTotal = 0;
        for (let i = 0; i < this.order.payment_histories.length; i++) {
          this.paymentTotal += this.order.payment_histories[i].amount;
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    return;

    axios
      .get(this.apiUrl + 'admin/orders/pdfs/' + this.orderId)
      .then((response) => {
        this.pdfs = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    getRebateableProfit() {
      let profit = 0;
      for (let order_item in this.order.order_items) {
        if (order_item.rebateable) {
          profit += 0.1 * order_item.qty * order_item.cog;
          for (let order_item_accessory of order_item.order_item_accessories) {
            profit += 0.1 * order_item_accessory.qty * order_item_accessory.cog;
          }
        }
      }
      return profit;
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex">Order {{ order.orderid }}</div>
        <div class="card-body">
          <table class="">
            <tr>
              <td class="form-label">Status</td>
              <td>{{ order.status }}</td>
            </tr>
            <tr>
              <td class="form-label">Date Quoted</td>
              <td>{{ $getDateTime(order.date_quoted) }}</td>
            </tr>
            <tr>
              <td class="form-label">Date Ordered</td>
              <td>{{ $getDateTime(order.date_ordered) }}</td>
            </tr>
            <tr>
              <td class="form-label">Payment Type</td>
              <td>{{ order.checkout_type }}</td>
            </tr>
            <tr>
              <td class="form-label">Shipping Option</td>
              <td>{{ order.shipping_option }}</td>
            </tr>
            <tr>
              <td class="form-label">Sales Person</td>
              <td v-if="order.employee">
                {{ order.employee.firstname }}
                {{ order.employee.lastname }}
              </td>
            </tr>
            <tr>
              <td class="form-label">Profile</td>
              <td v-if="order.profile">
                <a :href="`/admin/profile/company/${order.profile.profile_company_id}`">
                  {{ order.profile.firstname }}
                  {{ order.profile.lastname }}</a
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col no-print">
      <div class="card">
        <div class="card-header">Memos</div>
        <div class="card-body">
          <div v-if="viewType == 'admin'">
            Internal Memo <br />
            <textarea v-model="order.memo" class="form-control" rows="3"></textarea>
          </div>
          <br />

          <div v-if="viewType == 'admin'">
            Customer Memo <br />
            <textarea
              v-model="order.customer_memo"
              class="form-control"
              rows="3"
            ></textarea>
          </div>
          <div v-else>
            <p>{{ order.customer_memo }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--- Bill To - Ship To -->
  <div class="row py-3">
    <div class="col">
      <div class="card">
        <div class="card-header">Bill To</div>
        <div class="card-body">
          {{ order.billto_firstname }} {{ order.billto_lastname }}<br />
          <span v-if="order.billto_company">{{ order.billto_company }}<br /></span>
          <span v-if="order.billto_address1">{{ order.billto_address1 }}<br /></span>
          <span v-if="order.billto_address2">{{ order.billto_address2 }}<br /></span>
          {{ order.billto_city }} {{ order.billto_state }} {{ order.billto_zip }}
          <br /><br />
          <span v-if="order.billto_email">{{ order.billto_email }}<br /></span>
          <span v-if="order.billto_phone">{{ order.billto_phone }}<br /></span>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-header">Ship To</div>
        <div class="card-body">
          {{ order.firstname }} {{ order.lastname }}<br />
          <span v-if="order.company">{{ order.company }}<br /></span>
          <span v-if="order.address1">{{ order.address1 }}<br /></span>
          <span v-if="order.address2">{{ order.address2 }}<br /></span>
          {{ order.city }} {{ order.state }} {{ order.zip }} <br /><br />
          <span v-if="order.email">{{ order.email }}<br /></span>
          <span v-if="order.phone">{{ order.phone }}<br /></span>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />

  <!--- payment history -->
  <div v-if="viewType == 'admin'" class="card">
    <div class="card-header">Payment History</div>
    <div class="card-body">
      <table class="table table-hover table-sm" style="width: 100%">
        <thead class="table-light">
          <tr>
            <td>DateEntered</td>
            <td>Source</td>
            <td>TransId</td>
            <td>AuthCode</td>
            <td>Memo</td>
            <td class="right">Amount</td>
          </tr>
        </thead>
        <tbody
          v-for="payment_history in order.payment_histories"
          :key="payment_history.id"
        >
          <tr>
            <td>{{ $getDateTime(payment_history.date_entered) }}</td>
            <td>{{ payment_history.source }}</td>
            <td>{{ payment_history.trans_id }}</td>
            <td>{{ payment_history.auth_code }}</td>
            <td>{{ payment_history.memo }}</td>
            <td class="right">{{ payment_history.amount }}</td>
          </tr>
        </tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="form-label">Total</td>
          <td class="right">{{ $money(paymentTotal) }}</td>
        </tr>
      </table>
    </div>
  </div>
  <br />
  <br />

  <table class="table table-hover table-sm" style="width: 100%">
    <thead class="table-light">
      <tr>
        <td>Orderid</td>
        <td>Company</td>
        <td>SKU</td>
        <td>Item</td>
        <td v-if="viewType == 'admin'" class="right">Cog</td>
        <td v-if="viewType == 'admin'" class="right">CogTax</td>
        <td class="right">Price</td>
        <td class="right">Tax</td>
        <td class="right">Qty</td>
        <td class="right">Total</td>
        <td v-if="viewType == 'admin'" class="right">Profit</td>
      </tr>
    </thead>
    <tbody v-for="order_item in order.order_items" :key="order_item.orderid">
      <tr>
        <td>{{ order_item.order_itemid }}</td>
        <td>{{ order_item.manufacturer }}</td>
        <td>
          {{ order_item.facts_number }}
        </td>
        <td>{{ order_item.title }}</td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item.cog) }}
        </td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item.cog_tax_total) }}
        </td>
        <td class="right">{{ $money(order_item.sell_price) }}</td>
        <td class="right">{{ $money(order_item.tax_total) }}</td>
        <td class="right">{{ order_item.qty }}</td>

        <td v-if="order_item.sell_price > 0" class="right">
          {{ $money(order_item.qty * (order_item.sell_price + order_item.tax_total)) }}
        </td>
        <td v-if="order_item.sell_price == 0" class="right quote-label">request quote</td>

        <td v-if="viewType == 'admin'" class="right">
          {{
            $money(
              order_item.qty *
                (order_item.sell_price +
                  order_item.tax_total -
                  order_item.cog -
                  order_item.cog_tax_total)
            )
          }}
        </td>
      </tr>

      <!--- serial_number -->
      <tr v-if="order_item.serial_number > ''">
        <td></td>
        <td></td>
        <td></td>
        <td>serial: {{ order_item.serial_number }}</td>
        <td v-if="viewType == 'admin'" class="right"></td>
        <td v-if="viewType == 'admin'" class="right"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td v-if="viewType == 'admin'"></td>
      </tr>
      <!--- options -->
      <tr v-if="order_item.options > ''">
        <td></td>
        <td></td>
        <td></td>
        <td>{{ order_item.options }}</td>
        <td v-if="viewType == 'admin'" class="right"></td>
        <td v-if="viewType == 'admin'" class="right"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td v-if="viewType == 'admin'"></td>
      </tr>

      <!--- order_item_accessories -->
      <tr
        v-for="order_item_accessory in order_item.order_item_accessories"
        :key="order_item_accessory.orderid"
      >
        <td></td>
        <td>{{ order_item_accessory.manufacturer }}</td>
        <td>{{ order_item_accessory.model }}</td>
        <td>{{ order_item_accessory.description }}</td>

        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item_accessory.cog) }}
        </td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item_accessory.cog_tax_total) }}
        </td>
        <td class="right">{{ $money(order_item_accessory.sell_price) }}</td>
        <td class="right">{{ $money(order_item_accessory.tax_total) }}</td>
        <td class="right">{{ order_item_accessory.qty }}</td>
        <td class="right">
          {{
            $money(
              order_item_accessory.qty *
                (order_item_accessory.sell_price + order_item_accessory.tax_total)
            )
          }}
        </td>
        <td v-if="viewType == 'admin'"></td>
      </tr>

      <!--- freight 1 -->
      <tr v-if="order_item.freight_rate_1 > 0">
        <td></td>
        <td>{{ order_item.freight_company_1 }}</td>
        <td>{{ order_item.freight_memo_1 }}</td>
        <td></td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item.freight_cog_1) }}
        </td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item.freight_cog_tax_total_1) }}
        </td>
        <td class="right">{{ $money(order_item.freight_rate_1) }}</td>
        <td class="right">{{ $money(order_item.freight_tax_total_1) }}</td>
        <td></td>
        <td class="right">
          {{ $money(order_item.freight_rate_1 + order_item.freight_tax_total_1) }}
        </td>
        <td v-if="viewType == 'admin'" class="right">
          {{
            $money(
              order_item.freight_rate_1 +
                order_item.freight_tax_total_1 -
                order_item.freight_cog_1 -
                order_item.freight_cog_tax_total_1
            )
          }}
        </td>
      </tr>
      <!--- freight2 -->
      <tr v-if="order_item.freight_company_2 > '-'">
        <td></td>
        <td>{{ order_item.freight_company_2 }}</td>
        <td>{{ order_item.freight_memo_2 }}</td>
        <td></td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item.freight_cog_2) }}
        </td>
        <td v-if="viewType == 'admin'" class="right">
          {{ $money(order_item.freight_cog_tax_total_2) }}
        </td>
        <td class="right">{{ $money(order_item.freight_rate_2) }}</td>
        <td class="right">{{ $money(order_item.freight_tax_total_2) }}</td>
        <td></td>
        <td class="right">
          {{ $money(order_item.freight_rate_2 + order_item.freight_tax_total_2) }}
        </td>
        <td v-if="viewType == 'admin'" class="right">
          {{
            $money(
              order_item.freight_rate_2 +
                order_item.freight_tax_total_2 -
                order_item.freight_cog_2 -
                order_item.freight_cog_tax_total_2
            )
          }}
        </td>
      </tr>
    </tbody>

    <!--- fee -->
    <tr v-if="order.fee != 0">
      <td></td>
      <td></td>
      <td></td>
      <td>{{ order.fee_memo }}</td>
      <td v-if="viewType == 'admin'" class="right"></td>
      <td v-if="viewType == 'admin'" class="right"></td>
      <td></td>
      <td></td>
      <td class="form-label">Fee</td>
      <td class="right">{{ $money(order.fee) }}</td>
      <td v-if="viewType == 'admin'"></td>
    </tr>

    <!--- total -->
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td v-if="viewType == 'admin'" class="right"></td>
      <td v-if="viewType == 'admin'" class="right"></td>
      <td></td>
      <td></td>
      <td class="form-label">Total</td>
      <td class="right">{{ $money(order.total) }}</td>
      <td v-if="viewType == 'admin'" class="right">
        {{
          $money(
            order.total +
              order.fee -
              order.freight_total -
              order.cog_total -
              order.cog_total
          )
        }}
        <span v-if="getRebateableProfit() > 0"
          >({{ $money(getRebateableProfit()) }})</span
        >
      </td>
    </tr>
  </table>
  <br />
  <br />
  <hr />
  <br />
  <br />

  <!--- pdfs --->
  <div v-if="pdfs">
    <b>PDFs</b>
    <ul style="list-style-type: none">
      <li v-for="pdf in pdfs" :key="pdf">
        <a href="{{ pdf }}" target="_blank">{{ pdf }}</a>
      </li>
    </ul>
    <hr />
    <br />
    <br />
  </div>

  <!--- policies --->
  <div v-if="clientName === 'atlas'">
    <PolicyAtlas></PolicyAtlas>
  </div>
  <div v-if="clientName === 'midcity'">
    <PolicyMidcity></PolicyMidcity>
  </div>
  <div v-if="clientName === 'mychefstore'">
    <PolicyMychefstore></PolicyMychefstore>
  </div>
</template>

<style scoped>
.quote-label {
  color: #cf3700;
}
</style>
