<script>
import axios from 'axios';
import router from '@/router/index.js';
import { useAuthStore } from '@/stores/authStore';

export default {
  data() {
    const authStore = useAuthStore();
    return {
      showAddProfileCompanyForm: false,
      newProfileCompanyId: 0,
      authStore,
      companies: [],
      companyProfileList: [],
      profile: {
        profileid: 0,
        email: '',
        firstname: '',
        lastname: '',
        title: '',
        phone: '',
        facts_number: '',
        email_opt_out: 0,
        inactive: 0,
        password: ''
      }
    };
  },
  mounted() {
    // API get profile
    if (this.$route.params.profileid > 0) {
      axios
        .get(this.apiUrl + 'admin/profile/' + this.$route.params.profileid)
        .then((response) => {
          this.profile = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });

      axios
        .get(
          this.apiUrl +
            'admin/profile/companies-in-profile/' +
            this.$route.params.profileid
        )
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
    axios
      .get(this.apiUrl + 'admin/profile/dropdown')
      .then((response) => {
        this.companyProfileList = response.data;
        // new profile
        if (this.$route.params.profileid == 0) {
          this.profile.profile_company_id = this.companyProfileList[0].value;
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    cancelProfile() {
      this.$router.push({ path: '/admin/profile' });
    },
    saveProfile() {
      axios
        .post(this.apiUrl + 'admin/profile/save', this.profile)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/profile' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    addProfileCompany() {
      let data = {
        profile_company_id: this.newProfileCompanyId,
        profile_id: this.profile.profileid
      };
      // API save email
      axios
        .post(this.apiUrl + 'profile/add-company-to-profile', data)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          // reload page
          window.location.reload();

          // clear email in form
          this.newProfileCompanyId = 0;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    deleteProfileCompany(profile_company_id) {
      // API save email
      console.log(profile_company_id + this.profile.profileid);
      axios
        .delete(
          this.apiUrl +
            'profile/delete-company-from-profile/' +
            profile_company_id +
            '/' +
            this.profile.profileid
        )
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          // reload page
          window.location.reload();

          // clear email in form
          this.newProfileCompanyId = 0;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    viewProfileCompany(profile_company_id) {
      router.push({ path: '/admin/profile/company/' + profile_company_id });
    },
    // auto login as profile - on ProfileList.vue and ProfileAddMod.vue
    async loginProfile(profileid) {
      const data = { profileid };
      await axios
        .post(this.apiUrl + 'admin/profile/login-as', data)
        .then((response) => {
          this.authStore.cartItems = [];
          // select 1st company by default
          this.authStore.profile = response.data[0];

          // save list off
          this.authStore.profile_companies = response.data;

          // convert to array of ids
          this.authStore.profile.order_quide_item_ids =
            this.authStore.profile.order_quide_item_ids.split(',');

          this.authStore.profile.profile_assigned_to_item_ids =
            this.authStore.profile.profile_assigned_to_item_ids.split(',');

          if (this.authStore.hasOrderGuide) {
            router.push({ path: '/profile/order-guide' });
          } else {
            router.push({ path: '/' });
          }
        })
        .catch((error) => {
          this.authStore.profile = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Buyer Profile / Add Mod</h2>
    <div class="ms-auto">
      <span v-if="profile.profileid > 0">
        <button
          class="btn btn-light btn-sm mx-3"
          @click="loginProfile(profile.profileid)"
        >
          Login As
          <font-awesome-icon icon="fa-sign-in" />
        </button>
      </span>
      <button class="btn btn-light btn-sm mx-3" @click="cancelProfile">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveProfile">Save</button>
    </div>
  </div>

  <table class="">
    <tr>
      <td class="form-label">Inactive</td>
      <td>
        <input
          v-model="profile.inactive"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">FACTS</td>
      <td>
        <input v-model="profile.facts_number" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">title</td>
      <td><input v-model="profile.title" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">firstname</td>
      <td><input v-model="profile.firstname" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">lastname</td>
      <td><input v-model="profile.lastname" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">email</td>
      <td>
        <input v-model="profile.email" class="form-control" type="email" required />
      </td>
    </tr>
    <tr>
      <td class="form-label">phone</td>
      <td><input v-model="profile.phone" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">password</td>
      <td><input v-model="profile.password" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">default company</td>
      <td>
        <select v-model="profile.profile_company_id" class="form-select form-select-sm">
          <option
            v-for="company in companyProfileList"
            :key="company.value"
            :value="company.value"
          >
            {{ company.label }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">email opt out</td>
      <td>
        <input
          v-model="profile.email_opt_out"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
  </table>

  <div v-if="$route.params.profileid > 0">
    <div class="d-flex stickyXXX align-items-center">
      <h3 class="mt-5">Companies</h3>
      <div class="ms-auto">
        <button
          class="btn btn-primary btn-sm"
          @click="showAddProfileCompanyForm = !showAddProfileCompanyForm"
        >
          Add
        </button>
      </div>
    </div>
    <div v-if="showAddProfileCompanyForm" class="mb-3">
      <select v-model="newProfileCompanyId" class="form-select form-select-sm">
        <option
          v-for="company in companyProfileList"
          :key="company.value"
          :value="company.value"
        >
          {{ company.label }}
        </option>
      </select>
      <button class="btn btn-success btn-sm" @click="addProfileCompany()">Save</button>
    </div>

    <table class="table table-hover table-sm">
      <thead class="table-light">
        <tr>
          <th>Company</th>
          <td class="text-center">Modify</td>
          <td class="text-center">Delete</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="company in companies" :key="company">
          <td>{{ company.label }}</td>

          <td class="text-center">
            <span v-if="profile.profileid > 0">
              <a class="hand" @click="viewProfileCompany(profile.profile_company_id)">
                <font-awesome-icon icon="fa-pen" /> </a
            ></span>
          </td>
          <td class="text-center">
            <span
              v-if="profile.profileid > 0 && profile.profile_company_id != company.value"
            >
              <a class="hand" @click="deleteProfileCompany(profile.profile_company_id)">
                <font-awesome-icon icon="fa-trash-alt" /> </a
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
