<script>
import { useAuthStore } from '@/stores/authStore';

export default {
  components: {},
  data() {
    const authStore = useAuthStore();
    return {
      authStore
    };
  }
};
</script>

<template>
  <nav class="navbar navbar-expand-lg nav-bg mb-2">
    <div class="container-fluid">
      <div v-if="clientName === 'midcity'">
        <a class="navbar-brand" href="/"
          ><img src="https://ext-chef.s3.us-west-2.amazonaws.com/MidCityLogo.png"
        /></a>
      </div>
      <div v-if="clientName === 'mychefstore'">
        <a class="navbar-brand" href="/"
          ><img src="https://ext-chef.s3.us-west-2.amazonaws.com/mychefstore-logo.gif"
        /></a>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!--- Orders --->
          <li class="nav-item dropdown">
            <a href="/admin/orders" class="nav-link"> Orders </a>
          </li>

          <li class="nav-item dropdown">
            <a href="/admin/profile" class="nav-link"> Profiles </a>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Accounting
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="/admin/accounting/ap-orders">AP Orders</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/accounting/rebates">Rebates</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/accounting/revenue">Revenue</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Catalog
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="/admin/item">Items</a></li>
              <li>
                <a class="dropdown-item" href="/admin/items_misc_info">Items Misc Info</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/manufacturer">Manufacturers</a>
              </li>

              <li>
                <a class="dropdown-item" href="/admin/specials">Specials</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/stock_items">Stock Items</a>
              </li>

              <li>
                <hr />
              </li>
              <li><a class="dropdown-item" href="/admin/option">Options</a></li>
              <li>
                <a class="dropdown-item" href="/admin/section">Sections</a>
              </li>
              <!--- 
              <li>
                <hr />
              </li>
              <li>
                <a class="dropdown-item" href="/admin/aq-upload">AQ Upload</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/facts">FACTS Stock</a>
              </li>

              <li>
                <a class="dropdown-item" href="/admin/shipping">Shipping</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/shipping-programs"
                  >Shipping Programs</a
                >
              </li>-->
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Pricing
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="/admin/override_cog">Override Cog</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/override_map">Override MAP</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/override_markup"
                  >Override Markups</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="/admin/override_cog/zero_cogs">Zero Cog</a>
              </li>
              <li v-if="clientName == 'mychefstore'">
                <a class="dropdown-item" href="/admin/run-markups">Run Markups</a>
              </li>
              <li>
                <a class="dropdown-item" href="/admin/tax-rate">Tax Rates</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              System
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="/admin/cobrand">Settings</a></li>
              <li><a class="dropdown-item" href="/admin/email-list">Email List</a></li>
              <li><a class="dropdown-item" href="/admin/employee">Employees</a></li>
              <li><a class="dropdown-item" href="/admin/memo">Memo List</a></li>
            </ul>
          </li>
        </ul>
        <div v-if="authStore.isLoggedIn" class="gray">
          {{ authStore.employee.firstname }}
          <a class="hand px-3" title="logout" @click="authStore.logout">
            <font-awesome-icon class="text-whiteXXX" icon="fa-sign-out " />
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.dropdown {
  padding-right: 20px;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}
.nav-bg {
  --bs-bg-opacity: 1;
  background-color: #efefef !important;
  color: #000 !important;
  border-bottom: 1px solid #ccc;
}
</style>
