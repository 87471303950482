<script>
import axios from 'axios';

export default {
  data() {
    return {
      manufacturer_code: '1016',
      manufacturers: [],
      items: []
    };
  },
  watch: {
    manufacturer_code: function (newValue, oldValue) {
      // Fetch items based on the selected manufacturer
      this.fetchItems(newValue);
    }
  },
  mounted() {
    this.fetchItems(this.manufacturer_code);

    // API get manufacturers
    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    fetchItems(manufacturerCode) {
      axios
        .get(this.apiUrl + 'admin/override/markup/' + manufacturerCode)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    save() {
      axios
        .post(this.apiUrl + 'admin/override/markup', this.items)
        .then((response) => {
          this.showToast('Data saved', response.data.message, 'success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Override Markups</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="save">Save</button>
    </div>
  </div>

  <div class="mb-3">
    Manufacturer
    <select v-model="manufacturer_code" class="form-select no-width">
      <option
        v-for="manufacturer in manufacturers"
        :key="manufacturer.manufacturer_code"
        :value="manufacturer.manufacturer_code"
      >
        {{ manufacturer.manufacturer }}
      </option>
    </select>
  </div>

  <table class="table table-hover table-sm no-width">
    <thead class="table-light">
      <tr>
        <td>Model</td>
        <td>OrigMarkup</td>
        <td>OverrideMarkup</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.model }}</td>
        <td>{{ $money(item.cog) }}</td>
        <td>
          <input
            v-model="item.flat_markup"
            class="form-control no-width"
            type="text"
            min="0"
            step=".01"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
