<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      optionTypes: ['gas', 'electrical', 'color', 'misc'],
      itemOptionList: []
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/item/item_options')
      .then((response) => {
        this.itemOptionList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addOption(type) {
      this.itemOptionList[type].push({
        id: 0,
        name: this.itemOptionList[type].name,
        type: type
      });
    },
    deleteOption(type, index) {
      this.itemOptionList[type].splice(index, 1);
    },
    saveItem() {
      axios
        .post(this.apiUrl + 'admin/item/item_options/save', this.itemOptionList)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Options</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="saveItem">Save</button>
    </div>
  </div>
  <div class="container text-center">
    <div class="row">
      <!--- optionTypes --->
      <div v-for="optionType in optionTypes" :key="optionType" class="col align-top">
        <table class="no-width">
          <thead class="table-light">
            <tr>
              <td>
                {{ optionType }}
                <font-awesome-icon
                  class="hand mx-1"
                  icon="fa-plus"
                  @click="addOption(optionType)"
                />
              </td>
              <td class="text-center">Delete</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(option, index) in itemOptionList[optionType]" :key="option.id">
              <td>
                <input v-model="option.name" class="form-control no-width" type="text" />
              </td>
              <td class="text-center">
                <a
                  class="hand"
                  :href="javascript"
                  @click="deleteOption(optionType, index)"
                >
                  <font-awesome-icon icon="fa-trash-alt" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
