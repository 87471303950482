<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      specials_list: []
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/specials')
      .then((response) => {
        this.specials_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addSpecial() {
      router.push({ path: '/admin/specials/0' });
    },
    modifySpecial(specials_id) {
      router.push({ path: '/admin/specials/' + specials_id });
    },
    deleteSpecial(specials_id, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/specials/' + specials_id)
          .then((resp) => {
            this.specials_list.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Specials</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="addSpecial">Add</button>
    </div>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Image</td>
        <td>URL</td>

        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(specials, index) in specials_list" :key="specials.id">
        <td><img :src="specials.image_url" class="img-thumbnail" /></td>
        <td>{{ specials.special_url }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifySpecial(specials.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
        <td class="text-center">
          <a class="hand" :href="javascript" @click="deleteSpecial(specials.id, index)">
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
.img-thumbnail {
  width: 100px;
  height: 100px;
}
</style>
