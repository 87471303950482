<script>
import { useAuthStore } from '@/stores/authStore';
import { ref } from 'vue';
import axios from 'axios';
import router from '@/router/index.js';

export default {
  data() {
    const authStore = useAuthStore();
    let email = ref('');
    let password = ref('');

    return {
      showPassword: false,
      email,
      password,
      authStore
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? 'Hide' : 'Show';
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async login() {
      let data = { email: this.email, password: this.password };
      await axios
        .post(this.apiUrl + 'admin/login', data)
        .then((response) => {
          this.authStore.employee = response.data;
          router.push({ path: '/admin/orders' });
        })
        .catch((error) => {
          this.authStore.employee = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="card login_form">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email address</label>
      <input v-model="email" type="email" class="form-control" placeholder="email" />
    </div>

    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Password</label>

      <div class="input-group mb-3">
        <input v-if="showPassword" v-model="password" type="text" class="form-control" />
        <input v-else v-model="password" type="password" class="form-control" />

        <div class="input-group-text">
          <span @click="toggleShow">
            <font-awesome-icon v-if="showPassword" icon="fa-eye-slash" />
            <font-awesome-icon v-else icon="fa-eye" />
          </span>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" @click="login">Login</button>
  </div>
</template>

<style scoped>
.login_form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
</style>
