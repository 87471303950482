<script>
import ModalPopupTerms from '@/components/ModalPopupTerms.vue';
import ModalPopupPolicy from '@/components/ModalPopupPolicy.vue';

export default {
  components: {
    ModalPopupTerms,
    ModalPopupPolicy
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    showModalTerms() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-terms'));
      myModal.show();
    },
    showPolicyTerms() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-policy'));
      myModal.show();
    }
  }
};
</script>
<template>
  <ModalPopupTerms></ModalPopupTerms>
  <ModalPopupPolicy></ModalPopupPolicy>

  <div class="text-center mt-4 footer">
    <div class="fs-6">415-877-4803 or email sales@mychefstore.com</div>

    Copyright © {{ currentYear }} • Mychefstore<br />
    <span class="hand" @click="showModalTerms">Policies</span> •
    <span class="hand" @click="showPolicyTerms">Privacy Policy</span><br />
  </div>
</template>

<style scoped>
.footer {
  background-color: #003767;
  color: #fff !important;
  padding: 20px;
}
.footer a {
  color: #fff !important;
}
</style>
