<script>
import axios from 'axios';

export default {
  data() {
    return {
      stock_item_list: [],
      filters: {
        manufacturer_code: '1021'
      },
      manufacturers: [],
      warehouseList: []
    };
  },
  watch: {
    'filters.manufacturer_code': function (newValue, oldValue) {
      this.fetchItems(newValue);
      this.fetchWarehouse(newValue);
    }
  },
  mounted() {
    this.fetchItems(this.filters.manufacturer_code);
    this.fetchWarehouse(this.filters.manufacturer_code);

    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    fetchItems(manufacturer_code) {
      axios
        .get(this.apiUrl + 'admin/stock_items/' + manufacturer_code)
        .then((response) => {
          this.stock_item_list = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    fetchWarehouse(manufacturer_code) {
      axios
        .get(this.apiUrl + 'admin/manufacturer/dropdown-warehouse/' + manufacturer_code)
        .then((response) => {
          this.warehouseList = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    saveChanges() {
      // Create a new list to include warehouse names
      const updatedStockItemList = this.stock_item_list.map((item) => {
        // Find the warehouse name corresponding to the warehouse ID in each item
        const warehouse = this.warehouseList.find((wh) => wh.id === item.warehouse_id);
        // Create a new object with the warehouse name included
        return {
          ...item,
          warehouse_name: warehouse ? warehouse.name : null
        };
      });

      axios
        .put(this.apiUrl + 'admin/stock_items/save', updatedStockItemList)
        .then((response) => {
          this.showToast('Data saved', response.data.message, 'success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>
<template>
  <div>
    <div class="d-flex stickyXXX align-items-center">
      <h2>Shipping</h2>
      <button class="btn btn-primary ms-auto" @click="saveChanges">Save Changes</button>
    </div>

    <!-- Filters -->
    <div class="filter">
      <font-awesome-icon icon="fa-filter" class="mr-10" />Filters<br />
      <select v-model="filters.manufacturer_code" class="form-select form-select-sm">
        <option value="">all manufacturers</option>
        <option
          v-for="manufacturer in manufacturers"
          :key="manufacturer.manufacturer_code"
          :value="manufacturer.manufacturer_code"
        >
          {{ manufacturer.manufacturer }}
        </option>
      </select>
    </div>

    <table class="table table-hover table-sm">
      <thead class="table-light">
        <tr>
          <td>Manufacturer</td>
          <td>Title</td>
          <td>Facts</td>
          <td>Model</td>
          <td>ShipsFrom</td>
          <td>StockQty1</td>
          <td>StockQty2</td>
          <td>StockQty3</td>
          <td>StockQty4</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stock_item, index) in stock_item_list" :key="index">
          <td>{{ stock_item.manufacturer }}</td>
          <td>{{ stock_item.title }}</td>
          <td>{{ stock_item.facts_number }}</td>
          <td>{{ stock_item.model }}</td>
          <td>
            <select v-model="stock_item.warehouse_id" class="form-select">
              <option
                v-for="warehouse in warehouseList"
                :key="warehouse.id"
                :value="warehouse.id"
              >
                {{ warehouse.name }}
              </option>
            </select>
          </td>
          <td>
            <input
              v-model.number="stock_item.stock_qty_warehouse_1"
              type="number"
              class="form-control"
            />
          </td>
          <td>
            <input
              v-model.number="stock_item.stock_qty_warehouse_2"
              type="number"
              class="form-control"
            />
          </td>
          <td>
            <input
              v-model.number="stock_item.stock_qty_warehouse_3"
              type="number"
              class="form-control"
            />
          </td>
          <td>
            <input
              v-model.number="stock_item.stock_qty_warehouse_4"
              type="number"
              class="form-control"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
