<script>
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Multiselect from '@vueform/multiselect';

import filters_default from './filters_default';
import filters_midcity from './filters_midcity';

export default {
  components: { flatPickr, Multiselect },
  data() {
    let filters = filters_default;
    if (this.clientName == 'midcity') {
      filters = filters_midcity;
    }

    //let begin_date = new Date();
    //let end_date = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
    // dayjs(begin_date).format('YYYY-MM-DD')

    return {
      isLoading: false,
      files: [],
      fileImage1: null,
      fileImage2: null,
      fileImage3: null,
      fileImage4: null,
      fileImage5: null,
      filters: filters,
      add_on_options: [],

      item: {
        id: 0,
        item_secs: [],
        item_accessories: [],
        item_addons: [],
        item_profiles: [],
        packed_qty: 1,
        manufacturer_code: '',
        model: '',
        misc_info_begin_date: null,
        misc_info_end_date: null,
        misc_info: '',
        options: {
          gas: [],
          color: [],
          electrical: [],
          misc: []
        }
      },
      fright_classes: [
        '0',
        '55',
        '70',
        '77.5',
        '85',
        '92.5',
        '100',
        '110',
        '125',
        '150',
        '250'
      ],
      uoms: [
        'BD',
        'BG',
        'BT',
        'BX',
        'CN',
        'CS',
        'DZ',
        'EA',
        'FT',
        'GL',
        'PK',
        'PR',
        'PL',
        'QT',
        'RL',
        'SL',
        'ST'
      ],
      availabilities: [
        '',
        'next day',
        '3-5 days',
        '7-10 days',
        '14 days',
        '2-3 weeks',
        '3-4 weeks',
        '6-8 weeks'
      ],
      qtys: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 18, 24, 36, 48, 50, 60, 72, 84, 96,
        108, 120, 132, 144, 168, 180
      ],

      section_list: [],
      manufacturers: [],
      itemOptionList: [],
      warehouseList: [],
      companyProfileList: [],
      optionTypes: ['gas', 'electrical', 'color', 'misc']
    };
  },

  mounted() {
    // API get Data
    if (this.$route.params.id > 0) {
      this.getItem();
    }

    // API get manufacturers
    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    axios
      .get(this.apiUrl + 'admin/item/item_options')
      .then((response) => {
        this.itemOptionList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    axios
      .get(this.apiUrl + 'admin/profile/dropdown')
      .then((response) => {
        this.companyProfileList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    axios
      .get(this.apiUrl + 'admin/item/add_ons')
      .then((response) => {
        this.add_on_options = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    axios
      .get(this.apiUrl + 'admin/section/dropdown')
      .then((response) => {
        this.section_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    getItem() {
      axios
        .get(this.apiUrl + 'admin/item/' + this.$route.params.id)
        .then((response) => {
          this.item = response.data;
          // needs to be array for checkboxes to work - convert to string on save
          this.item.options = {
            gas: this.item.gas_options.split(','),
            color: this.item.color_options.split(','),
            electrical: this.item.electrical_options.split(','),
            misc: this.item.misc_options.split(',')
          };

          this.getWarehouses();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    getWarehouses() {
      axios
        .get(
          this.apiUrl +
            'admin/manufacturer/dropdown-warehouse/' +
            this.item.manufacturer_code
        )
        .then((response) => {
          this.warehouseList = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    addImage1(e) {
      this.fileImage1 = e.target.files[0];
    },
    addImage2(e) {
      this.fileImage2 = e.target.files[0];
    },
    addImage3(e) {
      this.fileImage3 = e.target.files[0];
    },
    addImage4(e) {
      this.fileImage4 = e.target.files[0];
    },
    addImage5(e) {
      this.fileImage5 = e.target.files[0];
    },
    addFilePdfAdditionalInfo1(e) {
      this.files.push({ file_name: 'pdf_additional_info1_url', file: e.target.files[0] });
    },
    addFilePdfAdditionalInfo2(e) {
      this.files.push({ file_name: 'pdf_additional_info2_url', file: e.target.files[0] });
    },
    addFilePdf(e) {
      this.files.push({ file_name: 'pdf_url', file: e.target.files[0] });
    },
    addFilePdfWarranty(e) {
      this.files.push({ file_name: 'warranty_pdf_url', file: e.target.files[0] });
    },
    addFilePdfBrochure(e) {
      this.files.push({ file_name: 'brochure_pdf_url', file: e.target.files[0] });
    },

    addItemAccessory() {
      this.item.item_accessories.push({ id: 0, item_id: this.item.id });
    },
    deleteItemAccessory(acc_index) {
      this.item.item_accessories.splice(acc_index, 1);
    },
    addItemAddon() {
      this.item.item_addons.push({
        id: 0,
        master_item_id: this.item.id,
        addon_qty: 1,
        orderby: 0,
        item_id: this.add_on_options[0].value
      });
    },
    deleteItemAddon(deleteAddOn) {
      this.item.item_addons.splice(deleteAddOn, 1);
    },
    addItemProfile() {
      this.item.item_profiles.push({
        id: 0,
        item_id: this.item.id,
        profile_company_id: 0
      });
    },
    deleteItemProfile(deleteProfileIndex) {
      this.item.item_profiles.splice(deleteProfileIndex, 1);
    },
    addItemSec() {
      this.item.item_secs.push({ sec_id: 0 });
    },
    deleteItemSec(sec_index) {
      this.item.item_secs.splice(sec_index, 1);
    },
    cancelItem() {
      this.$router.push({ path: '/admin/item' });
    },
    async saveItem() {
      this.isLoading = true;

      // convert string to array this.item.options['gas']
      this.item.gas_options = this.item.options['gas'].toString();
      this.item.color_options = this.item.options['color'].toString();
      this.item.electrical_options = this.item.options['electrical'].toString();
      this.item.misc_options = this.item.options['misc'].toString();
      // save each pdf and wait for URL in response
      for (let i = 0; i < this.files.length; i++) {
        const data = new FormData();
        data.append('file', this.files[i].file);
        data.append('manufacturer_code', this.item.manufacturer_code);

        const response = await axios.post(this.apiUrl + 'admin/upload-pdf', data);

        // set pdf_url from response in item
        if (response.data) {
          this.item[this.files[i].file_name] = response.data.pdf_url;
        } else {
          break; // Stop looping if no data found
        }
      }

      // images
      if (this.fileImage1) {
        let data = new FormData();
        data.append('file', this.fileImage1);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'full');
        let response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.image_url = response.data.image_url;

        // med
        data = new FormData();
        data.append('file', this.fileImage1);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'med');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.med_image_url = response.data.image_url;

        // thumb
        data = new FormData();
        data.append('file', this.fileImage1);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'thumb');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.thumb_image_url = response.data.image_url;
      }
      if (this.fileImage2) {
        let data = new FormData();
        data.append('file', this.fileImage2);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'full');
        let response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.image2_url = response.data.image_url;

        // med
        data = new FormData();
        data.append('file', this.fileImage2);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'med');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.med_image2_url = response.data.image_url;

        // thumb
        data = new FormData();
        data.append('file', this.fileImage2);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'thumb');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.thumb_image2_url = response.data.image_url;
      }
      if (this.fileImage3) {
        let data = new FormData();
        data.append('file', this.fileImage3);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'full');
        let response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.image3_url = response.data.image_url;

        // med
        data = new FormData();
        data.append('file', this.fileImage3);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'med');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.med_image3_url = response.data.image_url;

        // thumb
        data = new FormData();
        data.append('file', this.fileImage3);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'thumb');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.thumb_image3_url = response.data.image_url;
      }
      if (this.fileImage4) {
        let data = new FormData();
        data.append('file', this.fileImage4);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'full');
        let response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.image4_url = response.data.image_url;

        // med
        data = new FormData();
        data.append('file', this.fileImage4);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'med');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.med_image4_url = response.data.image_url;

        // thumb
        data = new FormData();
        data.append('file', this.fileImage4);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'thumb');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.thumb_image4_url = response.data.image_url;
      }
      if (this.fileImage5) {
        let data = new FormData();
        data.append('file', this.fileImage5);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'full');
        let response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.image5_url = response.data.image_url;

        // med
        data = new FormData();
        data.append('file', this.fileImage5);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'med');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.med_image5_url = response.data.image_url;

        // thumb
        data = new FormData();
        data.append('file', this.fileImage5);
        data.append('manufacturer_code', this.item.manufacturer_code);
        data.append('size', 'thumb');
        response = await axios.post(this.apiUrl + 'admin/upload-image', data);
        this.item.thumb_image5_url = response.data.image_url;
      }

      // make last API call with all data
      await axios
        .post(this.apiUrl + 'admin/item/save', this.item)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          // this.$router.push({ path: '/admin/item' });
          window.location.reload();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // same funtion on OverrideMapList.vue
    updateDiscountPercent() {
      this.item.map_discount_percent = 0;
      if (this.item.map_discount_display > '') {
        try {
          let map_discount_percent = 100;
          let parts = this.item.map_discount_display.split('/');
          for (let j = 0; j < parts.length; j++) {
            map_discount_percent =
              map_discount_percent - map_discount_percent * parts[j] * 0.01;
          }
          this.item.map_discount_percent = map_discount_percent;
        } catch (e) {
          console.log(e);
          this.item.map_discount_percent = 0;
        }
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Item / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelItem">Cancel</button>
      <button class="btn btn-primary btn-sm" :disabled="isLoading" @click="saveItem">
        Save
      </button>
    </div>
  </div>

  <!--- tabs -->
  <ul id="myTab" class="nav nav-tabs mb-3" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        id="info-tab"
        class="nav-link active"
        data-bs-toggle="tab"
        data-bs-target="#info-tab-pane"
        type="button"
        role="tab"
        aria-controls="info-tab-pane"
        aria-selected="true"
      >
        info
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="pricing-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#pricing-tab-pane"
        type="button"
        role="tab"
        aria-controls="pricing-tab-pane"
        aria-selected="false"
      >
        pricing
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="accessories-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#accessories-tab-pane"
        type="button"
        role="tab"
        aria-controls="accessories-tab-pane"
        aria-selected="false"
      >
        accessories
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="addons-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#addons-tab-pane"
        type="button"
        role="tab"
        aria-controls="addons-tab-pane"
        aria-selected="false"
      >
        addons
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="filters-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#filters-tab-pane"
        type="button"
        role="tab"
        aria-controls="filters-tab-pane"
        aria-selected="false"
      >
        filters
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="options-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#options-tab-pane"
        type="button"
        role="tab"
        aria-controls="options-tab-pane"
        aria-selected="false"
      >
        options
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="media-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#media-tab-pane"
        type="button"
        role="tab"
        aria-controls="media-tab-pane"
        aria-selected="false"
      >
        media
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="shipping-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#shipping-tab-pane"
        type="button"
        role="tab"
        aria-controls="shipping-tab-pane"
        aria-selected="false"
      >
        shipping
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        id="assigned-tab"
        class="nav-link"
        data-bs-toggle="tab"
        data-bs-target="#assigned-tab-pane"
        type="button"
        role="tab"
        aria-controls="assigned-tab-pane"
        aria-selected="false"
      >
        assigned
      </button>
    </li>
  </ul>

  <div id="myTabContent" class="tab-content">
    <div
      id="info-tab-pane"
      class="tab-pane fade show active"
      role="tabpanel"
      aria-labelledby="info-tab"
      tabindex="0"
    >
      <table class="table table-hover">
        <tr>
          <td class="form-label">Manufacturer</td>
          <td>
            <select v-model="item.manufacturer_code" class="form-select">
              <option
                v-for="manufacturer in manufacturers"
                :key="manufacturer.manufacturer_code"
                :value="manufacturer.manufacturer_code"
              >
                {{ manufacturer.manufacturer }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="form-label">Model</td>
          <td>
            <input v-model="item.model" class="form-control no-width" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Facts Number</td>
          <td><input v-model="item.facts_number" class="form-control no-width" /></td>
        </tr>

        <tr>
          <td class="form-label">UOM</td>
          <td>
            <select v-model="item.uom" class="form-select">
              <option v-for="uom in uoms" :key="uom" :value="uom">
                {{ uom }}
              </option>
            </select>
          </td>
        </tr>

        <tr>
          <td class="form-label align-top">
            Section
            <font-awesome-icon class="hand mx-1" icon="fa-plus" @click="addItemSec()" />
          </td>
          <td>
            <div v-for="(sec, sec_index) in item.item_secs" :key="sec.sec_id">
              <select v-model="sec.sec_id" class="form-select">
                <option
                  v-for="section in section_list"
                  :key="section.name"
                  :value="section.id"
                >
                  {{ section.name }}
                </option>
              </select>
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-trash-alt"
                @click="deleteItemSec(sec_index)"
              />
            </div>
          </td>
        </tr>

        <tr>
          <td class="form-label"></td>
          <td>
            <div class="d-flex flex-row mb-3">
              <div class="p-2">
                Show Pricing
                <input
                  v-model="item.show_pricing"
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="p-2">
                Addon Only
                <input
                  v-model="item.sell_as_ad_on_only"
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="p-2">
                Discontinued
                <input
                  v-model="item.discontinued"
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="p-2">
                Do Not Display
                <input
                  v-model="item.do_not_display"
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="p-2">
                Energy Star
                <input
                  v-model="item.energy_star"
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="p-2">
                Dont Use Facts
                <input
                  v-model="item.dont_use_facts_for_availability"
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="form-label">Title</td>
          <td>
            <input v-model="item.title" class="form-control" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Description</td>
          <td width="90%">
            <textarea v-model="item.specs" class="form-control"></textarea>
          </td>
        </tr>
        <tr>
          <td class="form-label">Warranty</td>
          <td width="90%">
            <textarea v-model="item.warranty" class="form-control"></textarea>
          </td>
        </tr>
        <tr>
          <td class="form-label">Internal Memo</td>
          <td>
            <textarea v-model="item.internal_memo" class="form-control"></textarea>
          </td>
        </tr>
        <tr>
          <td class="form-label align-top">MiscInfo</td>
          <td>
            <table class="">
              <tr>
                <td class="align-top">
                  <flatPickr
                    v-model="item.misc_info_begin_date"
                    class="form-control form-control-date flatpickr-input inline"
                    data-bs-focus="false"
                  />
                </td>
                <td class="align-top">
                  <flatPickr
                    v-model="item.misc_info_end_date"
                    class="form-control form-control-date flatpickr-input inline"
                    data-bs-focus="false"
                  />
                </td>
                <td class="w-100" colspan="2">
                  <textarea v-model="item.misc_info" class="form-control"></textarea>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>

    <!--- pricing ------------------------------------------------------------------- -->
    <div
      id="pricing-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="pricing-tab"
      tabindex="0"
    >
      <!--- Atlas Midcity Only -->
      <table
        v-if="clientName === 'atlas' || clientName === 'midcity'"
        class="table table-hover"
      >
        <tr>
          <td class="form-label">Standard</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">Price1</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price1"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">Price2</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price2"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">Price3</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price3"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">Price4</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price4"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">Price5</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price5"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">Price6</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.sell_price6"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">COG</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.cog"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
      </table>

      <!--- MyChefstore Only clientName === 'mychefstore'-->
      <table v-if="clientName === 'mychefstore'" class="table table-hover">
        <tr>
          <td class="form-label">Rebatable</td>
          <td>
            <input
              v-model="item.is_rebateable"
              class="form-check-input"
              type="checkbox"
              true-value="1"
              false-value="0"
            />
          </td>
        </tr>
        <tr>
          <td class="form-label">List Price</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.list_price"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label">COG</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.cog"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td class="form-label align-top">Override COG</td>
          <td>
            <table class="table table-hover table-sm no-width">
              <thead class="table-light">
                <tr>
                  <td>Cog</td>
                  <td>Start Date</td>
                  <td>End Date</td>
                </tr>
              </thead>
              <tr>
                <td>
                  <div class="input-group d-inline-flex w-auto">
                    <span class="input-group-text">$</span>
                    <input
                      v-model="item.override_cog"
                      class="form-control input-sm inline"
                      type="number"
                      step="0.01"
                    />
                  </div>
                </td>
                <td>
                  <flatPickr
                    v-model="item.override_cog_begin_date"
                    class="form-control form-control-date flatpickr-input"
                    data-bs-focus="false"
                  />
                </td>
                <td>
                  <flatPickr
                    v-model="item.override_cog_end_date"
                    class="form-control form-control-date flatpickr-input"
                    data-bs-focus="false"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td class="form-label align-top">Markup</td>
          <td>
            <table class="table table-hover table-sm no-width">
              <thead class="table-light">
                <tr>
                  <td>Map (50/10/5)</td>
                  <td>Map Markup</td>
                </tr>
              </thead>
              <tr>
                <td>
                  <input
                    v-model="item.map_discount_display"
                    class="form-control"
                    type="text"
                    @change="updateDiscountPercent"
                  />
                </td>
                <td class="">
                  <span class="mx-3">{{ item.map_discount_percent }}%</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>

    <!--- accessories ------------------------------------------------------------------- -->
    <div
      id="accessories-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="accessories-tab"
      tabindex="0"
    >
      <table class="table table-hover table-sm no-width">
        <thead class="table-light">
          <tr>
            <td>
              Model
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-plus"
                @click="addItemAccessory()"
              />
            </td>
            <td>Spec</td>
            <td>List Price</td>
            <td>Sell Price</td>
            <td>Cog</td>

            <td>Cube</td>
            <td>Weight</td>
            <td>Freight Class</td>

            <td>Auto Selected</td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(accessory, acc_index) in item.item_accessories" :key="accessory.id">
            <td><input v-model="accessory.model" class="form-control no-width" /></td>
            <td><input v-model="accessory.spec" class="form-control no-width" /></td>
            <td>
              <div class="input-group d-inline-flex w-auto">
                <span class="input-group-text">$</span>
                <input
                  v-model="accessory.list_price"
                  class="form-control input-sm inline"
                  type="number"
                  step="0.01"
                />
              </div>
            </td>
            <td>
              <div class="input-group d-inline-flex w-auto">
                <span class="input-group-text">$</span>
                <input
                  v-model="accessory.sell_price"
                  class="form-control input-sm inline"
                  type="number"
                  step="0.01"
                />
              </div>
            </td>
            <td>
              <div class="input-group d-inline-flex w-auto">
                <span class="input-group-text">$</span>
                <input
                  v-model="accessory.cog"
                  class="form-control input-sm inline"
                  type="number"
                  step="0.01"
                />
              </div>
            </td>

            <td><input v-model="accessory.aq_cube" class="form-control no-width" /></td>
            <td><input v-model="accessory.aq_weight" class="form-control no-width" /></td>
            <td>
              <select v-model="accessory.aq_freight_class" class="form-select">
                <option
                  v-for="fright_class in fright_classes"
                  :key="fright_class"
                  :value="fright_class"
                >
                  {{ fright_class }}
                </option>
              </select>
            </td>

            <td class="text-center">
              <input
                v-model="accessory.auto_select"
                class="form-check-input"
                type="checkbox"
                true-value="1"
                false-value="0"
              />
            </td>
            <td class="text-center">
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-trash-alt"
                @click="deleteItemAccessory(acc_index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--- addons ------------------------------------------------------------------- -->
    <div
      id="addons-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="addons-tab"
      tabindex="0"
    >
      <table class="table table-hover table-sm">
        <thead class="table-light">
          <tr>
            <td>
              Model
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-plus"
                @click="addItemAddon()"
              />
            </td>
            <td>Qty</td>
            <td>OrderBy</td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(addon, addonIndex) in item.item_addons" :key="addon.id">
            <td width="100%">
              <Multiselect
                v-model="addon.item_id"
                :options="add_on_options"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                mode="single"
              ></Multiselect>
            </td>
            <td>
              <input
                v-model="addon.addon_qty"
                class="form-control no-width"
                type="number"
                min="0"
              />
            </td>
            <td>
              <input
                v-model="addon.orderby"
                class="form-control no-width"
                type="number"
                min="0"
                max="100"
              />
            </td>
            <td class="text-center">
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-trash-alt"
                @click="deleteItemAddon(addonIndex)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--- filters ------------------------------------------------------------------- -->
    <div
      id="filters-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="filters-tab"
      tabindex="0"
    >
      <table class="table table-sm no-width">
        <thead class="table-light">
          <tr>
            <td v-for="filter_name in filters.filter_names" :key="filter_name">
              {{ filter_name.replace('filter_', '').replace('_', ' ') }}
            </td>
          </tr>
        </thead>

        <tr>
          <td
            v-for="filter_name in filters.filter_names"
            :key="filter_name"
            class="align-top"
          >
            <div v-for="sub_filter in filters[filter_name]" :key="sub_filter">
              <input
                v-model="item[filter_name]"
                class="form-check-input"
                type="radio"
                :value="sub_filter"
              />
              {{ sub_filter }}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!--- options ------------------------------------------------------------------- -->
    <div
      id="options-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="options-tab"
      tabindex="0"
    >
      <div class="row">
        <!--- optionTypes --->
        <div v-for="optionType in optionTypes" :key="optionType" class="col align-top">
          <table class="no-width">
            <thead class="table-light">
              <tr>
                <td>
                  {{ optionType }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="option in itemOptionList[optionType]" :key="option.name">
                <td>
                  <input
                    v-model="item.options[optionType]"
                    :value="option.name"
                    class="form-check-input"
                    type="checkbox"
                  />
                  {{ option.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--- media ------------------------------------------------------------------- -->
    <div
      id="media-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="media-tab"
      tabindex="0"
    >
      <table class="table table-hover">
        <tr>
          <td class="form-label">Youtube ID</td>
          <td>
            <input v-model="item.you_tube_id" class="form-control no-width" />
            <br />
            Use just the id. e.g) xVxfQvVxPUs
          </td>
          <td data-bs-toggle="tooltip" data-bs-title="Default tooltip"></td>
        </tr>
        <tr>
          <td><br /></td>
        </tr>

        <tr>
          <td class="form-label">Image1</td>
          <td>
            <img :src="item.thumb_image_url" class="img-thumbnail" /><br />
            <input type="file" @change="addImage1" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Image2</td>
          <td>
            <img :src="item.thumb_image2_url" class="img-thumbnail" /><br />
            <input type="file" class="img-thumbnail" @change="addImage2" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Image3</td>
          <td>
            <img :src="item.thumb_image3_url" class="img-thumbnail" /><br />
            <input type="file" class="img-thumbnail" @change="addImage3" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Image4</td>
          <td>
            <img :src="item.thumb_image4_url" class="img-thumbnail" /><br />
            <input type="file" class="img-thumbnail" @change="addImage4" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Image5</td>
          <td>
            <img :src="item.thumb_image5_url" class="img-thumbnail" /><br />
            <input type="file" class="img-thumbnail" @change="addImage5" />
          </td>
        </tr>
        <tr>
          <td><br /></td>
        </tr>

        <tr>
          <td class="form-label">PDF</td>
          <td>
            {{ item.pdf_url }}<br />
            <input type="file" @change="addFilePdf" />
          </td>
        </tr>
        <tr>
          <td class="form-label">PDF AdditionalInfo1</td>
          <td>
            {{ item.pdf_additional_info1_url }}<br />
            <input type="file" @change="addFilePdfAdditionalInfo1" />
          </td>
        </tr>
        <tr>
          <td class="form-label">PDF AdditionalInfo2</td>
          <td>
            {{ item.pdf_additional_info2_url }}<br />
            <input type="file" @change="addFilePdfAdditionalInfo2" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Warranty PDF</td>
          <td>
            {{ item.warranty_pdf_url }}<br />
            <input type="file" @change="addFilePdfWarranty" />
          </td>
        </tr>
        <tr>
          <td class="form-label">Brochure PDF</td>
          <td>
            {{ item.brochure_pdf_url }}<br />
            <input type="file" @change="addFilePdfBrochure" />
          </td>
        </tr>
      </table>
    </div>

    <!--- shipping ------------------------------------------------------------------- -->
    <div
      id="shipping-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="shipping-tab"
      tabindex="0"
    >
      <table class="table table-hover">
        <tr>
          <td class="form-label align-top">Honolulu Stock</td>
          <td>{{ item.stock_qty_warehouse_2 }}</td>
        </tr>
        <tr>
          <td class="form-label">Packed Qty</td>
          <td>
            <select v-model="item.packed_qty" class="form-select">
              <option v-for="qty in qtys" :key="qty" :value="qty">
                {{ qty }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="form-label">Min Order Qty</td>
          <td>
            <select v-model="item.min_order_qty" class="form-select">
              <option v-for="qty in qtys" :key="qty" :value="qty">
                {{ qty }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="form-label">Availability</td>
          <td>
            <select v-model="item.availability" class="form-select">
              <option
                v-for="availability in availabilities"
                :key="availability"
                :value="availability"
              >
                {{ availability }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="form-label">Dimensions</td>
          <td>
            L-<input
              v-model="item.shipping_length"
              class="form-control no-width inline input-sm"
              type="number"
              step="0.01"
            />
            W-<input
              v-model="item.shipping_width"
              class="form-control no-width inline input-sm"
              type="number"
              step="0.01"
            />
            H-<input
              v-model="item.shipping_height"
              class="form-control no-width inline input-sm"
              type="number"
              step="0.01"
            />
            Cube-<input
              v-model="item.shipping_cube"
              class="form-control no-width inline input-sm"
              type="number"
              step="0.01"
            />
            lbs-<input
              v-model="item.shipping_weight"
              class="form-control no-width inline input-sm"
              type="number"
              step="0.01"
            />
          </td>
        </tr>
        <tr>
          <td class="form-label">Est Freight</td>
          <td>
            {{ $money(item.estimated_freight_cog) }}
          </td>
        </tr>
        <tr>
          <td class="form-label">Shipping rate to warehouse</td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.shipping_price_to_warehouse"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
          </td>
        </tr>

        <tr>
          <td class="form-label align-top">Shipping Program</td>
          <td>
            <table class="">
              <tr>
                <td class="align-top">
                  <flatPickr
                    v-model="item.shipping_program_begin_date"
                    class="form-control form-control-date flatpickr-input inline"
                    data-bs-focus="false"
                  />
                </td>
                <td class="align-top">
                  <flatPickr
                    v-model="item.shipping_program_end_date"
                    class="form-control form-control-date flatpickr-input inline"
                    data-bs-focus="false"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td class="form-label align-top"></td>
          <td>
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">$</span>
              <input
                v-model="item.shipping_program_flat_48"
                class="form-control input-sm inline"
                type="number"
                step="0.01"
              />
            </div>
            Flat Rate for 48 (origin to customer)
          </td>
        </tr>

        <tr>
          <td class="form-label align-top"></td>
          <td>
            <input
              v-model="item.free_freight"
              class="form-check-input"
              type="checkbox"
              true-value="1"
              false-value="0"
            />
            Free Freight 48
          </td>
        </tr>

        <tr>
          <td class="form-label align-top"></td>
          <td>
            <input
              v-model="item.comp_freight"
              class="form-check-input"
              type="checkbox"
              true-value="1"
              false-value="0"
            />
            Comp Freight (free freight to customer but we pay it to carrier in lower 48)
          </td>
        </tr>
        <tr>
          <td class="form-label align-top">Ships From</td>
          <td>
            <select v-model="item.warehouse_id" class="form-select">
              <option
                v-for="warehouse in warehouseList"
                :key="warehouse.id"
                :value="warehouse.id"
              >
                {{ warehouse.name }}
              </option>
            </select>
          </td>
        </tr>
      </table>
    </div>

    <!--- assigned ------------------------------------------------------------------- -->
    <div
      id="assigned-tab-pane"
      class="tab-pane fade"
      role="tabpanel"
      aria-labelledby="assigned-tab"
      tabindex="0"
    >
      <table class="table table-hover table-sm">
        <thead class="table-light">
          <tr>
            <td>
              Profile
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-plus"
                @click="addItemProfile()"
              />
            </td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item_profile, profileIndex) in item.item_profiles"
            :key="item_profile.profile_company_id"
          >
            <td width="100%">
              <Multiselect
                v-model="item_profile.profile_company_id"
                :options="companyProfileList"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                mode="single"
              ></Multiselect>
            </td>
            <td class="text-center">
              <font-awesome-icon
                class="hand mx-1"
                icon="fa-trash-alt"
                @click="deleteItemProfile(profileIndex)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
