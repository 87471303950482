<script>
import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';
import router from '@/router/index.js';

export default {
  data() {
    const authStore = useAuthStore();

    return {
      authStore,
      profile: null,
      resultList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,

      filters: {
        profile_company_id: authStore.profile.profile_company_id,
        status: ''
      }
    };
  },
  computed: {
    total() {
      return (
        Math.round(this.resultList.reduce((total, item) => total + item.total, 0) * 100) /
        100
      );
    }
  },
  mounted() {
    this.getAllResultList(1);
  },

  methods: {
    viewOrder(orderid, email) {
      router.push({ path: '/profile/orders/' + orderid + '/' + email });
    },
    processPagination(page) {
      this.getAllResultList(page);
    },
    getAllResultList(page) {
      axios
        .post(this.apiUrl + 'admin/orders?page_number=' + page, this.filters)
        .then((response) => {
          this.resultList = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
        });
    },
    async save() {
      let data = { profile: this.profile };
      await axios
        .post(this.apiUrl + 'profile/save', data)
        .then((response) => {
          this.authStore.profile = response.data;
          router.push({ path: '/profile/orders' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <h3>Orders</h3>

  <!--- this code is duped on 3 pages -->
  <div class="pagination-container">
    <vue-awesome-paginate
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
      :on-click="processPagination"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <th>Order ID</th>
        <th>Cobrand</th>
        <th>Date Quoted</th>
        <th>Name</th>
        <th>Status</th>
        <th>Payment Type</th>
        <th>Internal Memo</th>
        <th>Customer Memo</th>
        <th>Total</th>
        <th>Due</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in resultList" :key="order.orderid">
        <td>
          <a href="#" @click="viewOrder(order.orderid, order.email)">
            {{ order.orderid }}
          </a>
        </td>
        <td>{{ order.cobrand }}</td>
        <td>{{ $getDateTime(order.date_quoted) }}</td>
        <td>{{ order.billto_firstname }} {{ order.billto_lastname }}</td>
        <td>{{ order.status }}</td>
        <td>{{ order.checkout_type }}</td>
        <td>{{ order.memo }}</td>
        <td>{{ order.customer_memo }}</td>
        <td class="right">{{ order.total }}</td>
        <td class="right">{{ order.total_due }}</td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td class="right">Total</td>
        <td>
          <b class="right">{{ total }}</b>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>
