<script>
import axios from 'axios';
export default {
  name: 'App',
  props: {
    manufacturer: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      response: null,
      sending: false,
      email: '',
      phone: '',
      message: '',
      valid_email: true,
      valid_phone: true
    };
  },
  methods: {
    validate() {
      this.valid_email = this.validateEmail(this.email);
      this.valid_phone = this.phone.length >= 7;
      return this.valid_email && this.valid_phone;
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    closeModal() {
      new window.bootstrap.Modal(document.getElementById('modal-ask')).hide();
    },
    async saveAskQuestion() {
      if (this.validate()) {
        this.sending = true;
        let data = {
          subject:
            'Website Information Request - ' + this.manufacturer + ' ' + this.model,
          message: this.message,
          email: this.email,
          phone: this.phone
        };
        const response = await axios.post(this.apiUrl + 'ask-question', data);

        // check for 200 status
        if (response.status === 200) {
          this.response = response.data.message;
          this.email = '';
          this.phone = '';
          this.message = '';
        } else {
          this.sending = false;
          alert('There was an error submitting your request');
        }
      }
    }
  }
};
</script>

<template>
  <!-- Modal -->
  <div
    id="modal-ask"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="modal_title" class="modal-title fs-5">Information Request</h1>
        </div>
        <div id="modal_body" class="modal-body">
          <div v-if="response" class="">
            <div class="alert alert-success" role="alert">
              {{ response }}
            </div>
          </div>
          <div v-else>
            <table class="">
              <tbody>
                <tr>
                  <td class="label" nowrap="">Your Email</td>
                  <td>
                    <input v-model="email" type="email" class="form-control" />
                    <div v-if="!valid_email" class="invalid-form">Email is required</div>
                  </td>
                </tr>
                <tr>
                  <td class="label" nowrap="">Your Phone</td>
                  <td>
                    <input v-model="phone" type="text" class="form-control" />
                    <div v-if="!valid_phone" class="invalid-form">Phone is required</div>
                  </td>
                </tr>
                <tr>
                  <td class="label" valign="top">Message</td>
                  <td>
                    <textarea v-model="message" class="form-control"></textarea>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td width="100%">
                    <button
                      v-if="!sending"
                      type="submit"
                      class="btn btn-primary mt-3"
                      @click="saveAskQuestion()"
                    >
                      submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
