<script>
import ModalPopupTerms from '@/components/ModalPopupTerms.vue';
import ModalPopupPolicy from '@/components/ModalPopupPolicy.vue';

export default {
  components: {
    ModalPopupTerms,
    ModalPopupPolicy
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    showModalTerms() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-terms'));
      myModal.show();
    },
    showPolicyTerms() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-policy'));
      myModal.show();
    }
  }
};
</script>
<template>
  <ModalPopupTerms></ModalPopupTerms>
  <ModalPopupPolicy></ModalPopupPolicy>

  <div class="text-center mt-4 footer">
    <div class="fs-6">
      808-845-5755 (8:00 a.m. – 4:30pm PST, M-F) or email sales@midcityre.com
    </div>

    Copyright © {{ currentYear }} • Mid City Restaurant Supply • 500 Alakawa St,
    Honolulu, HI 96817<br />
    <span class="hand" @click="showModalTerms">Policies</span> •
    <span class="hand" @click="showPolicyTerms">Privacy Policy</span><br />
  </div>
</template>

<style scoped>
.footer {
  background-color: #003767;
  color: #fff !important;
  padding: 20px;
}
.footer a {
  color: #fff !important;
}
</style>
