import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const myPlugin = {
  install(app) {

    // use as {{ showToast('title', 'body', 'error, success') }}
    app.config.globalProperties.showToast = (title, body, toast_type) => {
      const toastLiveExample = document.getElementById('toast');
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample);
      // set title, body
      document.getElementById('toast').classList.remove('toast-error');
      document.getElementById('toast').classList.remove('toast-success');

      if (toast_type === 'error') {
        document.getElementById('toast').classList.add('toast-error');
      } else {
        document.getElementById('toast').classList.add('toast-success');
      }
      document.getElementById('toast_title').innerHTML = title;
      document.getElementById('toast_body').innerHTML = body;
      if (body === "" || body == 'undefined' || body == null) 
        document.getElementById('toast_body').style.display = "none";
      else 
        document.getElementById('toast_body').style.display = "block";

      toastBootstrap.show();
    }
    

    // use as {{ $money(2334) }}
    app.config.globalProperties.$money = (key) => {
      return key ? '$' + Number(key).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-';

      return key ? '$' + Number(key).toFixed(2).toLocaleString() : '-';
    };

    // use as {{ $isGrandRapids(48809) }}
    app.config.globalProperties.$isGrandRapids = (key) => {
      return [
        48809, 48815, 48838, 48846, 48846, 48881, 48897, 49046, 49058, 49070, 49301,
        49302, 49303, 49306, 49315, 49316, 49318, 49319, 49321, 49322, 49323, 49325,
        49326, 49327, 49328, 49329, 49330, 49331, 49333, 49337, 49339, 49341, 49343,
        49344, 49345, 49347, 49348, 49401, 49403, 49404, 49408, 49412, 49415, 49415,
        49417, 49418, 49419, 49423, 49424, 49425, 49426, 49428, 49435, 49440, 49441,
        49442, 49444, 49445, 49448, 49451, 49453, 49456, 49457, 49460, 49464, 49503,
        49504, 49505, 49506, 49507, 49508, 49509, 49512, 49519, 49525, 49534, 49544,
        49546, 49548
      ].includes(key);
    };
    // use as {{ $isFtWayne(41056) }}
    app.config.globalProperties.$isFtWayne = (key) => {
      return [
        41056, 43152, 43506, 43512, 43526, 43545, 43553, 45804, 45813, 45821, 45832,
        45832, 45840, 45879, 45880, 45891, 46000, 46538, 46543, 46555, 46571, 46701,
        46703, 46705, 46706, 46710, 46711, 46714, 46721, 46723, 46725, 46732, 46733,
        46737, 46738, 46740, 46741, 46742, 46743, 46747, 46748, 46750, 46755, 46763,
        46765, 46767, 46770, 46772, 46773, 46774, 46783, 46784, 46785, 46786, 46787,
        46791, 46792, 46793, 46797, 46799, 46800, 46801, 46802, 46803, 46804, 46805,
        46806, 46807, 46808, 46809, 46814, 46815, 46816, 46818, 46819, 46825, 46835,
        46845, 46855, 46860, 46861, 46885, 46895, 46898, 46899, 46952, 46953, 46962,
        46992, 47340, 47371, 49250, 55438, 60540, 67278, 92626, 46761, 46601, 46745
      ].includes(key);
    };
    // use as {{ $isIndy(41056) }}
    app.config.globalProperties.$isIndy = (key) => {
      return [
        46032, 46033, 46037, 46038, 46040, 46052, 46055, 46060, 46061, 46062, 46074,
        46075, 46077, 46082, 46085, 46107, 46112, 46113, 46123, 46140, 46142, 46143,
        46158, 46163, 46167, 46168, 46184, 46201, 46202, 46203, 46204, 46205, 46206,
        46207, 46208, 46209, 46213, 46214, 46216, 46217, 46218, 46219, 46220, 46221,
        46222, 46224, 46225, 46226, 46227, 46228, 46229, 46230, 46231, 46234, 46235,
        46236, 46237, 46239, 46240, 46241, 46242, 46244, 46247, 46249, 46250, 46251,
        46253, 46254, 46255, 46256, 46259, 46260, 46262, 46268, 46277, 46278, 46280,
        46282, 46283, 46285, 46290, 46298, 46131, 47907, 47996, 46901, 46902, 46903,
        46904, 47906, 46016, 47351, 47384
      ].includes(key);
    };

    // use as {{ $isSouthBend(46635) }}
    app.config.globalProperties.$isSouthBend = (key) => {
      return [
        11040, 19087, 28152, 29316, 29341, 32763, 32901, 38119, 38305, 40272, 40509,
        40985, 41101, 42134, 42431, 43724, 44146, 44701, 45320, 45750, 46007, 46207,
        46301, 46304, 46307, 46310, 46311, 46311, 46319, 46320, 46321, 46322, 46323,
        46342, 46350, 46350, 46356, 46360, 46361, 46366, 46368, 46371, 46375, 46382,
        46383, 46385, 46391, 46392, 46394, 46403, 46404, 46405, 46408, 46410, 46501,
        46504, 46506, 46507, 46511, 46514, 46515, 46516, 46517, 46524, 46526, 46527,
        46528, 46530, 46532, 46534, 46536, 46537, 46538, 46539, 46540, 46542, 46544,
        46545, 46546, 46550, 46552, 46554, 46555, 46556, 46561, 46563, 46565, 46567,
        46571, 46573, 46574, 46580, 46582, 46613, 46614, 46615, 46616, 46617, 46618,
        46619, 46624, 46628, 46628, 46634, 46635, 46637, 46660, 46680, 46703, 46714,
        46725, 46737, 46743, 46746, 46755, 46767, 46776, 46784, 46795, 46804, 46805,
        46809, 46914, 46923, 46947, 46970, 46975, 46992, 46996, 47304, 47405, 47546,
        47670, 47905, 47909, 47933, 47960, 48099, 48152, 49001, 49002, 49003, 49004,
        49005, 49006, 49007, 49008, 49009, 49010, 49012, 49013, 49014, 49015, 49017,
        49019, 49022, 49023, 49024, 49026, 49028, 49030, 49031, 49032, 49035, 49036,
        49037, 49038, 49040, 49041, 49042, 49045, 49047, 49048, 49053, 49055, 49056,
        49057, 49061, 49064, 49065, 49066, 49067, 49068, 49071, 49077, 49078, 49079,
        49080, 49081, 49083, 49085, 49087, 49088, 49090, 49091, 49093, 49095, 49097,
        49098, 49099, 49101, 49103, 49104, 49106, 49107, 49112, 49113, 49115, 49117,
        49120, 49125, 49127, 49128, 49129, 49130, 49131, 49242, 54324, 55344, 58102,
        60002, 60148, 60187, 60201, 60202, 60401, 60411, 60416, 60417, 60430, 60432,
        60439, 60447, 60462, 60466, 60505, 60527, 60544, 60560, 60623, 60628, 60640,
        60649, 60653, 61602, 61866, 62650, 62702, 63166, 63703, 63755, 66113, 66202,
        67278, 49111, 46346, 46340, 49406, 46348, 49102
      ].includes(key);
    };

    // use as {{ $getIsland(96748) }}
    app.config.globalProperties.$getIsland = (key) => {
      if (isMolokai(key)) {
        return 'Molokai';
      } else if (isLanai(key)) {
        return 'Lanai';
      } else if (isBigIsland(key) || isBigIslandNonDeliver(key)) {
        return 'Big Island';
      } else if (isKauai(key)) {
        return 'Kauai';
      } else if (isMaui(key) || isMauiNonDeliver(key)) {
        return 'Maui';
      } else if (isOahu(key)) {
        return 'Oahu';
      }
      return '';
    };

    // use as {{ $isMolokai(96748) }}
    app.config.globalProperties.$isMolokai = (key) => {
      return isMolokai(key);
    };
    // use as {{ $isLanai(96763) }}
    app.config.globalProperties.$isLanai = (key) => {
      return isLanai(key);
    };
    // use as {{ $isBigIsland(96748) }}
    app.config.globalProperties.$isBigIsland = (key) => {
      return isBigIsland(key);
    };
    // use as {{ $isBigIslandNonDeliver(96748) }}
    app.config.globalProperties.$isBigIslandNonDeliver = (key) => {
      return isBigIslandNonDeliver(key);
    };
    // use as {{ $isKauai(96748) }}
    app.config.globalProperties.$isKauai = (key) => {
      return isKauai(key);
    };

    // use as {{ $isMaui(96748) }}
    app.config.globalProperties.$isMaui = (key) => {
      return isMaui(key);
    };
    // use as {{ $isMauiNonDeliver(96748) }}
    app.config.globalProperties.$isMauiNonDeliver = (key) => {
      return isMauiNonDeliver(key);
    };
    // use as {{ $isOahu(96748) }}
    app.config.globalProperties.$isOahu = (key) => {
      return isOahu(key);
    };

    
    // NO LONGER USED- insert into dbase with client time   use as {{ $getDate(clientName, item.date_modified) }}
    app.config.globalProperties.$getDate = (client, date) => {
      return dayjs(date).format('YYYY-MM-DD')

      if (client === 'atlas') {
        return dayjs.tz(date, "America/Indianapolis").format('YYYY-MM-DD') // Thu, 28 Sep 2023 07:46:27 GMT
      }
      return dayjs.tz(date, "Pacific/Honolulu").format('YYYY-MM-DD')// 	Thu, 28 Sep 2023 13:46:27 GMT
    };
    // NO LONGER USED- insert into dbase with client time   use as {{ $getDateTime(item.date_modified) }}
    app.config.globalProperties.$getDateTime = (date) => {
      return dayjs(date).format('YYYY-MM-DD hh:mma')
      if (client === 'atlas') {
        return dayjs.tz(date, "America/Indianapolis").format('YYYY-MM-DD hh:mma') // Thu, 28 Sep 2023 07:46:27 GMT
      }
      return dayjs.tz(date, "Pacific/Honolulu").format('YYYY-MM-DD hh:mma')// 	Thu, 28 Sep 2023 13:46:27 GMT
    };
  }
};

export default myPlugin;

const isMolokai = (key) => {
  return [96748].includes(key);
};
const isLanai = (key) => {
  return [96763].includes(key);
};
const isBigIsland = (key) => {
  return [
    96704, 96710, 96718, 96719, 96720, 96721, 96725, 96726, 96727, 96728, 96738, 96739,
    96740, 96743, 96745, 96749, 96750, 96755, 96760, 96764, 96771, 96773, 96774, 96776
  ].includes(key);
};
const isBigIslandNonDeliver = (key) => {
  return [96785, 96777, 96772, 96737].includes(key);
};
const isKauai = (key) => {
  return [
    96703, 96705, 96714, 96715, 96716, 96722, 96741, 96746, 96747, 96751, 96752, 96754,
    96756, 96765, 96766, 96769, 96796
  ].includes(key);
};
const isMaui = (key) => {
  return [
    96732, 96733, 96753, 96757, 96761, 96767, 96768, 96770, 96779, 96784, 96788, 96793
  ].includes(key);
};
const isMauiNonDeliver = (key) => {
  return [96708, 96713, 96790, 96729].includes(key);
};
const isOahu = (key) => {
  return [
    96818, 96701, 96706, 96707, 96709, 96712, 96717, 96730, 96731, 96734, 96744, 96759,
    96762, 96782, 96786, 96789, 96791, 96792, 96795, 96797, 96801, 96802, 96803, 96804,
    96805, 96806, 96807, 96808, 96809, 96810, 96811, 96812, 96813, 96814, 96815, 96816,
    96817, 96819, 96820, 96821, 96822, 96823, 96824, 96825, 96826, 96836, 96837, 96838,
    96839, 96840, 96841, 96843, 96844, 96846, 96847, 96848, 96849, 96850, 96853, 96854,
    96857, 96858, 96859, 96860, 96861, 96863, 96898
  ].includes(key);
};

