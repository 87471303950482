<script>
import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

export default {
  data() {
    const authStore = useAuthStore();
    return {
      sections: [],
      authStore
    };
  },
  mounted() {
    if (this.authStore.sections.length == 0) {
      axios
        .get(this.apiUrl + 'sections')
        .then((response) => {
          this.authStore.sections = response.data;
          this.sections = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
    this.sections = this.authStore.sections;
  }
};
</script>

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <!---<a class="navbar-brand" href="/"></a>-->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <div v-for="section in sections" :key="section.id">
            <li class="nav-item dropdown">
              <a
                id="navbarDropdown"
                class="nav-link dropdown-toggle"
                href="section.url_link"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ section.name }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" :href="section.url_link">
                    {{ section.name }}</a
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <div v-for="sub_section in section.sub_sections" :key="sub_section.id">
                  <li>
                    <a class="dropdown-item" :href="sub_section.url_link">
                      {{ sub_section.name }}
                    </a>
                  </li>
                </div>
              </ul>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.dropdown {
  padding-right: 20px;
}
</style>
<style>
.btn-primary {
  background-color: #003767 !important;
  border-color: #003767 !important;
}
.search-bar-icon {
  background-color: #003767 !important;
}
.btn-danger {
  background-color: #003767 !important;
  border: #003767 !important;
}
.btn-primary {
  background-color: #198754 !important;
  border: #198754 !important;
}
.filter-header {
  background-color: #a2a410 !important;
}
</style>
