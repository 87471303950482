<script>
import axios from 'axios';

export default {
  data() {
    return {
      manufacturer: {
        id: 0,
        address: '',
        city: '',
        zip: '',
        state_code: 'IN',
        country_code: 'USA',
        phone: '',
        email: '',
        email_po: 1,
        fax_po: 0,
        fax: '',

        cash_discount_percent: 1.0,
        generated_from_import: 1,
        is_rebateable: 1,
        manufacturer_aq_short_name: '',
        manufacturer_code: '',
        manufacturer_image_url: '',
        manufacturer: '',
        map_policy: '',
        memo: '',

        rep_address: '',
        rep_cell_phone: '',
        rep_city: '',
        rep_company: '',
        rep_country_code: '',
        rep_email_po: 1,
        rep_email: '',
        rep_fax_po: 0,
        rep_fax: '',
        rep_name: '',
        rep_phone: '',
        rep_state_code: 'IN',
        rep_zip: '',

        ships_from_address: '',
        ships_from_city: '',
        ships_from_country_code: 'USA',
        ships_from_state_code: 'CA',
        ships_from_zip: '',
        site_url: '',
        terms: ''
      },
      country_codes: ['US', 'CA'],
      state_codes: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',

        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT'
      ]
    };
  },
  mounted() {
    // API get manufacturer
    if (this.$route.params.id > 0) {
      axios
        .get(this.apiUrl + 'admin/manufacturer/' + this.$route.params.id)
        .then((response) => {
          this.manufacturer = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  },
  methods: {
    cancelManufacturer() {
      this.$router.push({ path: '/admin/manufacturer' });
    },
    saveManufacturer() {
      axios
        .post(this.apiUrl + 'admin/manufacturer', this.manufacturer)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/manufacturer' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Manufacturer / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelManufacturer">
        Cancel
      </button>
      <button class="btn btn-primary btn-sm" @click="saveManufacturer">Save</button>
    </div>
  </div>

  <table class="table">
    <tr>
      <td class="form-label">code</td>
      <td>
        <input
          v-model="manufacturer.manufacturer_code"
          class="form-control"
          type="text"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">logo</td>
      <td>
        <input
          v-model="manufacturer.manufacturer_image_url"
          class="form-control"
          type="text"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">www</td>
      <td>
        <input v-model="manufacturer.site_url" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">docs</td>
      <td>TODO: Add file upload</td>
    </tr>

    <tr>
      <td class="form-label">cash discount</td>
      <td>
        <div class="input-group no-padding-left">
          <input
            v-model="manufacturer.cash_discount_percent"
            class="form-control"
            type="number"
            step="0.01"
            size="3"
          /><span class="input-group-text">%</span>
        </div>
      </td>
    </tr>
    <tr>
      <td class="form-label">is rebateable</td>
      <td>
        <input
          v-model="manufacturer.is_rebateable"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">memo</td>
      <td>
        <textarea v-model="manufacturer.memo" class="form-control" rows="3"></textarea>
      </td>
    </tr>
    <tr>
      <td class="form-label">terms</td>
      <td>
        <textarea v-model="manufacturer.terms" class="form-control" rows="3"></textarea>
      </td>
    </tr>
    <tr>
      <td class="form-label">map policy</td>
      <td>
        <textarea
          v-model="manufacturer.map_policy"
          class="form-control"
          rows="3"
        ></textarea>
      </td>
    </tr>

    <tr>
      <td></td>
      <td><b>Manufacturer</b></td>
      <td><b>Rep</b></td>
    </tr>

    <tr>
      <td class="form-label">email PO</td>
      <td>
        <input
          v-model="manufacturer.email_po"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
      <td>
        <input
          v-model="manufacturer.rep_email_po"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">name</td>
      <td></td>
      <td>
        <input v-model="manufacturer.rep_name" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">company</td>
      <td style="width: 40%">
        <input v-model="manufacturer.manufacturer" class="form-control" type="text" />
      </td>
      <td style="width: 40%">
        <input v-model="manufacturer.rep_company" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">email</td>
      <td><input v-model="manufacturer.email" class="form-control" type="email" /></td>
      <td>
        <input v-model="manufacturer.rep_email" class="form-control" type="email" />
      </td>
    </tr>
    <tr>
      <td class="form-label">phone</td>
      <td><input v-model="manufacturer.phone" class="form-control" type="text" /></td>
      <td>
        <input v-model="manufacturer.rep_phone" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">address</td>
      <td>
        <input v-model="manufacturer.address" class="form-control" type="text" />
      </td>
      <td>
        <input v-model="manufacturer.rep_address" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">city state zip</td>
      <td>
        <input
          v-model="manufacturer.city"
          class="form-control no-width inline"
          type="text"
          placeholder="city"
        />

        <select v-model="manufacturer.state" class="form-select no-width inline mx-1">
          <option v-for="state in state_codes" :key="state" :value="state">
            {{ state }}
          </option>
        </select>

        <input
          v-model="manufacturer.zip"
          class="form-control no-width inline"
          type="text"
          placeholder="zip"
        />
      </td>

      <td>
        <input
          v-model="manufacturer.rep_city"
          class="form-control no-width inline"
          type="text"
          placeholder="city"
        />

        <select
          v-model="manufacturer.rep_state_code"
          class="form-select no-width inline mx-1"
        >
          <option v-for="state in state_codes" :key="state" :value="state">
            {{ state }}
          </option>
        </select>

        <input
          v-model="manufacturer.rep_zip"
          class="form-control no-width inline"
          type="text"
          placeholder="zip"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">country</td>
      <td>
        <select v-model="manufacturer.country_code" class="form-select">
          <option v-for="country in country_codes" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </td>
      <td>
        <select v-model="manufacturer.rep_country_code" class="form-select">
          <option v-for="country in country_codes" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </td>
    </tr>
  </table>

  <h4>DefaultWarehouse</h4>
  <table class="table">
    <tr>
      <td class="form-label">address</td>
      <td>
        <input
          v-model="manufacturer.ships_from_address"
          class="form-control"
          type="text"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">city state zip</td>
      <td>
        <input
          v-model="manufacturer.ships_from_city"
          class="form-control no-width inline"
          type="text"
          placeholder="city"
        />

        <select
          v-model="manufacturer.ships_from_state_code"
          class="form-select no-width inline mx-1"
        >
          <option v-for="state in state_codes" :key="state" :value="state">
            {{ state }}
          </option>
        </select>

        <input
          v-model="manufacturer.ships_from_zip"
          class="form-control no-width inline"
          type="text"
          placeholder="zip"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">country</td>
      <td>
        <select v-model="manufacturer.ships_from_country_code" class="form-select">
          <option v-for="country in country_codes" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </td>
    </tr>
  </table>
</template>

<style>
.no-padding-left {
  padding-left: 0 !important;
}
</style>
