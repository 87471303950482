<script>
import BreadcrumbTrail from '../components/BreadcrumbTrail.vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import ItemComponent from '../components/ItemComponent.vue';
import ItemsListComponent from '../components/ItemsListComponent.vue';
import { useAuthStore } from '@/stores/authStore';

export default {
  components: {
    BreadcrumbTrail,
    ItemComponent,
    ItemsListComponent
  },
  data() {
    const authStore = useAuthStore();
    return {
      authStore,
      // TODO section equal to URL pull?
      item: '',
      items: '',
      filters: '',
      sections: ''
    };
  },
  mounted() {
    const route = useRoute();
    let api_endpoint = 'product_results';
    let search_string = '';

    //console.log("search" + this.$route.query.search)
    //console.log("search" + route.query.search)
    if (route.query.search) {
      search_string = this.$route.query.search;
      api_endpoint = 'search';
    } else {
      search_string = computed(() => route.path).value;
    }

    axios
      .post(this.apiUrl + api_endpoint, {
        search: search_string,
        profile_company_id: this.authStore.profile.profile_company_id
      })
      .then((response) => {
        this.sections = response.data.sections;
        this.items = response.data.items;
        this.item = response.data.item;
        this.breadcrumbs = response.data.breadcrumbs;
        this.filters = response.data.filters;
      })
      .catch((error) => {
        console.log(api_endpoint, error.message);
      });
  },

  methods: {
    addToCart(item) {
      const authStore = useAuthStore();
      item.qty = 1;
      authStore.addToCart(item);
    }
  }
};
</script>

<template>
  <div>
    <BreadcrumbTrail :breadcrumbs="breadcrumbs"></BreadcrumbTrail>

    <!--- sections -->
    <ul
      v-if="sections.length > 0"
      class="list-group list-group-horizontal item-container"
    >
      <li v-for="section in sections" :key="section.url" class="list-group-item">
        <a :href="section.url_link">
          <img :src="section.sec_image_url" class="sec-img" /><br />
          {{ section.name }}
        </a>
      </li>
    </ul>

    <!--- items list -->
    <div v-if="items.length > 0">
      <ItemsListComponent :items="items" :filters="filters"></ItemsListComponent>
    </div>

    <!--- item -->
    <div v-if="item">
      <ItemComponent :item="item"></ItemComponent>
    </div>
  </div>
</template>

<style scoped>
.list-group-item + .list-group-item {
  border-width: 1px;
}
.list-group-item {
  height: 185px;
  list-style: none outside none;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 0px;
  width: 174px;
  text-align: center;
}

.list-100 {
  width: 100%;
  border: 0;
  border-bottom: 1px solid;
  text-align: left;
}
</style>
