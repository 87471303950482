<script>
import axios from 'axios';

export default {
  data() {
    return {
      show_form: false,
      email_list: [],
      new_email: ''
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/email')
      .then((response) => {
        this.email_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addEmail() {
      // API save email
      axios
        .post(this.apiUrl + 'admin/email', {
          email: this.new_email
        })
        .then((response) => {
          this.showToast('Data saved', '', 'success');

          // hide form
          this.show_form = false;

          // add email to list
          this.email_list.push({ email: this.new_email });

          // clear email in form
          this.new_email = '';
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    deleteEmail(email, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/email/' + email)
          .then((resp) => {
            this.email_list.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Emails</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="show_form = !show_form">Add</button>
    </div>
  </div>

  <div v-if="show_form" class="p2">
    <input v-model="new_email" type="email" />
    <button class="btn btn-success btn-sm" @click="addEmail()">Save</button>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Email</td>
        <td class="text-center">Opt Out</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(email, index) in email_list" :key="email.email">
        <td>{{ email.email }}</td>
        <td class="text-center">
          <a class="hand" :href="javascript" @click="deleteEmail(email.email, index)">
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
