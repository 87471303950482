<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      show_form: false,
      itemsMiscInfo_list: [],
      new_itemsMiscInfo: ''
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/items_misc_info')
      .then((response) => {
        this.itemsMiscInfo_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    viewItem(item_id) {
      router.push({ path: '/admin/item/' + item_id });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Items Misc Info</h2>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Manufacturer</td>
        <td>Model</td>
        <td>Dates</td>
        <td>Misc Info</td>

        <td class="text-center">View</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="itemsMiscInfo in itemsMiscInfo_list" :key="itemsMiscInfo.id">
        <td>{{ itemsMiscInfo.manufacturer }}</td>
        <td>{{ itemsMiscInfo.model }}</td>
        <td>
          {{ itemsMiscInfo.misc_info_begin_date }}-{{ itemsMiscInfo.misc_info_end_date }}
        </td>
        <td>{{ itemsMiscInfo.misc_info }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="viewItem(itemsMiscInfo.id)">
            <font-awesome-icon icon="fa-eye" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
