<script>
import AtlasNavBar from './AtlasNavBar.vue';
import MidCityNavBar from './MidCityNavBar.vue';
import MyChefStoreNavBar from './MyChefStoreNavBar.vue';

export default {
  components: {
    AtlasNavBar,
    MyChefStoreNavBar,
    MidCityNavBar
  }
};
</script>

<template>
  <div class="mb-2 no-print">
    <div v-if="clientName === 'atlas'">
      <AtlasNavBar></AtlasNavBar>
    </div>
    <div v-if="clientName === 'mychefstore'">
      <MyChefStoreNavBar></MyChefStoreNavBar>
    </div>
    <div v-if="clientName === 'midcity'">
      <MidCityNavBar></MidCityNavBar>
    </div>
  </div>
</template>
