<script>
import axios from 'axios';

export default {
  data() {
    return {
      specials_list: [],
      placeholderImage: 'https://via.placeholder.com/800x400?text=Placeholder+Image'
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/specials')
      .then((response) => {
        this.specials_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  }
};
</script>

<template>
  <div class="row">
    <div
      v-for="(special, index) in specials_list"
      :key="index"
      class="col-lg-4 col-sm-12"
    >
      <a :href="special.special_url"
        ><img
          :src="special.image_url || placeholderImage"
          class="img-thumbnail mb-3 hand"
      /></a>
    </div>
  </div>
</template>

<style scoped></style>
