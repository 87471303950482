<script>
import { useAuthStore } from '@/stores/authStore';
import { ref } from 'vue';
import axios from 'axios';
import router from '@/router/index.js';

export default {
  data() {
    const authStore = useAuthStore();

    return {
      authStore,
      profile: {},
      profile_company: {
        state: 'HI',
        country: 'US',

        billto_state: 'HI',
        billto_country: 'US'
      },

      filters: {
        profile_id: authStore.profile.profileid,
        profile_company_id: authStore.profile.profile_company_id,
        status: ''
      },

      country_codes: ['US', 'CA'],
      state_codes: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',

        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT'
      ]
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      if (this.authStore.profile.profileid) {
        await axios
          .post(this.apiUrl + 'profile/get-profile', this.filters)
          .then((response) => {
            this.profile = response.data.profile;
            this.profile_company = response.data.profile_company;
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.detail) {
              this.showToast('Error', error.response.data.detail, 'error');
            } else {
              this.showToast('Error', error.message, 'error');
            }
          });
      }
    },

    async save() {
      let data = { profile: this.profile, profile_company: this.profile_company };
      await axios
        .post(this.apiUrl + 'profile/save-profile', data)
        .then((response) => {
          console.log(response.data.profile);

          if (this.authStore.profile.profileid) {
            this.showToast('Success', 'Profile saved', 'success');
          } else {
            // new profile
            this.authStore.profile = response.data.profile;
            router.push({ path: '/profile/orders' });
          }
        })

        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <h3>Edit Profile</h3>

  <div class="item-container">
    <div class="d-flex align-items-center">
      <h4>Buyer</h4>
      <div class="ms-auto">
        <button class="btn btn-primary btn-sm" @click="save()">save</button>
      </div>
    </div>
    <table>
      <tr>
        <td class="form-label">firstname</td>
        <td>
          <input v-model="profile.firstname" class="form-control" type="text" />
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="form-label">lastname</td>
        <td>
          <input v-model="profile.lastname" class="form-control" type="text" />
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="form-label">email</td>
        <td>
          <input v-model="profile.email" class="form-control" type="email" />
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="form-label">phone</td>
        <td>
          <input v-model="profile.phone" class="form-control" type="text" />
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="form-label">password</td>
        <td>
          <input v-model="profile.password" class="form-control" type="password" />
        </td>
        <td></td>
      </tr>
    </table>

    <h4>Company</h4>
    <table>
      <tr>
        <td></td>
        <td><b>Bill To</b></td>
        <td><b>Ship To</b></td>
      </tr>
      <tr>
        <td class="form-label">company</td>
        <td style="width: 40%">
          <input
            v-model="profile_company.billto_company"
            class="form-control"
            type="text"
          />
        </td>
        <td style="width: 40%">
          <input v-model="profile_company.company" class="form-control" type="text" />
        </td>
      </tr>
      <tr>
        <td class="form-label">firstname</td>
        <td>
          <input
            v-model="profile_company.billto_firstname"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="profile_company.firstname" class="form-control" type="text" />
        </td>
      </tr>
      <tr>
        <td class="form-label">lastname</td>
        <td>
          <input
            v-model="profile_company.billto_lastname"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="profile_company.lastname" class="form-control" type="text" />
        </td>
      </tr>
      <tr>
        <td class="form-label">email</td>
        <td>
          <input
            v-model="profile_company.billto_email"
            class="form-control"
            type="email"
          />
        </td>
        <td>
          <input v-model="profile_company.email" class="form-control" type="email" />
        </td>
      </tr>
      <tr>
        <td class="form-label">phone</td>
        <td>
          <input
            v-model="profile_company.billto_phone"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="profile_company.phone" class="form-control" type="text" />
        </td>
      </tr>
      <tr>
        <td class="form-label">address</td>
        <td>
          <input
            v-model="profile_company.billto_address1"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="profile_company.address1" class="form-control" type="text" />
        </td>
      </tr>
      <tr>
        <td class="form-label">address2</td>
        <td>
          <input
            v-model="profile_company.billto_address2"
            class="form-control"
            type="text"
          />
        </td>
        <td>
          <input v-model="profile_company.address2" class="form-control" type="text" />
        </td>
      </tr>
      <tr>
        <td class="form-label">city state zip</td>
        <td>
          <input
            v-model="profile_company.billto_city"
            class="form-control no-width inline"
            type="text"
            placeholder="city"
          />

          <select
            v-model="profile_company.billto_state"
            class="form-select no-width inline mx-1"
          >
            <option v-for="state in state_codes" :key="state" :value="state">
              {{ state }}
            </option>
          </select>

          <input
            v-model="profile_company.billto_zip"
            class="form-control no-width inline"
            type="text"
            placeholder="zip"
          />
        </td>
        <td>
          <input
            v-model="profile_company.city"
            class="form-control no-width inline"
            type="text"
            placeholder="city"
          />

          <select
            v-model="profile_company.state"
            class="form-select no-width inline mx-1"
          >
            <option v-for="state in state_codes" :key="state" :value="state">
              {{ state }}
            </option>
          </select>

          <input
            v-model="profile_company.zip"
            class="form-control no-width inline"
            type="text"
            placeholder="zip"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">country</td>
        <td>
          <select v-model="profile_company.billto_country" class="form-select">
            <option v-for="country in country_codes" :key="country" :value="country">
              {{ country }}
            </option>
          </select>
        </td>
        <td>
          <select v-model="profile_company.country" class="form-select">
            <option v-for="country in country_codes" :key="country" :value="country">
              {{ country }}
            </option>
          </select>
        </td>
      </tr>
    </table>
  </div>
</template>
