<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      allEmployeeList: [],
      employeeList: [],
      paginationCurrentPage: 1,
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 50
    };
  },
  mounted() {
    this.getAllEmployeeList();
  },
  methods: {
    setEmployeeSubList() {
      const start = (this.paginationCurrentPage - 1) * this.paginationMaxRecordsPerPage;
      const end = this.paginationCurrentPage * this.paginationMaxRecordsPerPage;
      this.employeeList = this.allEmployeeList.slice(start, end);
    },
    getAllEmployeeList() {
      axios
        .get(this.apiUrl + 'admin/employee')
        .then((response) => {
          this.allEmployeeList = response.data;
          this.paginationTotalRecords = response.data.length;
          this.setEmployeeSubList();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    addEmployee() {
      router.push({ path: '/admin/employee/0' });
    },
    modifyEmployee(employee_id) {
      router.push({ path: '/admin/employee/' + employee_id });
    },
    processPagination(page) {
      // no need to get sub list since original is small- just slice it
      this.paginationCurrentPage = page;
      this.setEmployeeSubList();
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Employees</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="addEmployee">Add</button>
    </div>
  </div>

  <vue-awesome-paginate
    v-model="paginationCurrentPage"
    :total-items="paginationTotalRecords"
    :items-per-page="paginationMaxRecordsPerPage"
    :max-pages-shown="paginationMaxRecordsPerPage"
    :on-click="processPagination"
  />

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Firstname</td>
        <td>Lastname</td>
        <td>Phone</td>
        <td>Email</td>

        <td>Inactive</td>
        <td>Account Type</td>

        <td class="text-center">Modify</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="employee in employeeList" :key="employee.id">
        <td>{{ employee.firstname }}</td>
        <td>{{ employee.lastname }}</td>
        <td>{{ employee.phone }}</td>
        <td>{{ employee.email }}</td>

        <td>{{ employee.inactive }}</td>
        <td>{{ employee.account_type }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifyEmployee(employee.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
