<script>
import axios from 'axios';

export default {
  data() {
    return {
      file: null,
      section_list: [],
      section: {
        id: 0,
        parent_id: 0,
        name: '',
        description: '',
        sec_image_url: '',
        site_meta_keywords: '',
        site_meta_description: '',
        site_title: ''
      }
    };
  },
  mounted() {
    // API get section
    if (this.$route.params.id > 0) {
      axios
        .get(this.apiUrl + 'admin/section/' + this.$route.params.id)
        .then((response) => {
          this.section = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }

    axios
      .get(this.apiUrl + 'admin/section/dropdown')
      .then((response) => {
        this.section_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addFile(e) {
      this.file = e.target.files[0];
    },
    cancel() {
      this.$router.push({ path: '/admin/section' });
    },
    saveSec() {
      axios
        .post(this.apiUrl + 'admin/section', this.section)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/section' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    save() {
      if (this.file) {
        const data = new FormData();
        data.append('file', this.file);
        axios
          .post(this.apiUrl + 'admin/image-section', data)
          .then((response) => {
            this.section.sec_image_url = response.data.image_url;
          })
          .then(() => {
            this.saveSec();
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.detail) {
              this.showToast('Error', error.response.data.detail, 'error');
            } else {
              this.showToast('Error', error.message, 'error');
            }
          });
      } else {
        this.saveSec();
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Section / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancel">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="save">Save</button>
    </div>
  </div>

  <table class="width-100">
    <tr>
      <td class="form-label">Name</td>
      <td><input v-model="section.name" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">Description</td>
      <td>
        <input v-model="section.description" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">Parent Section</td>
      <td>
        <select v-model="section.parent_id">
          <option v-for="sec in section_list" :key="sec.name" :value="sec.id">
            {{ sec.name }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">Site_Title</td>
      <td><input v-model="section.site_title" class="form-control" /></td>
    </tr>
    <tr>
      <td class="form-label">Site Keywords</td>
      <td>
        <input v-model="section.site_meta_keywords" class="form-control" />
      </td>
    </tr>
    <tr>
      <td class="form-label">Site Description</td>
      <td>
        <input v-model="section.site_meta_description" class="form-control" />
      </td>
    </tr>
    <tr>
      <td class="form-label">Image</td>
      <td>
        <input type="file" @change="addFile" />
        <img :src="section.sec_image_url" />
      </td>
    </tr>
  </table>
</template>
