import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../views/HomePage.vue';
import ProductsView from '../views/ProductsView.vue';
import ShoppingCart from '../views/ShoppingCart.vue';
import ProfileLogin from '../views/ProfileLogin.vue';
import ProfileEdit from '../views/ProfileEdit.vue';
import ProfileOrderView from '../views/ProfileOrderView.vue';
import ProfileOrders from '../views/ProfileOrders.vue';
import ProfileOrderGuide from '../views/ProfileOrderGuide.vue';



// admin views ****************************************************
import AdminLogin from '../views/admin/AdminLogin.vue';
import AdminOrders from '../views/admin/AdminOrders.vue';
import APOrdersList from '../views/admin/accounting/APOrdersList.vue';
import CobrandAddMod from '../views/admin/CobrandAddMod.vue';
import CobrandList from '../views/admin/CobrandList.vue';
import EmailList from '../views/admin/EmailList.vue';
import EmployeeAddMod from '../views/admin/EmployeeAddMod.vue';
import EmployeeList from '../views/admin/EmployeeList.vue';
import MemoList from '../views/admin/MemoList.vue';
import ItemInfo from '../views/admin/ItemList.vue';
import ItemAddMod from '../views/admin/ItemAddMod.vue';
import ItemsMiscInfo from '../views/admin/ItemsMiscInfoList.vue';
import ItemsMiscInfoAddMod from '../views/admin/ItemsMiscInfoAddMod.vue';
import ManufacturerList from '../views/admin/ManufacturerList.vue';
import ManufacturerAddMod from '../views/admin/ManufacturerAddMod.vue';
import MemoAddMod from '../views/admin/MemoAddMod.vue';
import OptionList from '../views/admin/OptionList.vue';
import Orders from '../views/admin/OrdersList.vue';
import OrderEdit from '../views/admin/OrderEdit.vue';
import OrderView from '../views/admin/OrderView.vue';
import OverrideCog from '../views/admin/OverrideCogList.vue';
import OverrideMarkup from '../views/admin/OverrideMarkupList.vue';
import OverrideMap from '../views/admin/OverrideMapList.vue';
import ProfileList from '../views/admin/ProfileList.vue';
import ProfileAddMod from '../views/admin/ProfileAddMod.vue';
import ProfileCompanyAddMod from '../views/admin/ProfileCompanyAddMod.vue';
import RebatesList from '../views/admin/accounting/RebatesList.vue';
import RevenueList from '../views/admin/accounting/RevenueList.vue';
import TaxRate from '../views/admin/TaxRate.vue';
import RunMarkups from '../views/admin/RunMarkups.vue';
import SectionList from '../views/admin/SectionList.vue';
import SectionAddMod from '../views/admin/SectionAddMod.vue';
import WarehouseList from '../views/admin/WarehouseList.vue';
import WarehouseAddMod from '../views/admin/WarehouseAddMod.vue';
import ZeroCog from '../views/admin/ZeroCogList.vue';
import SpecialsList from '../views/admin/SpecialsList.vue';
import SpecialsAddMod from '../views/admin/SpecialsAddMod.vue';
import StockItemList from '../views/admin/StockItem.vue';
import { useAuthStore } from '../stores/authStore';
import OrdersList from '../views/admin/OrdersList.vue';
import ProfileAssignedItems from '@/views/ProfileAssignedItems.vue';

const routes = [
  {
    path: '/shopping-cart',
    component: ShoppingCart,
    meta: { is_admin: false }
  },
  {
    path: '/',
    component: HomePage,
    meta: { is_admin: false }
  },
  {
    path: '/login',
    component: ProfileLogin,
    meta: { is_admin: false }
  },
  {
    path: '/profile/edit',
    component: ProfileEdit,
    meta: { is_admin: false }
  },
  {
    path: '/profile/order-guide',
    component: ProfileOrderGuide,
    meta: { is_admin: false }
  },  
  {
    path: '/profile/assigned-items',
    component: ProfileAssignedItems,
    meta: { is_admin: false }
  },  
  {
    path: '/profile/orders',
    component: ProfileOrders,
    meta: { is_admin: false }
  },
  {
    path: '/profile/orders/:id/:email',
    component: ProfileOrderView,
    meta: { is_admin: false }
  },
  {
    path: '/orders',
    component: ProfileLogin,
    meta: { is_admin: false }
  },
  {
    path: '/:catchAll(.*)',
    component: ProductsView,
    meta: { is_admin: false }
  },

  // admin routes ****************************************************
  // admin routes ****************************************************
  // admin routes ****************************************************
  {
    path: '/admin',
    component: OrdersList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/accounting/ap-orders',
    component: APOrdersList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/accounting/rebates',
    component: RebatesList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/accounting/revenue',
    component: RevenueList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/cobrand',
    component: CobrandList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/cobrand/:id',
    component: CobrandAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/email-list',
    component: EmailList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/employee',
    component: EmployeeList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/employee/:id',
    component: EmployeeAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/item',
    component: ItemInfo,
    meta: { is_admin: true }
  },
  {
    path: '/admin/item/:id',
    component: ItemAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/items_misc_info',
    component: ItemsMiscInfo,
    meta: { is_admin: true }
  },
  {
    path: '/admin/items_misc_info/:id',
    component: ItemsMiscInfoAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/login',
    component: AdminLogin,
    meta: { is_admin: true }
  },
  {
    path: '/admin/manufacturer',
    component: ManufacturerList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/manufacturer/:id',
    component: ManufacturerAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/memo',
    component: MemoList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/memo/:id',
    component: MemoAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/option',
    component: OptionList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/profile',
    component: ProfileList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/profile/company/:profile_company_id',
    component: ProfileCompanyAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/profile/:profileid',
    component: ProfileAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/orders',
    component: Orders,
    meta: { is_admin: true }
  },
  {
    path: '/admin/orders/:id',
    component: OrderEdit,
    meta: {is_admin: true }
  },
  {
    path: '/admin/orders/view/:id',
    component: OrderView,
    meta: {is_admin: true }
  },
  {
    path: '/admin/override_cog',
    component: OverrideCog,
    meta: { is_admin: true }
  },
  {
    path: '/admin/override_markup',
    component: OverrideMarkup,
    meta: { is_admin: true }
  },
  {
    path: '/admin/override_map',
    component: OverrideMap,
    meta: { is_admin: true }
  },
  {
    path: '/admin/override_cog/zero_cogs',
    component: ZeroCog,
    meta: { is_admin: true }
  },
  {
    path: '/admin/run-markups',
    component: RunMarkups,
    meta: { is_admin: true }
  },
  {
    path: '/admin/section',
    component: SectionList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/section/:id',
    component: SectionAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/tax-rate',
    component: TaxRate,
    meta: { is_admin: true }
  },
  {
    path: '/admin/warehouse/:code',
    component: WarehouseList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/warehouse/:code/:id',
    component: WarehouseAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/specials',
    component: SpecialsList,
    meta: { is_admin: true }
  },
  {
    path: '/admin/specials/:id',
    component: SpecialsAddMod,
    meta: { is_admin: true }
  },
  {
    path: '/admin/stock_items',
    component: StockItemList,
    meta: { is_admin: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }
    }
  }
});

router.beforeEach((to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  const authStore = useAuthStore();

  if (to.meta.is_admin && to.path != '/admin/login' && !authStore.isLoggedIn) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    return {
      path: '/admin/login'
      // save the location we were at to come back later
      //query: { redirect: to.fullPath },
    };
  }
});

export default router;
