<script>
import axios from 'axios';

export default {
  data() {
    return {
      warehouse: {
        id: 0,
        phone: '',
        manufacturer_code: this.$route.params.code,
        city: '',
        zip: '',
        fax: '',
        email: 'test@test.com',
        warehouse: '',
        address: '',
        state_code: 'CA',
        country_code: 'US',
        terms: ''
      },
      country_codes: ['US', 'CA'],
      state_codes: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',

        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT'
      ]
    };
  },
  mounted() {
    // API get warehouse
    axios
      .get(
        this.apiUrl +
          'admin/warehouse/' +
          this.$route.params.code +
          '/' +
          this.$route.params.id
      )
      .then((response) => {
        this.warehouse = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    cancelWarehouse() {
      this.$router.push({ path: '/admin/manufacturer' });
    },
    saveWarehouse() {
      // API save warehouse
      axios
        .post(this.apiUrl + 'admin/warehouse', this.warehouse)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/manufacturer' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Warehouse / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelWarehouse">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveWarehouse">Save</button>
    </div>
  </div>

  <table class="table">
    <tr>
      <td class="form-label">manufacture code</td>
      <td>
        {{ warehouse.manufacturer_code }}
      </td>
    </tr>

    <tr>
      <td class="form-label">name</td>
      <td>
        <input v-model="warehouse.warehouse" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">email</td>
      <td><input v-model="warehouse.email" class="form-control" type="email" /></td>
    </tr>
    <tr>
      <td class="form-label">phone</td>
      <td><input v-model="warehouse.phone" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">address</td>
      <td>
        <input v-model="warehouse.address" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">city state zip</td>
      <td>
        <input
          v-model="warehouse.city"
          class="form-control no-width inline"
          type="text"
          placeholder="city"
        />

        <select v-model="warehouse.state" class="form-select no-width inline mx-1">
          <option v-for="state in state_codes" :key="state" :value="state">
            {{ state }}
          </option>
        </select>

        <input
          v-model="warehouse.zip"
          class="form-control no-width inline"
          type="text"
          placeholder="zip"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">country</td>
      <td>
        <select v-model="warehouse.country_code" class="form-select">
          <option v-for="country in country_codes" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">terms</td>
      <td>
        <textarea v-model="warehouse.terms" class="form-control" rows="3"></textarea>
      </td>
    </tr>
  </table>
</template>

<style>
.no-padding-left {
  padding-left: 0 !important;
}
</style>
