<template>
  <div>
    <form class="d-flex" action="/search">
      <div class="input-group mb-3">
        <input
          name="search"
          class="form-control"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
        <span id="basic-addon1" class="input-group-text search-bar-icon">
          <button class="btn btn-sm" type="submit">
            <font-awesome-icon icon="fa-magnifying-glass" class="search-bar-color" />
          </button>
        </span>
      </div>
    </form>
  </div>
</template>