<script>
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import dayjs from 'dayjs';

export default {
  components: { flatPickr },
  data() {
    let begin_date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let end_date = new Date();

    return {
      employeeList: [],
      rebates: [],
      filters: {
        date_ordered_begin: dayjs(begin_date).format('YYYY-MM-DD'),
        date_ordered_end: dayjs(end_date).format('YYYY-MM-DD'),
        employee_id: 0,
        order_id: '',
        status: 'all items delivered'
      }
    };
  },
  mounted() {
    this.getRebates();
    axios
      .get(this.apiUrl + 'admin/employee/dropdowns')
      .then((response) => {
        this.employeeList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    getRebates() {
      axios
        .post(this.apiUrl + 'admin/accounting/rebates', this.filters)
        .then((response) => {
          this.rebates = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Rebates</h2>
  </div>

  <!--- filter -->
  <div class="filter">
    <flatPickr
      v-model="filters.date_ordered_begin"
      class="form-control form-control-date flatpickr-input mr-10 inline"
      data-bs-focus="false"
    />
    to
    <flatPickr
      v-model="filters.date_ordered_end"
      class="form-control form-control-date flatpickr-input ml-10 inline"
      data-bs-focus="false"
    />

    <select v-model="filters.employee_id" class="form-select form-select-sm">
      <option value="0">all employees</option>
      <option v-for="employee in employeeList" :key="employee.id" :value="employee.id">
        {{ employee.name }}
      </option>
    </select>

    <select v-model="filters.status" class="form-select form-select-sm">
      <option value="">all</option>
      <option value="pending order">pending order</option>
      <option value="pending shipping">pending shipping</option>
      <option value="in transit">in transit</option>
      <option value="all items delivered">all items delivered</option>
    </select>

    <input
      v-model="filters.order_id"
      type="text"
      class="form-control form-control-sm"
      placeholder="Order Id"
    />
    <button class="btn btn-primary btn-sm" @click="getRebates">filter</button>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>OrderId</td>
        <td>DateEntered</td>
        <td>Manufacturer</td>
        <td>Model</td>

        <td class="right">Qty</td>
        <td class="right">COG Rebateable</td>
        <td class="right">COG</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="rebate in rebates" :key="rebate.id">
        <td>{{ rebate.orderid }}</td>
        <td>{{ rebate.date_ordered }}</td>
        <td>{{ rebate.manufacturer }}</td>
        <td>{{ rebate.model }}</td>

        <td class="right">{{ $money(rebate.qty) }}</td>
        <td class="right">{{ $money(rebate.cog) }}</td>
        <td class="right">{{ $money(rebate.rebateable_cog) }}</td>
      </tr>
    </tbody>
  </table>
</template>
