<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      cobrandList: []
    };
  },
  mounted() {
    this.getAllCobrandList();
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/cobrand')
      .then((response) => {
        this.cobrandList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addCobrand() {
      router.push({ path: '/admin/cobrand/0' });
    },
    modifyCobrand(cobrand_id) {
      router.push({ path: '/admin/cobrand/' + cobrand_id });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Settings</h2>
    <!---
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="addCobrand">Add</button>
    </div>-->
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Cobrand</td>
        <td>SiteUrl</td>

        <td class="text-center">Modify</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="cobrand in cobrandList" :key="cobrand.id">
        <td>{{ cobrand.cobrand }}</td>
        <td>{{ cobrand.site_url }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifyCobrand(cobrand.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
