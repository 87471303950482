<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      cobrand: {
        id: 0,
        site_url: '',
        company_name: '',
        phone: '',
        fax: '',
        bcc_email: '',
        email: '',
        mail_server: '',
        mail_server_username: '',
        mail_server_password: '',
        site_title: '',
        site_meta_description: '',
        site_meta_keywords: '',
        items_0_50_markup_percent: '',
        items_50_100_markup_percent: '',
        items_100_500_markup_percent: '',
        items_500_1000_markup_percent: '',
        items_1000_2500_markup_percent: '',
        items_2500_3500_markup_percent: '',
        items_3500_markup_percent: '',
        additional_markup_percent: '',
        freight_markup: '',
        freight_min_charge: '',
        paypal_api_username: '',
        paypal_api_password: '',
        paypal_api_signature: '',
        authorizenet_login_id: '',
        authorizenet_trans_key: '',
        stripe_published_key: '',
        stripe_private_key: '',
        payment_processor: ''
      }
    };
  },
  mounted() {
    // API get cobrand
    axios
      .get(this.apiUrl + 'admin/cobrand/' + this.$route.params.id)
      .then((response) => {
        this.cobrand = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    cancelCobrand() {
      this.$router.push({ path: '/admin/cobrand' });
    },
    saveCobrand() {
      // API save cobrand
      axios
        .post(this.apiUrl + 'admin/cobrand', this.cobrand)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/cobrand' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Setting / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelCobrand">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveCobrand">Save</button>
    </div>
  </div>

  <table>
    <tbody>
      <tr>
        <td class="form-label">Cobrand</td>
        <td>
          <input
            v-model="cobrand.cobrand"
            class="form-control"
            type="text"
            maxlength="20"
            size="20"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Site Url</td>
        <td>
          <input
            v-model="cobrand.site_url"
            class="form-control"
            type="text"
            size="50"
            maxlength="50"
          />
          (url - corechef.com)
        </td>
      </tr>
      <tr>
        <td class="form-label">Company Name</td>
        <td>
          <input
            v-model="cobrand.company_name"
            class="form-control"
            type="text"
            size="50"
            maxlength="50"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Phone</td>
        <td>
          <input
            v-model="cobrand.phone"
            class="form-control"
            type="text"
            size="20"
            maxlength="20"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Fax</td>
        <td>
          <input
            v-model="cobrand.fax"
            class="form-control"
            type="text"
            size="20"
            maxlength="20"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Email (Orders)</td>
        <td>
          <input
            v-model="cobrand.bcc_email"
            class="form-control"
            type="text"
            size="100"
            maxlength="500"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Email (System + PO)</td>
        <td>
          <input
            v-model="cobrand.email"
            class="form-control"
            type="text"
            maxlength="50"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Email Server</td>
        <td>
          <input
            v-model="cobrand.mail_server"
            class="form-control"
            type="text"
            size="50"
            maxlength="100"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Email Server Username</td>
        <td>
          <input
            v-model="cobrand.mail_server_username"
            class="form-control"
            type="text"
            size="50"
            maxlength="100"
          />
        </td>
      </tr>
      <tr>
        <td class="form-label">Email Server Password</td>
        <td>
          <input
            v-model="cobrand.mail_server_password"
            class="form-control"
            type="text"
            size="50"
            maxlength="100"
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td><br /></td>
      </tr>

      <tr>
        <td class="form-label">Site Title</td>
        <td>
          <input
            v-model="cobrand.site_title"
            class="form-control"
            type="text"
            size="70"
            maxlength="70"
          />
          4-10 words
        </td>
      </tr>
      <tr>
        <td class="form-label">Site Meta Description</td>
        <td>
          <input
            v-model="cobrand.site_meta_description"
            class="form-control"
            type="text"
            size="150"
            maxlength="150"
          />
          keywords at beginning, call to action
        </td>
      </tr>

      <tr>
        <td class="form-label">Site Meta Keywords</td>
        <td>
          <input
            v-model="cobrand.site_meta_keywords"
            class="form-control"
            type="text"
            size="150"
            maxlength="150"
          />
          &lt; 10 keywords, comma separated, lowercase
        </td>
      </tr>

      <tr>
        <td></td>
        <td><br /></td>
      </tr>

      <tr>
        <td class="form-label">Items Markup</td>
        <td>
          <div class="row align-items-center">
            <div class="col">
              $0-$50
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_0_50_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              $50-$100
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_50_100_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              $100-$500
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_100_500_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              $500-$1000
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_500_1000_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              $1000-$2500
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_1000_2500_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              $2500-$3500
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_2500_3500_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              $3500+
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.items_3500_markup_percent"
                  class="form-control"
                  type="number"
                  step="0.01"
                  size="3"
                /><span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td class="form-label">Other Markups</td>
        <td>
          <div class="row align-items-center">
            <div class="col">
              Additional
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.additional_markup_percent"
                  class="form-control"
                  type="text"
                  size="5"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              Freight Markup
              <div class="input-group mb-3">
                <input
                  v-model="cobrand.freight_markup"
                  class="form-control"
                  type="text"
                  size="5"
                /><span class="input-group-text">%</span>
              </div>
            </div>
            <div class="col">
              Freight Min Charge
              <div class="input-group mb-3">
                <span class="input-group-text">$</span
                ><input
                  v-model="cobrand.freight_min_charge"
                  class="form-control"
                  type="text"
                  size="5"
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <h2>Payment Processors</h2>
  <table>
    <tbody>
      <tr>
        <td class="label">Paypal API Username</td>
        <td>
          <input
            v-model="cobrand.paypal_api_username"
            class="form-control"
            type="text"
            size="50"
          />
        </td>
      </tr>
      <tr>
        <td class="label">Paypal API Password</td>
        <td>
          <input
            v-model="cobrand.paypal_api_password"
            class="form-control"
            type="text"
            size="50"
          />
        </td>
      </tr>
      <tr>
        <td class="label">Paypal API Signature</td>
        <td>
          <input
            v-model="cobrand.paypal_api_signature"
            class="form-control"
            type="text"
            size="80"
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td><br /></td>
      </tr>

      <tr>
        <td class="label">AuthorizeNet Login Id</td>
        <td>
          <input
            v-model="cobrand.authorizenet_login_id"
            class="form-control"
            type="text"
            size="20"
          />
        </td>
      </tr>
      <tr>
        <td class="label">AuthorizeNet Trans Key</td>
        <td>
          <input
            v-model="cobrand.authorizenet_trans_key"
            class="form-control"
            type="text"
            size="20"
          />
        </td>
      </tr>
      <tr>
        <td class="label">AuthorizeNet Client Key</td>
        <td>
          <input
            v-model="cobrand.authorizenet_client_key"
            class="form-control"
            type="text"
            size="100"
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td><br /></td>
      </tr>
      <tr>
        <td class="label">Stripe Public Key</td>
        <td>
          <input
            v-model="cobrand.stripe_published_key"
            class="form-control"
            type="text"
            size="80"
          />
        </td>
      </tr>
      <tr>
        <td class="label">Stripe Private Key</td>
        <td>
          <input
            v-model="cobrand.stripe_private_key"
            class="form-control"
            type="text"
            size="80"
          />
        </td>
      </tr>

      <tr>
        <td></td>
        <td><br /></td>
      </tr>

      <tr>
        <td class="label">Payment Processor</td>
        <td>
          <select v-model="cobrand.payment_processor" class="form-select form-select-sm">
            <option value="authorizenet">authorizenet</option>
            <option value="paypal">paypal</option>
            <option value="stripe" selected="">stripe</option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>
</template>
