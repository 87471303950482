<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      search_state_code: 'IN',
      tax_rate: {
        id: 0,
        state_code: '',
        tax_rate_percent: 0.0
      },
      tax_collected_by_manufacturer_codes: [],
      manufacturers: [],
      support: [1, 2]
    };
  },
  watch: {
    search_state_code: function (newValue) {
      // Fetch items based on the selected manufacturer
      this.getTaxRates();
      this.getTaxCollectedByManufacturers();
    }
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/manufacturer/dropdown')
      .then((response) => {
        this.manufacturers = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
    this.getTaxRates();
    this.getTaxCollectedByManufacturers();
  },
  methods: {
    getTaxRates() {
      axios
        .get(this.apiUrl + 'admin/tax_rate/' + this.search_state_code)
        .then((response) => {
          this.tax_rate = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    getTaxCollectedByManufacturers() {
      axios
        .get(
          this.apiUrl +
            'admin/tax_rate/tax_collected_by_manufacturer/' +
            this.search_state_code
        )
        .then((response) => {
          this.tax_collected_by_manufacturer_codes = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    saveTaxRate() {
      // API save tax_rate
      axios
        .post(this.apiUrl + 'admin/tax_rate', this.tax_rate)
        .then((response) => {
          this.showToast('Data saved', response.data.message, 'success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });

      // API save tax_collected_by_manufacturer
      let tax_collected_by_manufacturer_data = {
        state_code: this.tax_rate.state_code,
        manufacturer_codes: this.tax_collected_by_manufacturer_codes
      };
      axios
        .post(
          this.apiUrl + 'admin/tax_rate/tax_collected_by_manufacturer',
          tax_collected_by_manufacturer_data
        )
        .then((response) => {
          this.showToast('Data saved', response.data.message, 'success');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
      // /admin/tax_rate/
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Tax Rate</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="saveTaxRate">Save</button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-1">
      State
      <select v-model="search_state_code" class="form-select no-width">
        <option value="AK">AK</option>
        <option value="AL">AL</option>
        <option value="AR">AR</option>
        <option value="AZ">AZ</option>
        <option value="CA">CA</option>
        <option value="CO">CO</option>
        <option value="CT">CT</option>
        <option value="DC">DC</option>
        <option value="DE">DE</option>
        <option value="FL">FL</option>
        <option value="GA">GA</option>
        <option value="HI">HI</option>
        <option value="IA">IA</option>
        <option value="ID">ID</option>
        <option value="IL">IL</option>
        <option value="IN">IN</option>
        <option value="KS">KS</option>
        <option value="KY">KY</option>
        <option value="LA">LA</option>
        <option value="MA">MA</option>
        <option value="MD">MD</option>
        <option value="ME">ME</option>
        <option value="MI">MI</option>
        <option value="MN">MN</option>
        <option value="MO">MO</option>
        <option value="MS">MS</option>
        <option value="MT">MT</option>
        <option value="NC">NC</option>
        <option value="ND">ND</option>
        <option value="NE">NE</option>
        <option value="NH">NH</option>
        <option value="NJ">NJ</option>
        <option value="NM">NM</option>
        <option value="NV">NV</option>
        <option value="NY">NY</option>
        <option value="OH">OH</option>
        <option value="OK">OK</option>
        <option value="OR">OR</option>
        <option value="PA">PA</option>
        <option value="RI">RI</option>
        <option value="SC">SC</option>
        <option value="SD">SD</option>
        <option value="TN">TN</option>
        <option value="TX">TX</option>
        <option value="UT">UT</option>
        <option value="VA">VA</option>
        <option value="VT">VT</option>
        <option value="WA">WA</option>
        <option value="WI">WI</option>
        <option value="WV">WV</option>
        <option value="WY">WY</option>
      </select>
    </div>
    <div class="col-1">
      Tax Rate
      <div class="input-group">
        <input
          v-model="tax_rate.tax_rate_percent"
          class="form-control"
          type="text"
          min="0"
          step=".01"
        />
        <span class="input-group-text">%</span>
      </div>
    </div>
  </div>

  <div v-if="search_state_code == 'HI'" class="mb-3">
    <h4>Zip Tax Override</h4>
    4.167%<br />
    96708, 96713, 96729, 96732, 96733, 96742, 96748, 96753, 96757, 96761, 96763, 96767,
    96768, 96770, 96779, 96784, 96788, 96790, 96793
  </div>

  <h4>Collect Taxes From Manufacturers</h4>
  <div class="row">
    <div
      v-for="manufacturer in manufacturers"
      :key="manufacturer.manufacturer_codes"
      class="col-3 fs-6"
    >
      <input
        :id="manufacturer.manufacturer_code"
        v-model="tax_collected_by_manufacturer_codes"
        :value="manufacturer.manufacturer_code"
        name="tax_collected_by_manufacturer_codes"
        type="checkbox"
        :true-value="[]"
      />
      {{ manufacturer.manufacturer }}
    </div>
  </div>
</template>
