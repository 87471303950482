import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { loadFonts } from './plugins/webfontloader';
// import store from './store'
import router from './router';

import helper from './helper';

// custom CSS
import './assets/css/base.css';

loadFonts();

/* Icons - fontawesome https://fontawesome.com/search?o=r&m=free&s=regular or https://fontawesome.com/search?o=r&m=free&s=solid  
<font-awesome-icon icon="fa-eye-slash" />
<font-awesome-icon :icon="['far', 'heart']" />
*/
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCheck,
  faCartShopping,
  faCodeMerge,
  faX,
  faEye,
  faEyeSlash,
  faFilter,
  faStar,

  faImage,
  faMagnifyingGlass,
  faPen,
  faPlus,
  faSignOutAlt,
  faSignOut,
  faSignIn,
  faTrash,
  faTrashAlt,
  faVideo,
  faUser,
  faUserPlus,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import {
  faStar as faStarRegular,
} from '@fortawesome/free-regular-svg-icons';

library.add(faCartShopping);
library.add(faCheck);
library.add(faCodeMerge);
library.add(faX);
library.add(faEye);
library.add(faEyeSlash);
library.add(faFilter);
library.add(faStar);
library.add(faStarRegular);

library.add(faImage);
library.add(faMagnifyingGlass);
library.add(faPen);
library.add(faPlus);
library.add(faSignOutAlt);
library.add(faSignOut);
library.add(faSignIn);
library.add(faTrash);
library.add(faTrashAlt);
library.add(faUser);
library.add(faVideo);

library.add(faUserPlus);
library.add(faWarehouse);

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);

// Pagination
import VueAwesomePaginate from 'vue-awesome-paginate';
import 'vue-awesome-paginate/dist/style.css';

// global variables
app.config.globalProperties.clientName = process.env.VUE_APP_CLIENT_NAME;
app.config.globalProperties.apiUrl = process.env.VUE_APP_API_URL;

// register the packages
app.use(router);
app.use(helper);

//app.use(store)
app.use(VueAwesomePaginate);
app.use(createPinia());
app.mount('#app');
