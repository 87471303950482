<script>
import AtlasFooter from './AtlasFooter.vue';
import MidCityFooter from './MidCityFooter.vue';
import MyChefStoreFooter from './MyChefStoreFooter.vue';

export default {
  components: {
    AtlasFooter,
    MyChefStoreFooter,
    MidCityFooter
  }
};
</script>

<template>
  <div class="mb-2 no-print">
    <div v-if="clientName === 'atlas'">
      <AtlasFooter></AtlasFooter>
    </div>
    <div v-if="clientName === 'mychefstore'">
      <MyChefStoreFooter></MyChefStoreFooter>
    </div>
    <div v-if="clientName === 'midcity'">
      <MidCityFooter></MidCityFooter>
    </div>
  </div>
</template>
