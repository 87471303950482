<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      show_form: false,
      ZeroCog_list: [],
      newZeroCog: ''
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/override/zero_cogs')
      .then((response) => {
        this.ZeroCog_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Zero Cogs</h2>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Manufacturer</td>
        <td>Item</td>
        <td>Cog</td>
        <td>Dates</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="ZeroCog in ZeroCog_list" :key="ZeroCog.id">
        <td>{{ ZeroCog.manufacturer }}</td>
        <td>{{ ZeroCog.model }}</td>
        <td>{{ ZeroCog.cog }}</td>
        <td>{{ ZeroCog.begin_date }} - {{ ZeroCog.end_date }}</td>
      </tr>
    </tbody>
  </table>
</template>
