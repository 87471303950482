<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      show_form: false,
      manufacturer_list: [],
      new_manufacturer: ''
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/manufacturer')
      .then((response) => {
        this.manufacturer_list = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    addManufacturer() {
      router.push({ path: '/admin/manufacturer/0' });
    },
    modifyManufacturer(manufacturer_id) {
      router.push({ path: '/admin/manufacturer/' + manufacturer_id });
    },
    warehouses(code) {
      router.push({ path: '/admin/warehouse/' + code });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Manufacturers</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="addManufacturer">Add</button>
    </div>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Code</td>
        <td>Manufacturer</td>
        <td>Rep</td>
        <td>Phone</td>

        <td>BuyingGroup</td>

        <td class="text-center">Modify</td>
        <td class="text-center">Warehouses</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="manufacturer in manufacturer_list" :key="manufacturer.id">
        <td>{{ manufacturer.manufacturer_code }}</td>
        <td>{{ manufacturer.manufacturer }}</td>
        <td>{{ manufacturer.rep_name }}</td>
        <td>{{ manufacturer.rep_phone }}</td>

        <td>{{ manufacturer.is_rebateable }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifyManufacturer(manufacturer.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
        <td class="text-center">
          <a
            class="hand"
            :href="javascript"
            @click="warehouses(manufacturer.manufacturer_code)"
          >
            <font-awesome-icon icon="fa-warehouse" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
