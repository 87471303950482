<script>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';
import ItemsListComponent from '../components/ItemsListComponent.vue';
import router from '../router/index.js';

export default {
  components: {
    ItemsListComponent
  },
  data() {
    const authStore = useAuthStore();

    return {
      authStore,
      items: []
    };
  },
  computed: {},
  mounted() {
    this.loadOrderGuide();
  },
  methods: {
    loadOrderGuide() {
      let data = { profile_company_id: this.authStore.profile.profile_company_id };
      axios
        .post(this.apiUrl + 'profile/order-guide', data)
        .then((response) => {
          this.items = response.data.items;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>
<template>
  <h4>Order Guide</h4>
  <div v-if="items">
    <ItemsListComponent :items="items" :filters="[]"></ItemsListComponent>
  </div>
</template>
