<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      resultList: []
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/warehouse/' + this.$route.params.code)
      .then((response) => {
        this.resultList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    modifyWarehouse(warehouseId) {
      router.push({
        path: '/admin/warehouse/' + this.$route.params.code + '/' + warehouseId
      });
    },
    deleteWarehouse(warehouseId, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/warehouse/' + warehouseId)
          .then((resp) => {
            this.resultList.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Warehouses - {{ $route.params.code }}</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="modifyWarehouse(0)">Add</button>
    </div>
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <td>Code</td>
        <td>Warehouse</td>
        <td>Address</td>
        <td>State</td>

        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(warehouse, index) in resultList" :key="warehouse.id">
        <td>{{ warehouse.warehouse_code }}</td>
        <td>{{ warehouse.warehouse }}</td>
        <td>{{ warehouse.address }}</td>
        <td>{{ warehouse.state_code }}</td>

        <td class="text-center">
          <a class="hand" :href="javascript" @click="modifyWarehouse(warehouse.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </td>
        <td class="text-center">
          <a
            class="hand"
            :href="javascript"
            @click="deleteWarehouse(warehouse.id, index)"
          >
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
