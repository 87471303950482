<script>
import axios from 'axios';
import router from '../../router/index.js';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      resultList: []
    };
  },
  mounted() {
    axios
      .get(this.apiUrl + 'admin/section')
      .then((response) => {
        this.resultList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    modify(section_id) {
      router.push({ path: '/admin/section/' + section_id });
    },
    deleteSection(section_id, index) {
      this.showToast('Error', 'Delete of sections is not currently authorized', 'error');
      return;

      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/section/' + section_id)
          .then((resp) => {
            this.resultList.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    add() {
      router.push({ path: '/admin/section/0' });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Sections</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="add">Add</button>
    </div>
  </div>

  <div class="row header">
    <div class="col-9">Section</div>
    <div class="col text-center">Image</div>
    <div class="col text-center">Modify</div>
    <div class="col text-center">Delete</div>
  </div>
  <div v-for="(result, index) in resultList" :key="result.id">
    <div class="row">
      <div class="col-9">{{ result.name }}</div>
      <div class="col text-center">
        <span v-if="result.sec_image_url > ''"
          ><font-awesome-icon icon="fa-image"
        /></span>
      </div>
      <div class="col text-center">
        <a class="hand" :href="javascript" @click="modify(result.id)">
          <font-awesome-icon icon="fa-pen" />
        </a>
      </div>
      <div class="col text-center">
        <a class="hand" :href="javascript" @click="deleteSection(result.id, index)">
          <font-awesome-icon icon="fa-trash-alt" />
        </a>
      </div>
    </div>

    <div
      v-for="(sub_result, sub_result_index) in result.sub_sections"
      :key="sub_result.id"
    >
      <div class="row">
        <div class="col-9 pl-50">{{ sub_result.name }}</div>
        <div class="col text-center">
          <span v-if="sub_result.sec_image_url > ''"
            ><font-awesome-icon icon="fa-image"
          /></span>
        </div>
        <div class="col text-center">
          <a class="hand" :href="javascript" @click="modify(sub_result.id)">
            <font-awesome-icon icon="fa-pen" />
          </a>
        </div>
        <div class="col text-center">
          <a
            class="hand"
            :href="javascript"
            @click="deleteSection(sub_result.id, sub_result_index)"
          >
            <font-awesome-icon icon="fa-trash-alt" />
          </a>
        </div>
      </div>

      <div
        v-for="(sub_result2, sub_result2_index) in sub_result.sub_sections2"
        :key="sub_result2.id"
      >
        <div class="row">
          <div class="col-9 pl-100">{{ sub_result2.name }}</div>
          <div class="col text-center">
            <span v-if="sub_result2.sec_image_url > ''"
              ><font-awesome-icon icon="fa-image"
            /></span>
          </div>
          <div class="col text-center">
            <a class="hand" :href="javascript" @click="modify(sub_result2.id)">
              <font-awesome-icon icon="fa-pen" />
            </a>
          </div>
          <div class="col text-center">
            <a
              class="hand"
              :href="javascript"
              @click="deleteSection(sub_result2.id, sub_result2_index)"
            >
              <font-awesome-icon icon="fa-trash-alt" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pl-50 {
  padding-left: 50px;
}
.pl-100 {
  padding-left: 100px;
}
.row:hover {
  background-color: #f0f0f0;
}
.header {
  background-color: #f8f9fa;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 4px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(222, 226, 230);
}
</style>
