<script>
import axios from 'axios';
import router from '../../router/index.js';

export default {
  data() {
    return {
      isLoading: false,
      files: [],
      fileImage1: null,

      special: {
        id: 0,
        image_url: '',
        special_url: ''
      }
    };
  },
  mounted() {
    // API get special
    if (this.$route.params.id > 0) {
      axios
        .get(this.apiUrl + 'admin/specials/' + this.$route.params.id)
        .then((response) => {
          this.special = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  },
  methods: {
    addImage1(e) {
      this.fileImage1 = e.target.files[0];
    },
    cancelSpecial() {
      this.$router.push({ path: '/admin/specials' });
    },
    async saveSpecial() {
      this.isLoading = true;
      if (this.fileImage1) {
        const data = new FormData();
        data.append('special_url', this.special_url);
        data.append('file', this.fileImage1);
        const response = await axios.post(
          this.apiUrl + 'admin/upload-image-special',
          data
        );
        this.special.image_url = response.data.image_url;
      }

      await axios
        .post(this.apiUrl + 'admin/specials', this.special)
        .then((response) => {
          router.push({ path: '/admin/specials' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Special / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelSpecial">Cancel</button>
      <button class="btn btn-primary btn-sm" :disabled="isLoading" @click="saveSpecial">
        Save
      </button>
    </div>
  </div>

  <table class="">
    <tr>
      <td class="form-label">URL</td>
      <td>
        <input v-model="special.special_url" class="form-control" />
      </td>
    </tr>
    <tr>
      <td class="form-label">Image1</td>
      <td>
        <img :src="special.image_url" class="img-thumbnail" /><br />
        <input type="file" @change="addImage1" />
      </td>
    </tr>
  </table>
</template>

<style scoped>
.img-thumbnail {
  width: 100px;
  height: 100px;
}
</style>
