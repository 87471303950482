<script>
import PolicyAtlas from './layouts/PolicyAtlas.vue';
import PolicyMidcity from './layouts/PolicyMidcity.vue';
import PolicyMychefstore from './layouts/PolicyMychefstore.vue';
export default {
  name: 'App',
  components: {
    PolicyAtlas,
    PolicyMychefstore,
    PolicyMidcity
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    closeModal() {
      new window.bootstrap.Modal(document.getElementById('modal-ask')).hide();
    }
  }
};
</script>

<template>
  <!-- Modal -->
  <div
    id="modal-terms"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="modal_title" class="modal-title fs-5">Terms and Conditions</h1>
        </div>
        <div id="modal_body" class="modal-body">
          <!--- policies --->
          <div v-if="clientName === 'atlas'">
            <PolicyAtlas></PolicyAtlas>
          </div>
          <div v-if="clientName === 'midcity'">
            <PolicyMidcity></PolicyMidcity>
          </div>
          <div v-if="clientName === 'mychefstore'">
            <PolicyMychefstore></PolicyMychefstore>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
