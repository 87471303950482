let filter_names = [
  'filter_type',
  'filter_size',
  'filter_power',
  'filter_power_type',
  'filter_capacity',
  'filter_style',
  'filter_height',
  'filter_depth',
  'filter_door_type',
  'filter_width',
  'filter_utensil_type',
  'filter_flatware_type',
  'filter_shape',
  'filter_color',
  'filter_material',
  'filter_category'
];
let filter_type = [
  'Reach-in',
  'Undercounter',
  'Cube',
  'Flake',
  'Nugget',
  'Gourmet',
  'Refrigerated',
  'Non Refrigerated',
  'Dual-Temp',
  'With Cold Plate',
  'Full Pan',
  '1/2 Pan',
  '1/4 Pan',
  '1/3 Pan',
  '1/6 Pan',
  '1/9 Pan',
  'Convection Oven',
  'Standard Oven',
  'Countertop',
  'Floor Model',
  'Underbar',
  'Backbar',
  'Draft Beer Cooler',
  'Merchandiser',
  'Hotel Pan',
  'Bun Pan',
  'Drop-in',
  'Hand Sink',
  'Prep Sink',
  'Warewashing Sink',
  'Wall-mount',
  'Free-Standing',
  'Mop Sink',
  'Deck Mount',
  'Splash/ Wall Mount',
  'Swivel',
  'Gooseneck',
  'Pre-Rinse',
  'Gas',
  'Thermostatic',
  'Manual',
  'Coffee',
  'Tea',
  'Espresso',
  'Bowl',
  'Plate',
  'Platter',
  'Mug',
  'Cup',
  'Coffee Cup',
  'Work Table',
  'Wire Shelving',
  'Handheld',
  '2 Burner',
  '4 Burner',
  '6 Burner',
  'Regulators',
  'Hoses'
];
let filter_size = [
  '1-Door',
  '2-Door',
  '3-Door',
  '1-Pan',
  '2-Pans',
  '3-Pans',
  '4-Pans',
  '5-Pans',
  '6-Pans',
  '22"',
  '30"',
  '48"',
  '60"',
  '27"',
  '36"',
  'Full Pan',
  '1/2 Pan',
  '1/4 Pan',
  '1/3 Pan',
  '1/6 Pan',
  '1/9 Pan',
  '18x26x3',
  '18x26x6',
  '18x26x9',
  '18x26x15',
  '12x18x3',
  '12x18x6',
  '12x18x9',
  '1 Well',
  '2 Wells',
  '3 Wells',
  '4 Wells',
  '5 Wells',
  '1-Compartment',
  '2-Compartment',
  '3-Compartment',
  '8" Center',
  '4" Center',
  '6"',
  '8"',
  '10"',
  '12"',
  '18" Dbl Jointed',
  '22x22',
  '24x24',
  '24x30',
  '28x28',
  '30x24',
  '30x30',
  '30x42',
  '36x24',
  '26x30',
  '36x36',
  '48x24',
  '48x30',
  '60x24',
  '60x30',
  '72x24',
  '72x30',
  '1-Group',
  '2-Group',
  '3-Group',
  '9-Compartment',
  '16-Compartment',
  '20-Compartment',
  '25-Compartment',
  '36-Compartment',
  '1-Keg',
  '2-Kegs',
  '3-Kegs',
  '#100',
  '#70',
  '#50',
  '#40',
  '#30',
  '#24',
  '#20',
  '#16',
  '#12',
  '#10',
  '#8',
  '#6',
  '20 cup',
  '30 cup',
  '37 cup',
  '50 cup',
  '55 cup',
  '4 keg',
  '1/2 keg',
  '27'
];
let filter_power = ['Electric', 'Gas'];
let filter_power_type = [
  'LP GAS',
  'NATURAL GAS',
  '115 VOLTS',
  '120 VOLTS',
  '208 VOLTS',
  '220 VOLTS',
  '230 VOLTS',
  '115/208-230 VOLTS',
  '208/230 VOLTS',
  'Single Phase',
  'Three Phase'
];
let filter_capacity = [
  '1 Quart',
  '2 Quart',
  '3 Quart',
  '4 Quart',
  '5 Quart',
  '6 Quart',
  '7 Quart',
  '8 Quart',
  '9 Quart',
  '10 Quart',
  '11 Quart',
  '18 Quart',
  '20 Quart',
  '22 Quart',
  '30 Quart',
  '40 Quart',
  '50 Quart',
  '60 Quart',
  '80 Quart',
  '90 Quart',
  '100 Quart',
  '110 Quart',
  '10 Gallon',
  '20 Gallon',
  '32 Gallon',
  '35 Gallon',
  '44 Gallon',
  '55 Gallon',
  'Less than 100 lbs',
  '100 - 150 lbs',
  '151 - 250 lbs',
  '251 - 350 lbs',
  '351 - 450 lbs',
  '451 + lbs',
  '1oz',
  '2oz',
  '3oz',
  '4oz',
  '5oz',
  '6oz',
  '7oz',
  '8oz',
  '9oz',
  '10oz',
  '12oz',
  '14oz',
  '16oz',
  '18oz',
  '20oz',
  '24oz',
  '64oz',
  '84oz',
  '.5 oz',
  '30 oz',
  '20 cup',
  '30 cup',
  '37 cup',
  '50 cup',
  '55 cup'
];
let filter_style = [
  'w/ Casters',
  'Free-Standing',
  'Single Drainboard',
  'Double Drainboards',
  'No Drainboards',
  'Bain Marie',
  'Stock Pot',
  'Sauce Pan',
  'Brazier',
  'Wok',
  'Warmer',
  'Cooker',
  'Warmer Bulb',
  'Heated Rod',
  'Manual Controls',
  'Automatic',
  'Computer Controls',
  'China',
  'Porcelain',
  'Glass',
  'Melamine',
  'Stoneware',
  'Plastic',
  'Wooden',
  'Metal',
  'Bone China',
  'Aluminum',
  'Paintes Steel',
  'Galvanized Steel',
  'Rubber',
  'Grease',
  'Carpet',
  'Rattan/ Natural',
  'Reach In',
  'Prep',
  'Pizza',
  'Pop Up',
  'Conveyor'
];
let filter_height = ['Standard', 'ADA', 'Low-Profile', '72"', '86"'];
let filter_depth = ['2"', '4"', '6"', '8"', '12"', '18"', '24"', '30"', '36"'];
let filter_door_type = ['Glass', 'Solid', 'Sliding - Glass', 'Sliding - Solid'];
let filter_width = [
  '24" & under',
  '27"',
  '30"',
  '36"',
  '42"',
  '44"',
  '48"',
  '50"',
  '52"',
  '60"',
  '64"',
  '67"',
  '70"',
  '72"',
  '84"',
  '90"',
  '94"',
  '96"',
  '108"',
  '120"',
  '3/4"',
  '1"',
  '1-1/4"'
];
let filter_utensil_type = [
  'Spreader',
  'Scraper',
  'Spatula',
  'Rice Paddle',
  'Corer/ Pitter',
  'Turner',
  'Skimmer',
  'Ice Cream Dipper',
  'Pizza Cutter',
  'Peeler',
  'Wooden Spoon',
  'Ice Paddle',
  'Meat Tenderizer',
  'Scoop',
  'Scissors',
  'Disher',
  'Steak Weight',
  'Sifter',
  'Grater',
  'Mixing Paddle',
  'Pizza Peel',
  'Mandolin',
  'Smoke Infuser',
  'Garlic Press',
  'Pasta Insert',
  'Pot Fork',
  'Wok Brush',
  'Ladle',
  'Skimmer',
  'Ice Cream Spade',
  'Masher',
  'Wok Shovel'
];
let filter_flatware_type = [
  'Dinner Fork',
  'Cocktail Fork',
  'Dessert Fork',
  'Oyster Fork',
  'Salad Fork',
  'Dinner Spoon',
  'Soup Spoon',
  'Bouillon Spoon',
  'Dessert Spoon',
  'Demitasse Spoon',
  'Iced Tea Spoon',
  'Serving Spoon',
  'Dinner Knives',
  'Table Knives',
  'Steak Knives',
  'Dessert Knives',
  'Butter Spreaders',
  'Bread Knives',
  'Chef Knives',
  'Boning Knives',
  'Clever',
  'Sharpening Steel',
  'Teaspoon',
  'Tablespoon',
  'Salad Knife'
];
let filter_shape = ['Circle', 'Square', 'Oval', 'Oblong', 'Rectangle'];
let filter_color = [
  'Clear',
  'White',
  'Black',
  'Cream',
  'Bone White',
  'Ivory',
  'Beige',
  'Gray',
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Brown',
  'Bronze',
  'Copper',
  'Gold',
  'Sand',
  'Bright White',
  'American White'
];
let filter_material = [
  'Stainless Steel',
  'Galvanized Steel',
  'Glass',
  'Melamine',
  'Stoneware',
  'Plastic',
  'Wooden',
  'Metal',
  'Bone China',
  'Aluminum',
  'Painted Steel',
  'Porcelain',
  'Rubber'
];
let filter_category = [
  'Ice Maker',
  'Bin',
  'Filter',
  'Dollies',
  'Racks',
  'Boxes',
  'Blender',
  'Blender Parts'
];

let filters = {
  'filter_names': filter_names,
  'filter_type': filter_type,
  'filter_size': filter_size,
  'filter_power': filter_power,
  'filter_power_type': filter_power_type,
  'filter_capacity': filter_capacity,
  'filter_style': filter_style,
  'filter_height': filter_height,
  'filter_depth': filter_depth,
  'filter_door_type': filter_door_type,
  'filter_width': filter_width,
  'filter_utensil_type': filter_utensil_type,
  'filter_flatware_type': filter_flatware_type,
  'filter_shape': filter_shape,
  'filter_color': filter_color,
  'filter_material': filter_material,
  'filter_category': filter_category
} 

export default filters